import React, { useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

interface IModal {
  title: string;
  content: string;
  isOpen: boolean;
  onClose: () => void;
  confirm: (param: boolean) => void;
}

const ModalComponent: React.FC<IModal> = ({
  title,
  content,
  isOpen,
  onClose,
  confirm,
}) => {
  const handleModal = () => {
    confirm(true);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"90%"} maxW={400}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>

        <ModalFooter>
          <Button colorScheme={"green"} onClick={handleModal}>
            Confirmar
          </Button>
          <Button colorScheme="blue" ml={3} onClick={onClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
