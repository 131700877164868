import provider from "./config";
import Toast from "../components/Toast";
import { IRoom } from "../pages/home";

export const getRooms = async (id?: any, idModality?: string, idUser?: any, dataInicio?: any, page?: number, filter?: string) => {
  try {
    const response = await provider.get("/entidades/salas", {
      params: {
        idClasse: id,
        idModalidadeSala: idModality,
        idUser: idUser,
        dataInicioGt: dataInicio,
        page: page,
        nome: filter
      }
    });

    return response.data
  } catch (err: any) {
    Toast.show(err.response.data.message, "error");
  }
};

export const getMyRooms = async (id?: any, idModality?: string, idUser?: any, page?: number, filter?: string) => {
  try {
    const response = await provider.get("/entidades/salas", {
      params: {
        idClasse: id,
        idModalidadeSala: idModality,
        idUser: idUser,
        idCriador: idUser,
        page: page,
        nome: filter
      }
    });

    return response.data;
  } catch (err: any) {
    Toast.show(err.response.data.message, "error");
  }
};

export const getNewRoom = async (id?: string, idModalidadeSala?: string, idUser?: any, dataInicio?: any, page?: number) => {
  try {
    const response = await provider.get("/entidades/salas", {
      params: {
        idClasse: id,
        idModalidadeSala: idModalidadeSala,
        idUser: idUser,
        dataInicioGt: dataInicio,
        page: page
      }
    })

    return response.data
  } catch (err: any) {
    Toast.show(err.response.data.message, "error");
  }
}


export const getMenu = async (dataInicio: any) => {
  try {
    const response = await provider.get("/tabela/modalidades", {
      params: {
        dataInicioGt: dataInicio,
      }
    });

    return response.data
  } catch (err: any) {
    Toast.show(err.response.data.message, "error");
  }
}

export const inscriptionRoom = async (userId: any, salaId: any) => {
  try {
    const response = await provider.post(`/entidades/inscricao-sala/`, {
      userId,
      salaId
    });

    return response;
  } catch (err: any) {
    throw Error(err.response.data.message);
  }
}

export const reinscriptionRoom = async (userId: any, salaId: any) => {
  try {
    const response = await provider.post(`/entidades/reinscricao/`, {
      userId,
      salaId
    });

    return response;
  } catch (err: any) {
    throw Error(err.response.data.message);
  }
}

export const postRoom = async (id: any, payload: IRoom) => {
  try {
    const response = await provider.post("/entidades/criar-sala/", {
      idCriador: id,
      ...payload
    })

    return response
  } catch (err: any) {
    throw Error(err.response.data.message);
  }
}

export const queryPassword = async (senha: string, idSala: any) => {
  try {
    const response = await provider.post("entidades/verifica-senha-sala/", {
      senha: senha,
      idSala: idSala
    })

    return response
  } catch (err: any) {
    Toast.show(err.response.data.message, "error");
    return err
  }
}