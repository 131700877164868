import React, { useEffect, useRef, useState } from "react";
import {
  CardQuestion,
  CardReply,
  Container,
  Timer,
  FooterSection,
  FormChat,
  Question,
  Reply,
  QuestionCard,
  DivRow,
  DivMain,
  SectionChat,
  DivChat,
} from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { formatMoney } from "../../utils/format";
import { IBalanceRoom, ICard } from "../home";
import {
  Button,
  Card,
  Flex,
  FormControl,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import ModalComponent from "../../components/modal";
import Toast from "../../components/Toast";
import { IoIosArrowForward } from "react-icons/io";
import { MdSend } from "react-icons/md";
import InputComponent from "../../components/input";
import { addMessage, socket } from "../../services/socket";
import { useAuth } from "../../contexts/auth";
import Vimeo from "@u-wave/react-vimeo";
import { getBalanceRoom } from "../../services/user";
import { reinscriptionRoom } from "../../services/room";
import GoldMedal from "../../assets/icons/winner.png";
import SilverMedal from "../../assets/icons/silver-medal.png";
import BronzeMedal from "../../assets/icons/bronze-medal.png";
import IconUser from "../../assets/user.png";
import SimpleMedal from "../../assets/icons/medal.png";
import LogoQuizz from "../../assets/logo_quizz_cash_certa_fundo_verde.webp";
import IconInfo from "../../assets/icons/icon-info.webp";
import IconChat from "../../assets/icons/icon-chat.webp";
import differenceInSeconds from "date-fns/differenceInSeconds";

interface IMessage {
  id: string;
  message: string;
  idSala: string;
  nameUser: string;
  idUser: string;
}

interface ILocation {
  card: ICard;
}

interface IRCTIce {
  message: string;
  offer: IOffer;
}

interface IOffer {
  data: any;
  type: string;
}

interface IQuestion {
  excluido: boolean;
  id: string;
  idModalidade: string;
  nivel: string;
  nome: string;
  respostas: IResponse[];
}

interface IResponse {
  atualizadoEm: string;
  criadoEm: string;
  id: string;
  idPergunta: string;
  nome: string;
  correto?: boolean;
}

interface IGameOver {
  acertos: number;
  errors: number;
  idUser: string;
  nome: string;
  position: number;
}

const servers = {
  iceServers: [
    { urls: "stun:stun.l.google.com:19302" },
    { urls: "stun:stun1.l.google.com:19302" },
    { urls: "stun:stun2.l.google.com:19302" },
    { urls: "stun:stun3.l.google.com:19302" },
    { urls: "stun:stun4.l.google.com:19302" },
    { urls: "stun:stun.sipgate.net:2083" },
    { urls: "stun:stun.ekiga.net:2083" },
    {
      urls: "turn:openrelay.metered.ca:80",
      credential: "openrelayproject",
      username: "openrelayproject",
    },
    // {
    //   urls: 'turn:turn.devari.com.br:3478',
    //   credential: 'c39faea3cdd71e6aa569479bade87a41db6fcd91d34cf69d21e94254dba3bfed',
    //   username: 'devari'
    // },
  ],
};

// const questions: IQuestion = {
//   excluido: false,
//   id: "6dhr8d2hu",
//   idModalidade: "gd61r872",
//   nivel: "1",
//   nome: "Teste 1",
//   respostas: [
//     {
//       atualizadoEm: "20/08/2023",
//       criadoEm: "20/08/23",
//       id: "671hrude3",
//       idPergunta: "jf7298r1i",
//       nome: "Resposta 1",
//       correto: true,
//     },
//     {
//       atualizadoEm: "20/08/2023",
//       criadoEm: "20/08/23",
//       id: "yfg6387",
//       idPergunta: "gf623718fsaj",
//       nome: "Resposta 2",
//       correto: false,
//     },
//     {
//       atualizadoEm: "20/08/2023",
//       criadoEm: "20/08/23",
//       id: "7udfow",
//       idPergunta: "6udwio23",
//       nome: "Resposta 3",
//       correto: false,
//     },
//     {
//       atualizadoEm: "20/08/2023",
//       criadoEm: "20/08/23",
//       id: "fh638fiudnj",
//       idPergunta: "g63ind35r",
//       nome: "Resposta 4",
//       correto: false,
//     },
//   ],
// };

const Room: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const localRef = useRef<HTMLVideoElement>(null);
  const chatFeedRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isReconectOpen,
    onOpen: onReconectOpen,
    onClose: onReconectClose,
  } = useDisclosure();
  const {
    isOpen: isRankingOpen,
    onOpen: onRankingOpen,
    onClose: onRankingClose,
  } = useDisclosure();
  const {
    isOpen: isWinnerOpen,
    onOpen: onWinnerOpen,
    onClose: onWinnerClose,
  } = useDisclosure();
  const {
    isOpen: isInfoGameOpen,
    onOpen: onInfoGameOpen,
    onClose: onInfoGameClose,
  } = useDisclosure();
  const [cardRoom, setCardRoom] = useState<ICard | null>(null);
  const [reply, setReply] = useState<string>("");
  const [confirmReply, setConfirmReply] = useState<boolean>(false);
  const [counter, setCounter] = useState<string | number>("");
  const counterRef = useRef<any>(setCounter);
  const [timeOver, setTimeOver] = useState<boolean>(false);
  const [time, setTime] = useState<number>();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [balance, setBalance] = useState<IBalanceRoom>();
  const [valueMessage, setValueMessage] = useState<string>("");
  const [renderComponent, setRenderComponent] = useState<boolean>(false);
  const [requestQuestion, setRequestQuestion] = useState<boolean>(false);
  const [question, setQuestion] = useState<IQuestion | null>(null);
  const [gameOver, setGameOver] = useState<string>("");
  const [noPlayer, setNoPlayer] = useState<string>("");
  const [loadingInscription, setLoadingInscription] = useState<boolean>(false);
  const [block, setBlock] = useState<boolean>(false);
  const [ranking, setRanking] = useState<IGameOver[]>([]);
  const [winner, setWinner] = useState<number | null>(0);
  const [chat, setChat] = useState<boolean>(false);
  const [responseCorrect, setResponseCorrect] = useState<IResponse | null>(
    null
  );
  // let pc: RTCPeerConnection = new RTCPeerConnection(servers);
  // const signaling = new BroadcastChannel("webrtc");
  let joinRoom: boolean = false;

  useEffect(() => {
    var input = document.querySelector("#tab1");
    input?.setAttribute("checked", "checked");

    const handleChatMessage = (msg: IMessage) => {
      setMessages((currentMessages) => [...currentMessages, msg]);
    };

    socket.on("chat-message", handleChatMessage);

    console.log("inicio");

    return () => {
      const { card } = location.state;
      console.log("Sair da sala");
      socket.emit("leave-room", { idSala: card?.id });
      socket.off("chat-message", handleChatMessage);
      // joinRoom = false;
    };
  }, []);

  useEffect(() => {
    if (location && user) {
      const { card }: ILocation = location.state;

      if (card.dataInicio) {
        let dataInicio = new Date(card.dataInicio).toISOString();
        const _time = differenceInSeconds(new Date(dataInicio), new Date());
        console.log(dataInicio);
        startTime(Math.abs(_time));
      }

      setCardRoom(card);

      if (card.idClasse === "64381959694bc59bbe9a6ec8") {
        if (card.id && !joinRoom) {
          joinRoom = true; //VERIFICA MELHOR FORMA DE NAO ENTRAR DUAS VEZES NA SALA
          setRequestQuestion(true);
          // setupWebRTC();
        }
      } else {
        if (!joinRoom) {
          joinRoom = true;
          socket.emit("join-room", { idUser: user.id, idSala: card.id });
          if (card && card.id) getUserBalance(card.id);
        }
        setRequestQuestion(true);
        socket.on("answer-message", (msg) => {
          if (msg.inicioCiclo && block) {
            setBlock(false);
            setGameOver(msg.message);
          } else if (!msg.inicioCiclo && !block) {
            setGameOver(msg.message);
          }
          if (msg.reentrada == true) {
            onReconectOpen();
          } else if (msg.reentrada == false) {
            const redirectTimeout: any = setTimeout(() => {
              socket.emit("leave-room", { idSala: cardRoom?.id });
              // joinRoom = false;
              navigate("/"); // Redireciona para a página inicial
            }, 10000);

            // Limpa o timeout caso o componente seja desmontado antes do redirecionamento
            return () => clearTimeout(redirectTimeout);
          }
        });
        socket.on("answer-correct", (msg) => {
          if (!block) setResponseCorrect(msg.message);
        });
        socket.on("game-over", (msg) => {
          const resultMsg: IGameOver[] = compare(msg.message);

          setRanking(resultMsg);
          onRankingOpen();

          resultMsg.map((item: IGameOver) => {
            if (item.position === 1 && item.idUser === user.id) {
              setWinner(msg.ganhos);
              onWinnerOpen();
            }
          });
        });
        socket.on("sem-jogador", (msg) => {
          setGameOver(msg.titulo);
          setNoPlayer(msg.mensagem);
          const redirectTimeout: any = setTimeout(() => {
            socket.emit("leave-room", { idSala: cardRoom?.id });
            navigate("/"); // Redireciona para a página inicial
          }, 10000);

          return () => clearTimeout(redirectTimeout);
        });
      }

      // startTime(6);
    }
  }, [location]);

  useEffect(() => {
    if (gameOver === "Parabéns Você VENCEU!!!") {
      onRankingOpen();
      const redirectTimeout: any = setTimeout(() => {
        socket.emit("leave-room", { idSala: cardRoom?.id });
        // joinRoom = false;
        // navigate("/"); // Redireciona para a página inicial
      }, 10000);

      // Limpa o timeout caso o componente seja desmontado antes do redirecionamento
      return () => clearTimeout(redirectTimeout);
    }
  }, [gameOver]);

  useEffect(() => {
    if (requestQuestion) {
      socket.on("inicio-sala", (inicio) => {
        // setCounter("O Jogo vai começar!");
        setTime(inicio.message.segundosMs / 1000);
        if (cardRoom && cardRoom.id) getUserBalance(cardRoom.id);
      });
      socket.on("question", (question) => {
        if (block) setBlock(false);
        setQuestion(question.message);
        setTime(
          question.message.tempoPergunta ? question.message.tempoPergunta : 15
        );
        resetValues();
      });
    }
  }, [requestQuestion]);

  const resetValues = () => {
    setConfirmReply(false);
    setGameOver("");
    setResponseCorrect(null);
  };

  // useEffect(() => {
  //   if (reply && confirmReply) {
  //     handleSubmit();
  //   }
  // }, [reply, confirmReply]);

  useEffect(() => {
    if (time) {
      var duration = time;
      if (question) {
        setTimeout(() => {
          startTime(duration);
        }, time);
      }
    }
  }, [time, question]);

  useEffect(() => {
    if (chatFeedRef.current) {
      const chatFeedElement = chatFeedRef.current;
      chatFeedElement.scrollTop = chatFeedElement.scrollHeight;
    }
  }, [messages, chat]);

  useEffect(() => {
    if (confirmReply) {
      if (timeOver) {
        return;
      } else {
        socket.emit("answer", {
          idSala: cardRoom?.id,
          idPergunta: question!.id,
          idResposta: reply,
          idUser: user!.id,
        });
      }
    } else if (!confirmReply && timeOver) {
      onClose();
    }
  }, [confirmReply, timeOver]);

  // Buscar saldo da sala
  const getUserBalance = async (id: string) => {
    try {
      const result = await getBalanceRoom(id);

      if (result) {
        setBalance(result);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  // const setupWebRTC = async () => {
  //   const { card }: ILocation = location.state;
  //   const remoteStream = new MediaStream();

  //   pc.ontrack = (event: RTCTrackEvent) => {
  //     event.streams[0].getTracks().forEach((track) => {
  //       remoteStream.addTrack(track);
  //     });
  //   };
  //   if (videoRef.current) videoRef.current.srcObject = remoteStream;

  //   pc.onicecandidate = (event: any) => {
  //     if (event.candidate) {
  //       // console.log("ICE", event.candidate);
  //       socket.emit("client-broadcast-video", {
  //         idSala: card.id,
  //         data: event.candidate.toJSON(),
  //         type: "ice",
  //       });
  //     }
  //   };

  //   // configurando listner para ouvido o anwser
  //   socket.on("connection-infor", (info) => {
  //     readMessageVideo(info);
  //   });

  //   socket.emit("join-room", { idSala: card.id });

  //   // const offerDescription = await pc.createOffer();
  //   // await pc.setLocalDescription(offerDescription);

  //   // socket.emit("client-broadcast-video", {
  //   //   idSala: card.id,
  //   //   data: offerDescription,
  //   //   type: "offer",
  //   // });
  //   // const localStream = await navigator.mediaDevices.getUserMedia({
  //   //   video: true,
  //   //   audio: true,
  //   // });

  //   // localStream.getTracks().forEach((track) => {
  //   //   pc.addTrack(track, localStream);
  //   // });

  //   // if (localRef.current) localRef.current.srcObject = localStream;
  // };

  // const readMessageVideo = async (dataOffer: IOffer) => {
  //   if (dataOffer.type === "ice") {
  //     const candidate = new RTCIceCandidate(dataOffer.data);
  //     pc.addIceCandidate(candidate);
  //   } else if (dataOffer.type === "answer") {
  //     console.log("answer");
  //     // await pc.setRemoteDescription(new RTCSessionDescription(dataOffer.data));
  //   } else if (dataOffer.type === "offer") {
  //     console.log("Offer");
  //     await pc.setRemoteDescription(new RTCSessionDescription(dataOffer.data));
  //     const localDescription = await pc.createAnswer();
  //     await pc.setLocalDescription(localDescription);
  //     const { card }: ILocation = location.state;
  //     if (card) {
  //       console.log("respondendo PC");
  //       socket.emit("client-broadcast-video", {
  //         idSala: card.id,
  //         data: pc.localDescription,
  //         type: "answer",
  //       });
  //     }
  //   }
  // };

  const startTime = (duration: number) => {
    let remaining = duration;
    setTimeOver(false);
    const intervalId = setInterval(function () {
      const days = Math.floor(remaining / (60 * 60 * 24));
      const hours = Math.floor((remaining % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((remaining % (60 * 60)) / 60);
      const seconds = remaining % 60;
      counterRef.current(
        (days > 0 ? days.toString().padStart(2, "0") + ":" : "") +
          hours.toString().padStart(2, "0") +
          ":" +
          minutes.toString().padStart(2, "0") +
          ":" +
          seconds.toString().padStart(2, "0")
      );
      if (--remaining < 0) {
        clearInterval(intervalId);
        setTimeOver(true);
      }
    }, 1000);
  };

  // async function handleAnswer(answer: any) {
  //   if (!pc) {
  //     console.error("no peerconnection");
  //     return;
  //   }
  //   await pc.setRemoteDescription(answer);
  // }

  // async function handleCandidate(candidate: RTCIceCandidate) {
  //   if (!pc) {
  //     console.error("no peerconnection");
  //     return;
  //   }
  //   if (!candidate.candidate) {
  //     await pc.addIceCandidate(undefined);
  //   } else {
  //     await pc.addIceCandidate(candidate);
  //   }
  // }

  // const handleSubmit = async () => {
  //   try {
  //     // const result = await
  //   } catch (error) {
  //     Toast.show(`${error}`, "error");
  //   }
  // };

  const handleSendMessage = (event: any) => {
    event.preventDefault();

    if (valueMessage.trim().length === 0) {
      return {
        text: "validMessageError",
      };
    }

    const message = {
      message: valueMessage,
      idSala: cardRoom?.id,
      nameUser: user?.nome,
      idUser: user?.id,
    };

    socket.emit("chat-message", { ...message });
    setValueMessage("");
  };

  const handleReplySelected = (value: string) => {
    if (timeOver || confirmReply) return;
    setReply(value);
    onOpen();
  };

  const handleValueMessage = (value: string) => {
    setValueMessage(value);
  };

  const handleInscription = async () => {
    try {
      const { card } = location.state;
      setLoadingInscription(true);
      const result = await reinscriptionRoom(user?.id, card.id);

      if (result) {
        const _balance: any = { ...balance };
        let value = _balance.saldoSaque - card.valorIngresso;
        _balance.saldoSaque = value;
        socket.emit("join-room", {
          idUser: user?.id,
          idSala: card.id,
          reentrada: true,
        });
        setLoadingInscription(false);
        setBalance(_balance as IBalanceRoom);
        setBlock(true);
        setGameOver("Aguardando a próxima pergunta");
        onReconectClose();
      }
    } catch (error) {
      setLoadingInscription(false);
      onReconectClose();
      console.error("Erro: ", error);
    }
  };

  const compare = (message: any) => {
    message.sort((a: IGameOver, b: IGameOver) => b.acertos - a.acertos);

    let currentPosition = 1;
    let previousAcertos = message[0].acertos;

    for (let i = 0; i < message.length; i++) {
      if (message[i].acertos !== previousAcertos) {
        currentPosition++;
        previousAcertos = message[i].acertos;
      }

      message[i].position = currentPosition;
    }

    return message;
  };

  const handleConfirm = () => {
    handleInscription();
  };

  const handleCancel = () => {
    onReconectClose();
    socket.emit("leave-room", { idSala: cardRoom?.id });
    // joinRoom = false;
    navigate("/"); // Redireciona para a página inicial
  };

  return (
    <Container>
      <Question winner={gameOver === "Parabéns Você VENCEU!!!"}>
        <DivRow>
          <div className="header">
            <div style={{ display: "flex" }}>
              <IoIosArrowForward style={{ height: 28, width: 28 }} />
              <IoIosArrowForward
                style={{ marginLeft: -22, height: 28, width: 28 }}
              />
              <IoIosArrowForward
                style={{ marginLeft: -22, height: 28, width: 28 }}
              />
            </div>
            <p>{cardRoom?.modalidade}</p>
          </div>
          <p>{balance?.saldo ? formatMoney(balance.saldo) : "R$ 0,00"}</p>
        </DivRow>
        <DivMain>
          <Timer
            gameOver={gameOver ? true : false}
            className={timeOver ? "pulse" : ""}
          >
            {!gameOver && !question ? (
              <div className="start-game">O Jogo começa em</div>
            ) : null}
            {gameOver ? gameOver : counter}
          </Timer>
          <Timer
            className={timeOver ? "pulse" : ""}
            style={{ fontSize: "2.5rem" }}
          >
            {noPlayer}
          </Timer>
          {question ? (
            <CardQuestion>
              <QuestionCard>{question?.nome}</QuestionCard>
            </CardQuestion>
          ) : cardRoom && cardRoom.type === "video" ? (
            <CardQuestion>
              <Vimeo
                video={`${cardRoom.content}`}
                autoplay
                height={200}
                width={360}
              />
            </CardQuestion>
          ) : null}
          {/* <video autoPlay playsInline id="remoteVideo" ref={videoRef} style={videoRef.current ? { display: "block" } : { display: "none" }}></video>
          <video autoPlay playsInline id="localVideo" ref={localRef} style={localRef.current ? { display: "block" } : { display: "none" }}></video> */}
          {chat ? (
            <SectionChat active={chat}>
              <DivChat>
                <div className="chat" id="chat-feed">
                  <div className="chat-messages" ref={chatFeedRef}>
                    {messages.map((msg: IMessage) => (
                      <>
                        <span
                          className={
                            user && user.id === msg.idUser ? "myMessage" : ""
                          }
                        >
                          {user && user.id !== msg.idUser ? msg.nameUser : "Eu"}
                        </span>
                        <p
                          className={
                            user && user.id === msg.idUser ? "myMessage" : ""
                          }
                        >
                          {msg.message}
                        </p>
                      </>
                    ))}
                  </div>
                  <FormChat onSubmit={handleSendMessage}>
                    <InputComponent
                      placeholder="Mensagem"
                      type="text"
                      value={valueMessage}
                      onChange={({ target }) =>
                        handleValueMessage(target.value)
                      }
                    />
                    <button type="submit">
                      <MdSend />
                    </button>
                  </FormChat>
                </div>
              </DivChat>
            </SectionChat>
          ) : (
            <Reply active={question ? true : false}>
              {question?.respostas.map((item: IResponse) => (
                <CardReply
                  onClick={() => handleReplySelected(item.id)}
                  key={item.id}
                  className={
                    reply === item.id && !responseCorrect
                      ? "active"
                      : responseCorrect && responseCorrect.id === item.id
                      ? "active"
                      : responseCorrect && responseCorrect.id !== reply
                      ? "danger"
                      : ""
                  }
                >
                  <button value={item.id}>{item.nome}</button>
                </CardReply>
              ))}
            </Reply>
          )}
        </DivMain>
      </Question>
      <FooterSection>
        <div>
          <div style={{ display: "flex" }}>
            <IoIosArrowForward style={{ height: 28, width: 28 }} />
            <IoIosArrowForward
              style={{ marginLeft: -22, height: 28, width: 28 }}
            />
            <IoIosArrowForward
              style={{ marginLeft: -22, height: 28, width: 28 }}
            />
          </div>
          <p>{cardRoom?.nome?.replace("LOC - ", "")}</p>
        </div>
        <img
          src={LogoQuizz}
          height={34}
          width={140}
          style={{ placeSelf: "center" }}
        />
        <div>
          <button onClick={() => onInfoGameOpen()}>
            <img src={IconInfo} />
          </button>
          <button onClick={() => setChat(!chat)}>
            <img src={IconChat} />
          </button>
        </div>
      </FooterSection>
      {/* <Reply>
        <input type="radio" name="tabs" id="tab1" value="tab1" />
        <label htmlFor="tab1" className="tab-button">
          Alternativas
        </label>

        <input type="radio" name="tabs" id="tab2" value="tab2" />
        <label htmlFor="tab2" className="tab-button">
          Chat
        </label>

        <section id="content1">
          <div>
            <div>
              {question?.respostas.map((item: IResponse) => (
                <CardReply
                  key={item.id}
                  className={
                    reply === item.id && !responseCorrect
                      ? "active"
                      : responseCorrect && responseCorrect.id === item.id
                      ? "active"
                      : responseCorrect && responseCorrect.id !== reply
                      ? "danger"
                      : ""
                  }
                >
                  <button
                    onClick={({ target }: any) =>
                      handleReplySelected(target.value)
                    }
                    value={item.id}
                  >
                    {item.nome}
                  </button>
                </CardReply>
              ))}
            </div>

            <FooterSection>
              <p>{cardRoom?.room}</p>
              <p>
                Aposta total{" "}
                <span className="money">
                  {cardRoom && cardRoom?.value
                    ? formatMoney(cardRoom?.value)
                    : null}
                </span>
              </p>
              <p>
                Saldo sala:{" "}
                <span className="money">
                  {balance?.saldo
                    ? formatMoney(balance.saldo)
                    : "R$ 0,00"}
                </span>
              </p>
            </FooterSection>
          </div>
        </section>

        <section id="content2">
          <div>
            <div className="chat" id="chat-feed">
              <div className="chat-messages" ref={chatFeedRef}>
                {messages.map((msg: IMessage) => (
                  <>
                    <span
                      className={
                        user && user.id === msg.idUser ? "myMessage" : ""
                      }
                    >
                      {user && user.id !== msg.idUser ? msg.nameUser : "Eu"}
                    </span>
                    <p
                      className={
                        user && user.id === msg.idUser ? "myMessage" : ""
                      }
                    >
                      {msg.message}
                    </p>
                  </>
                ))}
              </div>
              <FormChat onSubmit={handleSendMessage}>
                <InputComponent
                  placeholder="Mensagem"
                  type="text"
                  value={valueMessage}
                  onChange={({ target }) => handleValueMessage(target.value)}
                />
                <button type="submit">
                  <MdSend />
                </button>
              </FormChat>
            </div>
          </div>
        </section>
      </Reply> */}

      <Modal isOpen={isReconectOpen} onClose={onReconectClose}>
        <ModalOverlay />
        <ModalContent w={"90%"} maxW={400}>
          <ModalHeader>Confirmar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              mb={5}
              textAlign={"center"}
              fontSize={"xl"}
              fontWeight={"bold"}
              color={"blackAlpha.800"}
            >
              Deseja entrar na sala novamente?
            </Text>
            <FormControl>
              <Flex>
                <Text>Sala:</Text>
                <Text fontWeight={"bold"} ml={2}>
                  {cardRoom && cardRoom.nome
                    ? cardRoom?.nome.replace("LOC - SALA", "")
                    : null}
                </Text>
              </Flex>
              <Flex>
                <Text>Valor:</Text>
                <Text fontWeight={"bold"} ml={2}>
                  {cardRoom && cardRoom?.valorIngresso
                    ? formatMoney(cardRoom.valorIngresso)
                    : "R$ 0,00"}
                </Text>
              </Flex>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme={"green"}
              isLoading={loadingInscription}
              spinnerPlacement="start"
              onClick={() => handleConfirm()}
            >
              Confirmar
            </Button>
            <Button
              colorScheme="blue"
              disabled={loadingInscription}
              spinnerPlacement="start"
              ml={3}
              onClick={() => handleCancel()}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal Ranking */}
      <Modal isOpen={isRankingOpen} onClose={onRankingClose}>
        <ModalOverlay />
        <ModalContent w={"90%"}>
          <ModalHeader
            sx={{
              textShadow:
                "-1px -1px 0 rgba(0, 0, 0, 0.8), -1px 1px 0 rgba(0, 0, 0, 0.8), 1px -1px 0 rgba(0, 0, 0, 0.8), 1px 0 0 rgba(0, 0, 0, 0.8)",
              fontSize: 34,
            }}
            color={"var(--green-lighter200)"}
            fontWeight={"bold"}
            paddingBottom={0}
          >
            Ranking
          </ModalHeader>
          <ModalCloseButton onClick={() => navigate("/")} />
          <ModalBody>
            <Card
              borderColor={"var(--black)"}
              borderStyle={"solid"}
              borderWidth={1}
              borderRadius={8}
              paddingY={2}
            >
              {ranking.map((item: IGameOver, index: number) => (
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  paddingInline={2}
                  key={index}
                  sx={{
                    "@media (max-width: 400px)": {
                      paddingBottom: 1,
                      paddingInline: 1,
                    },
                  }}
                >
                  {/* <Image
                    src={GoldMedal}
                    alt="Medalha de ouro"
                    height={50}
                    width={50}
                    sx={{
                      "@media (max-width: 400px)": {
                        height: "38px",
                        width: "38px",
                      },
                      "@media (max-width: 370px)": {
                        height: "34px",
                        width: "34px",
                      },
                    }}
                  /> */}
                  <Flex alignItems={"center"}>
                    {/* <Image
                      src={user?.foto || IconUser}
                      height={10}
                      width={10}
                      marginRight={2}
                      sx={{
                        "@media (max-width: 400px)": {
                          height: 9,
                          width: 9,
                          marginRight: 1,
                        },
                        "@media (max-width: 370px)": {
                          height: 8,
                          width: 8,
                        },
                      }}
                    /> */}
                    <Text
                      sx={{
                        textShadow:
                          "-1px -1px 0 rgba(0, 0, 0, 0.8), -1px 1px 0 rgba(0, 0, 0, 0.8), 1px -1px 0 rgba(0, 0, 0, 0.8), 1px 0 0 rgba(0, 0, 0, 0.8)",
                        fontSize: 24,
                        marginRight: 6,
                      }}
                      color={"var(--green-lighter200)"}
                      fontWeight={"extrabold"}
                    >
                      {item.position}º
                    </Text>
                    <Text
                      sx={{
                        "@media (max-width: 370px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      {item.nome.length > 20
                        ? `${item.nome.slice(0, 18)}...`
                        : item.nome}
                    </Text>
                  </Flex>
                  <Text
                    sx={{
                      textShadow:
                        "-0.5px -0.5px 0 rgba(0, 0, 0, 0.8), -0.5px 0.5px 0 rgba(0, 0, 0, 0.8), 0.5px -0.5px 0 rgba(0, 0, 0, 0.8), 0.5px 0 0 rgba(0, 0, 0, 0.8)",
                      fontSize: 20,
                    }}
                    color={"var(--green-lighter200)"}
                    fontWeight={"extrabold"}
                    whiteSpace={"nowrap"}
                  >
                    {item.acertos}/{item.acertos + item.errors}
                  </Text>
                </Flex>
              ))}
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={() => navigate("/")}>
              Escolher outra sala
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal Winner */}
      <Modal isOpen={isWinnerOpen} onClose={onWinnerClose}>
        <ModalOverlay />
        <ModalContent w={"90%"}>
          <ModalHeader>Vencedor</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              fontSize="2xl"
              fontWeight={"extrabold"}
              textAlign={"center"}
              mb={4}
              color="red"
            >
              PARABÉNS, VOCÊ VENCEU O DESAFIO!
            </Text>
            <Text fontSize="xl">
              Você ganhou:{" "}
              <span
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  color: "var(--green-lighter200)",
                }}
              >
                {winner ? formatMoney(winner) : "R$ 0,00"}
              </span>
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal Info Game */}
      <Modal isOpen={isInfoGameOpen} onClose={onInfoGameClose}>
        <ModalOverlay />
        <ModalContent w={"90%"}>
          <ModalHeader>Informações do jogo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign={"center"} mb={4}>
              O Quiz é um jogo de entretenimento aonde o objetivo do jogo é
              utilizar seu conhecimento para lucrar, a cada Quiz será respondido
              15 perguntas referente a modalidade selecionada, o jogador com
              mais acertos receberá o prêmio da rodada, todos os jogadores
              inscritos terão a mesma pergunta e o mesmo tempo para responder,
              caso esgote o tempo da pergunta e o player não selecionou nenhuma
              alternativa a resposta ficará como errada para esta pergunta, mas
              o jogador poderá continuar até o final do Quiz, ao final das 15
              perguntas aparecerá um ranking dos jogadores para verificar quem é
              o vencedor e quantas perguntas você conseguiu acertar.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={() => onInfoGameClose()}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalComponent
        title="Confirmar"
        content="Confirmar Resposta?"
        isOpen={isOpen}
        onClose={onClose}
        confirm={setConfirmReply}
      />
    </Container>
  );
};

export default Room;
