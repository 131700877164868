import styled from "styled-components";

interface IStyle {
  bgColor?: boolean;
  wide?: string;
}

interface IOpen {
  isOpen: boolean;
}

export const Container = styled.div`
  background-image: var(--background-gray);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  padding-top: 60px;
  position: fixed;
  top: 0;

  @media (max-width: 950px) {
    flex-direction: column;

    /* & > div:nth-of-type(1) {
      height: 100px;
      overflow-y: hidden;
    } */
  }
`;

export const DivContainer = styled.div<IStyle>`
  flex-grow: 1;
  height: 100vh;
  width: ${(props: any) => props.wide ? props.wide : "auto"};
  overflow-y: auto;
  overflow-x: hidden;
  transition: all .7s ease-in-out;
  
  @media (max-width: 950px){
    width: 100%;
  }
  
  @media (max-width: 390px){
    padding-bottom: 100px;

  }
`

export const DivContainerSidebar = styled(DivContainer)<IOpen>`
  position: fixed;
  left: ${(props: any) => props.isOpen ? "0" : "-100%"};
  top: 60px;
  z-index: 1;

  @media (max-width: 950px) {
   width: auto;
  }
`;

export const Offer = styled.div`
  background-color: #282828;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > div:nth-child(1){
    margin-left: 30px;

    & > p:nth-of-type(2) {
      color: var(--white);
      font-size: 32px;
      font-weight: 800;
      line-height: 36px;
      width: 60%;
    }
  }

  img {
    width: 500px;
    min-width: 400px;
  }

  .openCard {
    border: 1px solid var(--disabled);
    color: var(--green-lighter300);
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 120px;
    text-align: center;
  }

  @media (max-width: 768px) {
    & > div:nth-of-type(1) > p:nth-of-type(1) {
      font-size: 14px;
    }
    & > div:nth-of-type(1) > p:nth-of-type(2) {
      font-size: 24px;
      width: 80%;
    }
  }

  @media (max-width: 650px) {
    img{
      min-width: 300px;
    }
  }
  
  @media (max-width: 550px) {
    img{
      min-width: 250px;
    }
  }
`;

export const DivRow = styled.div`
  display: flex;
  width: 100%;

  & > div {
    width: 100%;
  }

  /* &s */

  /* & > div:nth-of-type(2){
    padding: 0 20px;
    width: 35%;
  } */
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &>p{
    color: var(--disabled);
    font-weight: 600;
  }>span{
    color: var(--disabled);
    display: flex;
    align-items: center ;
    font-size: .8rem;

    & > svg:nth-of-type(1){
      margin-left: 10px;
    }

    & > svg {
      color: var(--green-lighter200);
      margin-right: -8px;
    }
  }

  .alert{
    color: var(--alert);
    font-size: 1.2rem;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const LineHeight = styled.hr`
  background-color: #ddd;
  height: 90%;
  width: 1px;
`;

export const Sidebar = styled.div`
  background-color: var(--black-lighter200);
  color: #c3c3c3;
  min-width: 200px;
  /* margin-left: -200px; */
  padding: 10px 20px;
  transition: ease-in-out .5s;
  transform: translateX(0);
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  height: 100%;

  &.activeMenu{
    margin-left: 0;
  }

  & > ul {
    height: 80vh;
    min-height: fit-content;
    overflow: auto;
  }

  /* & > ul::-webkit-scrollbar {
    display: block;

    @media (max-width: 950px) {
      display: none;
    }
  } */

  & > .lista-com-scrollbar {
    overflow: auto;
  }

  & > ul::-webkit-scrollbar {
    width: 0;
  }

  & > .lista-com-scrollbar.scrollbar-visivel::-webkit-scrollbar {
    width: 4px;
  }

  & > ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--form-background);
  }
  
  & > ul::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--green-lighter300);
    outline: 1px solid var(--green-lighter200);
  }


  & > ul > li{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 400;
    list-style: none;
    margin: 10px 0;
    
    & svg {
      color: var(--green-lighter400);
      margin-right: 8px;
      height: 22px;
      width: 22px;
    }
    
    & > div {
      margin-right: 8px;
      height: auto;
      width: 22px;
    }
    
    & > p {
      max-width: 80%;
      text-transform: lowercase;
      white-space: break-spaces;
    }

    & > p::first-letter{
      text-transform: capitalize;
    }
    
    &:hover{
      background-color: var(--form-background);//#404040;
    }
    
    &.active{
      color: var(--white);
      font-weight: bold;

      & > svg {
        color: var(--green-lighter400);
      }
    }
  }

  @media (max-width: 950px){
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    /* width: 100%; */
    overflow-y: hidden;
    
    /* & > p{
      display: none;
    } */

    & > ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-x: auto;
      /* height: auto; */

      & > li {
        display: flex;
        margin-left: 20px;
        /* flex-direction: column; */
        /* min-width: 140px; */
        /* text-align: center; */
      }
    }
  }

  @media (max-width: 414px){

    padding: 10px;

    & > ul > li {
      min-width: 110px;
    }
  }
`;

export const Aside = styled.aside`
  background-color: var(--menu-background);
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 0 20px 10px;
  z-index: 0;
  position: relative;

  &.small{
    display: block;
    padding-bottom: 20px;
    width: 100%;
  }
  
  @media (max-width: 1200px) {
    display: none;
   /* &.small{
     display: block;
   } */
  }
`;

export const TitleSidebar = styled.p<IStyle>`
  /* border-bottom: 1px solid var(--white); */
  color: ${(props): any => props.bgColor ? "var(--green-lighter400)" : "var(--green-lighter)"};
  font-size: 16px;
  font-weight: 800;
  margin-top: 22px;
  padding: 0 20px 0 0;
  word-wrap: normal;
`;

export const SubTitle = styled.p`
  color: var(--disabled);
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
`;

export const TextAside = styled.p`
  color: var(--disabled);
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  width: 90%;
  word-wrap: break-word;
  text-align: justify;
`;

export const LabelMenu = styled.label`
  cursor: pointer;
  position: relative;
  display: none;
  height: 22px;
  width: 30px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: auto;

  span{
    position: absolute;
    display: none;
    display: block;
    height: 2px;
    width: 24px;
    border-radius: 30px;
    background: var(--white);
    transition: 0.25s ease-in-out;
    
  }
  
  @media(max-width: 950px){
    display: block;
  }

  span:nth-child(1){
    top: 0;
  }
  span:nth-child(2){
    top: 8px;
  }
  span:nth-child(3){
    top: 16px;
  }

`

export const DivBottom = styled.div`
  display: flex;
  background: var(--background-gray);
  height: auto;
  flex: 1 1 auto;
  width: 100%;
  padding-bottom: 60px;

  section {
    display: none;
    padding: 20px 0 0;
    /* background-color: var(--form-background); */
    /* border-top: 2px solid var(--green-lighter200); */
  }

  input[type="radio"] {
    display: none;
  }

  input[type="search"] {
    border-radius: 8px;
    padding: 6px;
  }

  label {
    /* border-right: 1px solid #ddd; */
    color: var(--white);
    display: inline-block;
    font-weight: 800;
    margin: 8px 0 -2px;
    padding: 12px 0 12px 18px;
    text-align: center;

    @media (max-width: 414px) {
      padding: 10px 0 10px 12px;
    }
      
    @media (max-width: 345px) {
      font-size: 14px;
      padding: 10px 6px;
    }

    span{
      color: var(--green-lighter300);
    }
  }



  label:nth-child(-n+4):after {
    content: "";
    font-weight: normal;
    margin-left: 20px;
    border-right: 1px solid #ddd;
  }

  label:hover {
    color: #888;
    cursor: pointer;
  }

  input:checked + label {
    color: var(--white);
    /* border-left: 1px solid #ddd; */
    border-bottom: 3px solid var(--alert);
    /* border-bottom: 1px solid var(--form-background); */
  }

  #tab1:checked ~ #content1,
  #tab2:checked ~ #content2,
  #tab3:checked ~ #content3 {
    display: flex;
    flex-direction: column;
    /* overflow-x: auto; */
  }

  .encerradas {
    background-color: #494949;
    border-top: none;
  }

  @media (max-width: 950px) {
    width: 100%;
    /* padding-bottom: 160px; */
  }
`;

export const SlideShow = styled.div`
  display: flex;
  flex-direction: column;
  height: 40vh;
  width: 100%;
  
  .slick-track {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0 auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    float: left;
    height: 40vh;
    width: 100%;
  }

  input[type="radio"] {
    display: none;
  }
  
  a:active {
    bottom: 1px;
  }

  .slick-prev {
    display: none !important;
  }
  
  .slick-next {
    display: none !important;
  }

  .slide-number {
    width: 100vw;
    height: 40vh;
    float: left;
    flex-shrink: 0;
    scroll-snap-align: start;
    overflow-y: hidden;
  }

  ul {
    bottom: 20px;
  }
  
  ul > li {
    background: white;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin: -20px 10px -0.5rem 0;
    text-decoration: none;
    z-index: 1;
    padding: 0;
  }

  @media (max-width: 950px) {
    .slide-number {
      width: 100vw;
    }
  }

  @media (max-width: 414px) {
    height: 32vh;
    .slick-track, .slide-number{
      height: 32vh;
    }
  }

`;

export const SearchContainer = styled.div`
  position: relative;
  display: inline-block;
  float: right;
  margin-top: 10px;
  margin-right: 20px;
  height: 40px;

  @media (max-width: 504px) {
    width: 39%;
  }
  
  @media (max-width: 481px) {
    width: 100%;
    margin-right: 10px;
  }
`;

export const SearchInput = styled.input`
  position: absolute;
  top: 0;
  right: 10px;
  padding-right: 20px;
  appearance: none;
  ::-webkit-search-cancel-button {
    display: none;
  }

  @media (max-width: 481px) {
    float: right;
    margin-bottom: 6px;
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #999;
  cursor: pointer;
`;



export const Card = styled.div`
  background: transparent;
  border: none;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  min-height: 80px;
  min-width: 245px;
  margin-bottom: 6px;
  padding: 8px;
  transition: 0.3s;
  
  h2 {
    color: var(--green-lighter300);
    display: flex;
    align-items: baseline;
    font-weight: 700;
    width: 90%;

    span{
      background-color: var(--green-lighter300);
      border-radius: 50%;
      margin-right: 8px;
      height: 6px;
      width: 6px;
    }
  }
  
  div {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-right: 10px;
    width: 30%;
    min-width: max-content;

    @media (max-width: 425px) {
      width: max-content;
    }
  }

  div:nth-of-type(2) {
    position: relative;
  }

  div:nth-last-of-type(2) > p::before{
    content: "";
    border: 1px solid var(--disabled);
    border-radius: 50%;
    margin-right: 6px;
    height: 6px;
    width: 6px;
  }

  div:nth-last-of-type(2) > p::after{
    content: "";
    position: absolute;
    top: 15px;
    left: 2.5px;
    height: 17px;
    width: 1px;
    background-color: var(--disabled);
  }

  p {
    color: var(--disabled);
    display: flex;
    align-items: center;
    font-size: .9rem;
    white-space: nowrap;
  }

  &.inactive {
    cursor: no-drop;
  }

  .alert {
    color: var(--alert);
  }

  .open {
    color: var(--green-lighter300);
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 800;
  }

  .closed {
    color: var(--warning) !important;
    font-size: 1rem;
    font-weight: 800;
  }

  .money {
    color: var(--green-lighter300);
    display: flex;
    font-size: 1rem;
    font-weight: 800;
    margin-right: 10px;

    & > svg {
      margin-right: -10px;
    }
  }

  .openCard {
    border: 1px solid var(--disabled);
    color: var(--green-lighter300);
    font-size: 1rem;
    font-weight: 600;
    width: 120px;
    text-align: center;
  }
  
  .alertCard {
    border: 1px solid var(--disabled);
    color: var(--alert);
    font-size: 1rem;
    font-weight: 600;
    width: 120px;
    text-align: center;
  }
`;


export const CardChampion = styled.div`
  border-bottom: 1px solid var(--disabled);
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Loading = styled.span`
  width: 48px;
  height: 48px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid var(--green-lighter300);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
`;

export const TitleRoom = styled.p`
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  margin-left: 20px;
`;