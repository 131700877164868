import React, { createContext, useState, useEffect, useContext } from "react";
import { BASE, CURRENT_STORE } from "../environments";
import provider from "../services/config";
import { authProvider } from "../services";
import Toast from "../components/Toast";
import { getUser } from "../services/user";
export interface ISignInPayload {
  email: string;
  password: string;
}
interface AuthContextData {
  signed: boolean;
  user: authProvider.IEntidade | null;
  loading: boolean;
  signIn(payload: ISignInPayload): Promise<true | void>;
  signOut(): void;
  updateUser(payload: authProvider.IEntidade): void;
  getUserData(id: string): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<any> = ({ children }) => {
  const [user, setUser] = useState<authProvider.IEntidade | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadStorageData() {
      const storagedUser = localStorage.getItem(
        `@${CURRENT_STORE}${BASE}:user`
      );

      if (storagedUser) {
        const user: authProvider.IEntidade = JSON.parse(storagedUser);
        setUser(user);
        provider.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${user.token}`;
      }

      setLoading(false);
    }

    loadStorageData();
  }, []);

  async function signIn(payload: ISignInPayload) {
    const loginPayload = {
      username: payload.email.toLowerCase().trim(),
      password: payload.password,
    };
    try {
      const response = await authProvider.login(loginPayload);
      setUser(response);
      provider.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.token}`;
      localStorage.setItem(
        `@${CURRENT_STORE}${BASE}:user`,
        JSON.stringify(response)
      );
      return true;
    } catch (err: any) {
      return Toast.show(err.message);
    }
  }

  async function getUserData(id: string) {
    try {
      const response = await getUser(id);

      if (response) {
        updateUser(response);
      }
    } catch (err: any) {
      return Toast.show(err.message);
    }
  }

  function updateUser(payload: authProvider.IEntidade) {
    let DUserGroup: any = { ...user?.DUserGroup };
    let Login: any = { ...user?.Login };
    let token: any = user?.token;
    payload["DUserGroup"] = DUserGroup;
    payload["Login"] = Login;
    payload["token"] = token;

    setUser(payload);
    localStorage.setItem(
      `@${CURRENT_STORE}${BASE}:user`,
      JSON.stringify(payload)
    );
  }

  async function signOut() {
    localStorage.removeItem(`@${CURRENT_STORE}${BASE}:user`);
    provider.defaults.headers.common["Authorization"] = "";
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signIn,
        signOut,
        updateUser,
        getUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export { AuthProvider, useAuth };
