import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Button } from "@chakra-ui/react";
import InputComponent from "../../components/input";
import { Container, Content, Link } from "./styles";
import { useAuth } from "../../contexts/auth";
import Toast from "../../components/Toast";
import { useNavigate } from "react-router-dom";
import { BASE, CURRENT_STORE } from "../../environments";
import Logo from "../../assets/logo_quizz_cash.webp";
import { forgotPassword } from "../../services/auth";

export interface IResetPassword {
  email: string;
  senha: string;
  confirm_password: string;
}

const RetrievePassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [payload, setPayload] = useState<string>("");

  useEffect(() => {
    if (localStorage.getItem(`@${CURRENT_STORE}${BASE}:user`)) {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();

    setLoading(true);
    if (validate()) {
      try {
        const result = await forgotPassword(payload);
        if (result) {
          Toast.show("Verifique seu email", "success");
          Toast.show("Enviado com sucesso!", "success");
          navigate("/login");
          setLoading(false);
        }
      } catch (error) {
        console.error("Erro: ", error)
      }
    }
    setLoading(false);
  };

  const validate = () => {
    if (!payload) {
      Toast.show("O campo email é obrigatório", "error");
      return false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(payload)) {
      Toast.show("Este endereço de e-mail é inválido", "error");
      return false;
    }
    // if (!payload.senha) {
    //   Toast.show("O campo senha é obrigatório", "error");
    //   return false;
    // } else if (payload.senha.length < 8) {
    //   Toast.show("A senha precisa ter no mínimo 8 dígitos", "error");
    //   return false;
    // }
    // if (!payload.confirm_password.trim()) {
    //   Toast.show("O campo confirmar senha é obrigatório", "error");
    //   return false;
    // } else if (payload.confirm_password !== payload.senha) {
    //   Toast.show(
    //     "O campo confirmar senha precisa ser igual ao campo senha",
    //     "error"
    //   );
    //   return false;
    // }
    return true;
  };

  const handlePayload = (value: string) => {
    setPayload(value);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit} autoComplete="chrome-off">
        <Content>
          <Box
            p={"9px"}
            rounded={"lg"}
            boxShadow={"md"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            w={310}
            h={350}
          >
            <img src={Logo} width={50} />
            <label>Recuperar Senha</label>
            <InputComponent
              placeholder="E-mail"
              type="email"
              autoComplete="chrome-off"
              onChange={(ev: any) => handlePayload(ev.target.value)}
            />
            {/* <InputComponent
              placeholder="Digite sua senha"
              type="password"
              autoComplete="chrome-off"
              onChange={(ev: any) => handlePayload("senha", ev.target.value)}
            />
            <InputComponent
              placeholder="Digite sua senha novamente"
              type="password"
              autoComplete="chrome-off"
              onChange={(ev: any) =>
                handlePayload("confirm_password", ev.target.value)
              }
            /> */}
            <Button
              width={"full"}
              mt={5}
              mb={2}
              isLoading={loading}
              bg={"#0a4f00"}
              border={"2px"}
              borderRadius={"3xl"}
              color={"white"}
              borderColor={"#0a4f00"}
              type="submit"
            >
              ENVIAR
            </Button>
            <Button
              w={"full"}
              bg={"light"}
              border={"2px"}
              borderColor={"white"}
              borderRadius={"3xl"}
              isDisabled={loading}
            >
              <Link to={"/login"}>LOGIN</Link>
            </Button>
          </Box>
        </Content>
      </form>
    </Container>
  );
};

export default RetrievePassword;
