import Provider from "./config";
import Toast from "../components/Toast";

export const getPayments = async () => {
	try {
		const response = await Provider.get("tabela/", {
			params: {
				classe: "Condições de Pagamento"
			}
		})

		return response.data
	} catch (err: any) {
		if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
			throw Error("Erro ao buscar usuário");
		}
	}
}

export const getHistoryAvailable = async (id: string, page: number) => {
	try {
		const response = await Provider.get("movdispo/", {
			params: {
				idLocEscritu: id,
				page: page
			}
		})

		return response.data
	} catch (err: any) {
		if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
			throw Error("Erro ao buscar histórico");
		}
	}
}

export const postPayment = async (payload: object) => {
	try {
		const response = await Provider.post("pedidos/deposito/", payload)

		return response
	} catch (err: any) {
		if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
			throw Error("Erro ao fazer depósito");
		}

	}
}

export const postAccountBank = async (payload: object) => {
	try {
		const response = await Provider.post("entidades/conta-bancaria/", payload)

		return response
	} catch (err: any) {
		if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
			throw Error("Erro ao cadastrar banco");
		}
	}
}

export const getAccountBank = async (id: string) => {
	try {
		const response = await Provider.get("entidades/conta-bancaria/", {
			params: {
				idLocEscritu: id
			}
		})

		return response.data
	} catch (err: any) {
		if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
			throw Error("Erro ao buscar lista de bancos");
		}
	}
}

export const postAlterBank = async (idUser: string, idEscritu: string, idBank: string) => {
	try {
		const response = await Provider.post("entidades/ativa-conta-bancaria/", {
			idUser: idUser,
			idLocEscritu: idEscritu,
			idBancoToken: idBank
		})

		return response
	} catch (err: any) {
		if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
			throw Error("Erro ao alterar bancos");
		}
	}
}

export const postRequestSack = async (payload: object) => {
	try {
		const response = await Provider.post("entidades/saque/", payload)

		return response
	} catch (err: any) {
		if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
			throw Error("Erro ao solicitar saque");
		}
	}
}

export const getConfirmPix = async (idChCriacao: number) => {
	try {
		const response = await Provider.get("titulos/verifica-baixa/", {
			params: {
				chcriacao: idChCriacao
			}
		})

		return response.data
	} catch (err: any) {
		if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
			throw Error("Erro ao confirmar pagamento no PIX");
		}
	}
}