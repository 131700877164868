import styled from "styled-components";

export const Container = styled.div`
	background: var(--green-lighter500);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	padding: 60px 0;
`;

export const Numbers = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 220px;

	& p:nth-of-type(1) {
		color: var(--green-lighter400);
		font-size: 3rem;
		font-weight: bold;
		line-height: 4rem;
	}

	& p:nth-of-type(2) {
		color: var(--white);
	}

	@media (max-width: 879px) {
		min-width: fit-content;
		margin-right: 16px;
	}
	@media (max-width: 689px) {
		& p:nth-of-type(1) {
			font-size: 2.5rem;
		}
		& p:nth-of-type(2) {
			font-size: 14px;
		}
	}
	@media (max-width: 612px) {
		& p:nth-of-type(1) {
			font-size: 2.3rem;
		}
		& p:nth-of-type(2) {
			font-size: 12px;
		}
	}
	@media (max-width: 552px) {
		& p:nth-of-type(1) {
			font-size: 2rem;
		}
		& p:nth-of-type(2) {
			font-size: 10px;
		}
	}
	@media (max-width: 454px) {
		& p:nth-of-type(1) {
			font-size: 1.8rem;
		}
		& p:nth-of-type(2) {
			font-size: 8px;
		}
	}
	@media (max-width: 376px) {
		& p:nth-of-type(1) {
			font-size: 1.6rem;
		}
		& p:nth-of-type(2) {
			font-size: 7px;
		}
	}
`;