import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import InputComponent from "../../components/input";
import { Container, Content, Link } from "./styles";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Toast";
import { postRegister } from "../../services/register";
import { BASE, CURRENT_STORE } from "../../environments";
import Logo from "../../assets/logo_quizz_cash.webp";
import { validateCPF } from "validations-br";

interface IPayload {
  nome: string;
  email: string;
  senha: string;
  confirm_password: string;
  telefone: string;
  dataNascimento: string;
  cpfCnpj: string;
}

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [payload, setPayload] = useState<IPayload>({
    nome: "",
    email: "",
    senha: "",
    telefone: "",
    dataNascimento: "",
    confirm_password: "",
    cpfCnpj: "",
  });

  useEffect(() => {
    if (localStorage.getItem(`@${CURRENT_STORE}${BASE}:user`) !== null) {
      navigate("/home");
    }
  }, [navigate]);

  const handleSubmit = async () => {
    // ev.preventDefault();
    onClose();
    setConfirmModal(true);
    if (validate()) {
      setLoading(true);
      try {
        let { nome, email, telefone, dataNascimento, senha, cpfCnpj } = payload;
        dataNascimento = dataNascimento?.replaceAll("/", "-");
        dataNascimento = dataNascimento?.split("-").reverse().join("-");
        dataNascimento += "T00:00:00.000Z";

        const result = await postRegister({
          nome,
          email,
          telefone,
          dataNascimento,
          senha,
          cpfCnpj,
        });
        if (result) {
          Toast.show("Dados enviados com sucesso.", "success");
          Toast.show("Verifique seu e-mail.", "success");
          navigate("/login");
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
  };

  const validate = () => {
    if (!payload.nome) {
      Toast.show("O campo nome é obrigatório", "error");
      return false;
    }
    if (!payload.email) {
      Toast.show("O campo email é obrigatório", "error");
      return false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(payload.email)
    ) {
      Toast.show("Este endereço de e-mail é inválido", "error");
      return false;
    }
    if (!payload.telefone.trim()) {
      Toast.show("O campo telefone é obrigatório", "error");
      return false;
    } else if (/[_]/.test(payload.telefone)) {
      Toast.show(
        "O campo telefone precisa ser preenchido corretamente",
        "error"
      );
      return false;
    }
    if (!payload.dataNascimento) {
      Toast.show("O campo data de nascimento é obrigatório", "error");
      return false;
    } else if (/[_]/.test(payload.dataNascimento)) {
      Toast.show(
        "O campo data de nascimento precisa ser preenchido corretamente",
        "error"
      );
      return false;
    } else if (payload.dataNascimento.length > 9) {
      const dataNascimento = payload.dataNascimento.split("/");
      const anoNascimento = +dataNascimento[2];
      const year = new Date().getFullYear();

      if (Number(year) - anoNascimento < 18) {
        Toast.show("O usuário precisa ter idade superior a 18 anos", "error");
        return false;
      }
    }
    if (!payload.cpfCnpj) {
      Toast.show("O campo CPF é obrigatório", "error");
      return false;
    } else if (/[_]/.test(payload.cpfCnpj)) {
      Toast.show("O campo CPF precisa ser preenchido corretamente", "error");
      return false;
    } else {
      let isValid;
      if (payload.cpfCnpj.replace(/\D/g, "").length < 12) {
        isValid = validateCPF(payload.cpfCnpj);
        if (!isValid) {
          Toast.show("CPF inválido", "error");
          return false;
        }
      }
    }
    if (!payload.senha.trim()) {
      Toast.show("O campo senha é obrigatório", "error");
      return false;
    } else if (payload.senha.length < 8) {
      Toast.show("A senha precisa ter no mínimo 8 dígitos", "error");
      return false;
    }
    if (!payload.confirm_password.trim()) {
      Toast.show("O campo confirmar senha é obrigatório", "error");
      return false;
    } else if (payload.confirm_password !== payload.senha) {
      Toast.show(
        "O campo confirmar senha precisa ser igual ao campo senha",
        "error"
      );
      return false;
    }
    return true;
  };

  const handlePayload = (key: string, value: any) => {
    setPayload({ ...payload, [key]: value });
  };

  const handleConfirmModal = (ev: any) => {
    ev.preventDefault();
    if(confirmModal){
      handleSubmit()
    } else 
      onOpen()
  }

  return (
    <Container>
      <form onSubmit={handleConfirmModal} autoComplete="chrome-off">
        <Flex h={"full"} align={"center"} justify="center" overflow={"auto"}>
          <Content>
            <Box
              p={4}
              rounded={"lg"}
              boxShadow={"md"}
              display={"flex"}
              flexDir={"column"}
              alignItems={"center"}
              w={310}
            >
              <img src={Logo} width={50} />
              <label>Nome</label>
              <InputComponent
                placeholder="Nome Completo"
                type="text"
                onChange={(ev: any) => handlePayload("nome", ev.target.value)}
              />
              <label>E-mail</label>
              <InputComponent
                placeholder="E-mail"
                type="email"
                onChange={(ev: any) => handlePayload("email", ev.target.value)}
              />
              <label>Telefone</label>
              <InputComponent
                masked={true}
                mask={"(99) 99999-9999"}
                placeholder="(99) 99999-9999"
                type="text"
                onChange={(ev: any) =>
                  handlePayload("telefone", ev.target.value)
                }
              />
              <label>Data de Nascimento</label>
              <InputComponent
                masked={true}
                mask={"99/99/9999"}
                placeholder="dd/mm/aaaa"
                type="text"
                onChange={(ev: any) =>
                  handlePayload("dataNascimento", ev.target.value)
                }
              />
              <label>CPF</label>
              <InputComponent
                masked={true}
                mask={"999.999.999-99"}
                placeholder="999.999.999-99"
                type="text"
                autoComplete="chrome-off"
                onChange={(ev: any) =>
                  handlePayload("cpfCnpj", ev.target.value)
                }
              />
              <label>Senha</label>
              <InputComponent
                placeholder="Senha"
                type="password"
                autoComplete="chrome-off"
                onChange={(ev: any) => handlePayload("senha", ev.target.value)}
              />
              <label>Confirmar Senha</label>
              <InputComponent
                placeholder="Digite sua senha novamente"
                type="password"
                autoComplete="chrome-off"
                onChange={(ev: any) =>
                  handlePayload("confirm_password", ev.target.value)
                }
              />
              <Button
                width={"full"}
                mt={5}
                mb={2}
                isLoading={loading}
                bg={"#0a4f00"}
                border="2px"
                borderColor={"#0a4f00"}
                borderRadius={"3xl"}
                color={"white"}
                type="submit"
                // onClick={() => handleSubmit()}
              >
                CADASTRAR
              </Button>
              <Button
                mb={2}
                w={"full"}
                bg={"light"}
                isDisabled={loading}
                border={"2px"}
                borderColor={"white"}
                borderRadius={"3xl"}
              >
                <Link to={"/login"}>Voltar ao Login</Link>
              </Button>
            </Box>
          </Content>
        </Flex>
      </form>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            TERMO DE USO, ACEITAÇÃO E UTILIZAÇÃO DO SITE
          </ModalHeader>
          <ModalCloseButton isDisabled={loading} />
          <ModalBody>
            <Text mb={4}>
              Leia com atenção e atentamente todas as explicações abaixo antes
              de prosseguir.
            </Text>
            <Text mb={4}>
              Preambulo: Este termo de uso, aceitação e utilização é um contrato
              formal e legal do usuário com a VINTE ZERO CINCO, referente a
              utilização do site de apostas.
            </Text>
            <Text mb={4}>
              Ao clicar na caixa de diálogo ao final deste termo, você declara
              que está de acordo com os termos de uso, aceitação, cadastro,
              regras do site, incluindo todos os produtos disponíveis no
              domínio, e considera-se que você leu, interpretou e entendeu todos
              os termos e condições deste documento.
            </Text>
            <Text mb={4}>
              Caso você, por algum motivo, não concordar com as regras dispostas
              abaixo, pedimos que não aceite as políticas vigentes, não use,
              simule ou acesse nosso site.
            </Text>
            <Text mb={4}>
              Esclarecemos que as políticas e os termos poderão ser alteradas a
              qualquer tempo, inclusive com a retirada ou inclusão de produtos,
              e por qualquer motivo, especialmente, se decorrentes de legislação
              federal, estadual ou municipal, ou até mesmo, de órgãos
              reguladores, como a Receita Federal.
            </Text>
            <Text mb={4}>
              No caso de alterações, você, aqui tratado como usuário, será
              informado e novamente será comunicado do novo termo de uso, que
              poderá ser aceitado ou recusado.
            </Text>
            <Text mb={8}>
              Caso você continue a utilizar dos serviços disponíveis neste site,
              entenderemos que você concorda com as modificações.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={12}
              mb={4}
              fontWeight={"bold"}
            >
              1 - DO CADASTRAMENTO DO USUÁRIO POR MEIO DE IDENTIFICAÇÃO/LOGIN
            </Heading>

            <Text mb={4}>
              1.1. É necessário ter capacidade civil plena para cadastrar-se no
              site da VINTE ZERO CINCO. A capacidade plena significa a aptidão
              que a pessoa tem de adquirir e exercer direitos, logo apenas
              maiores de 18 anos podem se cadastrar. Pessoas menores de 18 anos,
              ou aqueles que, por causa transitória ou permanente, não puderem
              exprimir sua vontade, não poderão participar dos produtos deste
              site e tampouco cadastrar-se. Caso o façam, poderão ser
              automaticamente seus dados de cadastro ou conta excluídos.
            </Text>
            <Text mb={4}>
              1.2. O usuário deve possuir um endereço de e-mail válido para
              criar sua conta ou realiza-lo por meio dos acessos automáticos
              disponíveis pelos sistemas vinculados de contas.
            </Text>
            <Text mb={4}>
              1.3. Os seguintes dados devem ser informados corretamente de
              acordo com o seu cadastro na Receita Federal, para completar e
              efetivar o cadastro do usuário: Nome completo; Nome da mãe; Data
              de nascimento; CPF.
            </Text>
            <Text mb={4}>
              1.3.1. Se algum dos dados informados não for validado, o cadastro
              não será concluído e o acesso não será permitido.
            </Text>
            <Text mb={4}>
              1.4. Dados bancários ou senhas de qualquer conta não serão
              transferidos para o cadastro e não os serão solicitados sob
              nenhuma circunstância. Denuncie em caso de desconfiança.
            </Text>
            <Text mb={4}>
              1.5. A VINTE ZERO CINCO enviará uma mensagem para o endereço de
              e-mail fornecido pelo usuário nas seguintes situações: a) Após a
              finalização do cadastro, contendo apenas o código de validação do
              cadastro realizado pelo usuário; b) No momento do login, para
              autenticação em duas etapas.
            </Text>
            <Text mb={4}>
              1.6. O endereço de e-mail do remetente da mensagem com o código de
              validação é adm@quizcash.com.br
            </Text>
            <Text mb={4}>
              1.7. A VINTE ZERO CINCO não se responsabiliza por informações
              incorretas ou falsas fornecidas durante o cadastro. É inteiramente
              responsabilidade do usuário fornecer dados corretos e precisos
              durante o cadastro.
            </Text>
            <Text mb={4}>
              1.8. O usuário pode alterar informações cadastrais como sexo,
              celular, endereço, entre outros, no canal apropriado do site,
              assim como solicitar informações de privacidade e dados.
            </Text>
            <Text mb={4}>
              1.9. A senha de acesso é criada pelo próprio usuário e é pessoal e
              intransferível.
            </Text>
            <Text mb={4}>
              1.9.1. A senha deve ter exatamente XXXXXX caracteres
              alfanuméricos.
            </Text>
            <Text mb={4}>
              1.9.2. Se o usuário esquecer a senha, ele pode solicitar o envio
              de uma nova senha através de uma opção específica no site.
            </Text>
            <Text mb={4}>
              1.9.2.1. A nova senha será enviada para o e-mail cadastrado no
              site e deve ser alterada no primeiro acesso após a geração da nova
              senha.
            </Text>
            <Text mb={4}>
              1.10. A VINTE ZERO CINCO reserva-se no direito de recusar qualquer
              cadastro que julgar necessário por motivos de segurança da
              informação ou que poderá ser tratado como fraudulento.
            </Text>
            <Text mb={4}>
              1.11. Para utilizar o portal, após o cadastro, são coletados e
              armazenados dados dos usuários, conforme estabelecido na Lei nº
              13.709/2018. Não ocorre nenhum outro tipo de manipulação dos dados
              dos clientes, e quaisquer alterações ou exclusões de dados só
              serão feitas mediante solicitação ou ação do próprio Titular.
            </Text>
            <Text mb={4}>
              1.12. Caso o usuário solicite a exclusão de dados ou o
              cancelamento do cadastro, e houver apostas saldos vinculados em
              seu CPF, o cadastro será removido da base de dados, mas os dados
              das apostas vinculadas permanecerão no banco de dados interno da
              VINTE ZERO CINCO.
            </Text>
            <Text mb={4}>
              1.13. Se o usuário solicitar a exclusão de dados ou o cancelamento
              do cadastro quando houver uma aposta, o prazo de validade do
              prêmio será mantido por 30 (trinta) dias, para que possa ser
              resgatado, por meio dos canais administrativos. Após esse prazo
              haverá a perda da aposta.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              2 - DO CADASTRAMENTO DO USUÁRIO POR MEIO DE IDENTIFICAÇÃO/LOGIN
            </Heading>

            <Text mb={4}>
              2.1. Ao realizar o cadastro, e este estando aprovado, o usuário
              poderá definir o valor de suas apostas, sem limite diário, mas
              sempre visando o controle e gerenciamento de suas apostas.
            </Text>
            <Text mb={4}>
              2.2. A definição das compras é uma decisão que cabe apenas ao
              usuário, ciente que deverá envidar cuidados no sentido de prevenir
              o jogo compulsivo e proteger pessoas vulneráveis dos possíveis
              danos associados aos jogos. Caso tenha problema ou vício
              relacionado a jogos, este site não é recomendado para você e
              recomendamos fortemente que procure ajuda médica.
            </Text>
            <Text mb={4}>
              2.3. Caso o usuário receba ou opte por um nome de usuário, dados
              sigilosos de acesso ou senha como parte integrante dos
              procedimentos de segurança, recomendamos tratar do assunto como
              dados confidenciais, não podendo ser divulgado a ninguém.
            </Text>
            <Text mb={4}>
              2.4. A VINTE ZERO CINCO não poderá ser responsabilizada, em
              qualquer esfera, por uso indevido de sua conta quando acessado por
              terceiros em razão do compartilhamento ou divulgação intencional
              ou não, acidental ou não, dos dados de login, inclusive não
              fazemos qualquer contato com o usuário para que este transmita
              seus dados de acesso.
            </Text>
            <Text mb={4}>
              2.4.1. Caso o usuário desconfie que terceiro identificado ou não
              identificado conhece seus dados de acesso ou que terceiro, usuário
              ou não, pode agir em fraude contra a conta, o usuário deverá
              relatar via denúncia imediatamente à VINTE ZERO CINCO.
            </Text>
            <Text mb={4}>
              2.4.1.1. Ao se cadastrar no Site, você concorda, declara e garante
              que: a) usará dos recursos no site para participar das apostas sem
              a intenção fraudulenta; b) sem realizar operações financeiras
              estritamente pessoal e não profissional, apenas por motivos
              recreativos e de entretenimento; c) participará em nome próprio e
              não em nome de terceiros; d) é o único responsável por relatar e
              pagar quaisquer impostos aplicáveis, de acordo com as lei; e) o
              dinheiro depositado não é proveniente de atividades ilegais ou
              duvidosa; f) entende que ao participar de casa de apostas, haverá
              o risco de perder o dinheiro; e g) não deve se envolver em nenhuma
              atividade fraudulenta, conspiratória ou ilegal em relação à sua
              participação no site, nem utilizar métodos amparados por software
              ou hardware, sob pena de cancelamento da conta.
            </Text>
            <Text mb={4}>
              2.4.2. Para depósitos e saques de fundos para a conta do usuário,
              somente serão aceitos cartões de crédito ou outros instrumentos
              financeiros válidos disponíveis no mercado.
            </Text>
            <Text mb={4}>
              2.4.3. As aportas e conversas devem ser, prioritariamente,
              conduzidos da mesma forma que a vida em sociedade, com urbanidade
              e cordialidade.
            </Text>
            <Text mb={4}>
              2.4.4. É autorizada somente uma conta por registro (CPF). Caso
              dois usuários acessem a mesma conta, e for identificado o acesso
              compartilhado a conta será cancelada e o saldo será revertido em
              favor da VINTE ZERO CINCO.
            </Text>
            <Text mb={4}>
              2.4.5. Caso o usuário se inscreva em mais de uma conta, a VINTE
              ZERO CINCO poderá bloquear, suspender, cancelar e excluir, uma ou
              outras contas, sendo que quaisquer lucros obtidos durante as
              apostas serão vertidos em favor desta.
            </Text>
            <Text mb={4}>
              2.4.5.1. Caso ocorra qualquer uma das hipóteses acima, a VINTE
              ZERO CINCO poderá bloquear o acesso registrado pelo mesmo usuário
              e anular os registros e transações, pelo que quaisquer lucros
              obtidos durante as apostas serão vertidos em favor desta.
            </Text>
            <Text mb={4}>
              2.5. É vedado ao usuário a pretensão de qualquer transferência,
              venda ou penhora de sua conta, inclusive de ativos, direitos ou
              reivindicações de apostas lucradas, e não se limita a apenas
              oneração, penhor, cessão, usufruto, negociação, corretagem,
              hipoteca e/ou doação em cooperação com um fiduciário ou qualquer
              outro terceiro, empresa, pessoa física ou jurídica, fundação e /ou
              associação de qualquer forma, exceto nos casos de penhorabilidade
              judicial, desde que devidamente comprovado e oficiado pelo
              Tribunal de origem.
            </Text>
            <Text mb={4}>
              2.6. Para que o usuário possa participar dos produtos e apostas, é
              necessário a efetivação das apostas. Para isso, o usuário precisa
              registrar-se no site e depositar ou transferir o valor em apostas
              que entende conveniente, cujas transferências e encontro de contas
              ocorrerão via getway de pagamento.
            </Text>
            <Text mb={4}>
              2.6.1. O usuário não poderá realizar apostas que excedam o saldo
              de sua conta e que não quite o valor do produto.
            </Text>
            <Text mb={4}>
              2.6.2. O usuário poderá cancelar seu cadastro e encerrar sua conta
              e registro no momento em que considerar pertinente, devendo
              observar que na eventualidade de haver apostas em aberto as mesmas
              deverão ser canceladas, sendo que na existência de saldos o
              reembolso observará o lustro de até 60 (sessenta) dias para a
              devolução, podendo ocorrer a qualquer momento antes desse prazo,
              sujeito à dedução de taxas de transferência.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              3 – SISTEMA DE PAGAMENTOS
            </Heading>

            <Text mb={4}>
              3.1. Para poder apostar e jogar dentro do site, é necessário
              realizar aportes financeiros em dinheiro na sua conta. Você pode
              depositar ou transferir a qualquer momento fazendo o uso de cartão
              de crédito e de meios válidos disponíveis no mercado financeiro
              nacional e aceito pelo site.
            </Text>
            <Text mb={4}>
              3.2. O usuário, ao depositar em sua conta, declara, ausente de
              dúvidas, que os créditos são oriundos de forma licita e que os
              recursos não foram originários de atividades ilegais ou suspeitas.
              A VINTE ZERO CINCO poderá solicitar a comprovação da fonte do
              depósito, caso necessário.
            </Text>
            <Text mb={4}>
              3.2.1. A cobrança pelos serviços da VINTE ZERO CINCO é fixada em
              20% (vinte por cento) de todas as movimentações do usuário em cada
              operação, sendo elas: a) no depósito; e b) no saque.
            </Text>
            <Text mb={4}>
              3.3. Não há limite de depósito ou saque, desde que, quanto último,
              se respeite o depósito mínimo de 30 (trinta) dias para realizar o
              pedido de saque. Ao realizar os saques o usuário entende que, após
              autorizados e creditados não poderão ser cancelados ou recusados,
              exceto nos casos deste termo.
            </Text>
            <Text mb={4}>
              3.3.1. A VINTE ZERO CINCO só aceitará depósitos em nome do titular
              da conta de registro no site, razão pela qual caso forem
              identificados terceiros pagadores os créditos serão retidos até
              que este reclame, comprovadamente, seus recursos.
            </Text>
            <Text mb={4}>
              3.3.2. Caso o usuário identifique crédito ou valores depositados
              por engano ou este verifique que os valores não lhes pertençam, e
              não for devolvido de modo tempestivo quando instado a tanto, os
              próximos créditos serão retidos até a totalidade do valor seja
              adimplida, ressalvado o caso de necessidade de intenção judicial
              para sua devolução, atitude esta que configura apropriação
              indevida e tratada como crime pelo Código Penal Brasileiro em
              vigor.
            </Text>
            <Text mb={4}>
              3.3.3. Ao usuário não será possível sacar saldos ou fundos que
              excedam o saldo em conta.
            </Text>
            <Text mb={4}>
              3.3.3.1. As apostas são produtos consumíveis, portanto, extinto no
              início dos jogos, pelo que não será possível reembolsos ou
              devoluções.
            </Text>
            <Text mb={4}>
              3.3.3.1.1. Processada a aposta, o usuário não pode alterar ou
              cancelar seu interesse, cabendo a apenas este observar os
              critérios e detalhes do seu jogo.
            </Text>
            <Text mb={4}>
              3.3.3.1.2. Os ganhos do usuário serão creditados em sua conta após
              a confirmação do resultado das rodadas de apostas, liquidando-se o
              jogo em aberto e declarando-se o(s) vencedor(es).
            </Text>
            <Text mb={4}>
              3.3.3.2. Os usuários declaram que estão cientes que os saques
              somente ocorrerão após 30 (trinta) dias, exceto se houver acordo
              entre as partes.
            </Text>
            <Text mb={4}>
              3.3.3.2.1. Nos saques, poderão ser exigidos documentos de
              identificação, a depender das circunstâncias.
            </Text>
            <Text mb={4}>
              3.3.3.2.2. Até o início ou durante o jogo, a VINTE ZERO CINCO
              poderá suspender, cancelar ou anular, na totalidade ou não, uma
              aposta e até mesmo a própria rodada do jogo, desde que tenha justa
              causa.
            </Text>
            <Text mb={4}>
              3.3.3.2.3. As solicitações de retirada não serão processadas até
              que todos os critérios de segurança previstos sejam conferidos e
              autorizados.
            </Text>
            <Text mb={4}>
              3.3.3.2.3.1. Jogos interrompidos ou abortados, por qualquer razão,
              não dão direito ao usuário de solicitar reembolso.
            </Text>
            <Text mb={4}>
              3.3.3.2.3.4. As solicitações de retirada serão processadas em até
              05 (cinco) dias úteis.
            </Text>
            <Text mb={4}>
              3.3.3.2.3.5. Os saques serão realizados e creditados diretamente
              na conta bancária do titular do registro, respeitado o prazo de 30
              (trinta) dias.
            </Text>
            <Text mb={4}>
              3.3.3.2.3.6. A VINTE ZERO CINCO poderá recusar o pedido de saque
              caso identifique ou considere o pedido fraudulento ou criminoso,
              garantida a investigada e análise da demanda.
            </Text>
            <Text mb={4}>
              3.3.3.2.3.7. Caso um saque seja cancelado pelo usuário, os valores
              serão devolvidos imediatamente a sua conta, exceto o prazo para o
              processamento do pedido, de até 05 (cinco) dias úteis, e o usuário
              poderá novamente utilizar-se do valor da maneira que melhor lhes
              seja conveniente.
            </Text>
            <Text mb={4}>
              3.4. Como prevenção a lavagem de dinheiro, o usuário declara e
              garante que conhece e cumpre as Leis relativas ao combate a
              corrupção e lavagem de dinheiro, sem limitação à Lei número
              12.846/13 e/ou 9.613/98, comprometendo-se a não praticar atos que
              as levem a violação de qualquer norma, regulamento ou Lei, assim
              como as posturas aplicáveis às boas praticadas recomendadas,
              baseadas nos princípios do contrato, função social, boa-fé e
              transparência.
            </Text>
            <Text mb={4}>
              3.5. Declara que não participa, não realiza, não oferece, direta
              ou indiretamente, qualquer pagamento, oferta de vantagem,
              diligência ou préstimo, para uso ou benefício, direto ou indireto,
              assim como efetuar qualquer pagamento e/ou benefício e/ou
              favorecimento ilegal à autoridade governamental, funcionário
              público, partido político ou candidato a cargo político, em
              qualquer esfera, seja ela municipal, estadual ou federal, bem como
              a funcionários da VINTE ZERO CINCO.
            </Text>
            <Text mb={4}>
              3.6. Declara seguir as recomendações existentes nos tratados
              internacionais, tais como a Convenção Interamericana Contra a
              Corrupção (Convenção da OEA); a Convenção das Nações Unidas Contra
              a Corrupção (Convenção das Nações Unidas); e a Convenção sobre o
              Combate da Corrupção de Funcionários Públicos Estrangeiros em
              Transações Comerciais Internacionais (Convenção da OCDE),
              disponível no sitio do Planalto.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              4 – FISCALIZAÇÃO DE OFÍCIO
            </Heading>

            <Text mb={4}>
              4.1. A VINTE ZERO CINCO reserva-se no direito de analisar e
              verificar qualquer aposta ou transação, parcial ou totalmente. As
              irregularidades podem ser apontadas como as abaixo, mas não se
              limitando: a) quando o usuário, de alguma forma, puder comprometer
              o resultado de qualquer evento; e b) quando o usuário, ou grupo de
              usuários, estiver violando as regras do site ou grupos de chat; c)
              quando o usuário estiver aparentemente envolvido em esquemas de
              apostas que resulta em atividade fraudulenta ou criminosa. Nesses
              casos, a conta será automaticamente suspensa e bloqueada, até que
              as suspeitas se resolvam.
            </Text>
            <Text mb={4}>
              4.2. A VINTE ZERO CINCO reserva-se no direito de analisar e
              verificar qualquer atividade considerada como delituosa como
              lavagem de dinheiro, conluio, trapaça, vantagens indevidas,
              impróprias ou manipulação de jogos ou apostas, e encaminhar para
              as agências ou entidades reguladoras ou policiais para que a lei
              vigente seja aplicada, nestes casos, incluí o deslocamento de
              servidores e localização, como VPN e proxy.
            </Text>
            <Text mb={4}>
              4.2.1. Nesses casos, os saldos eventualmente existentes serão
              bloqueados, as movimentações de conta bloqueada, incluindo saques,
              e os lucros obtidos serão vertidos em favor da VINTE ZERO CINCO,
              sem notificação prévia.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              5 – DO JOGO RESPONSÁVEL
            </Heading>

            <Text mb={4}>
              5.1. A VINTE ZERO CINCO está comprometida em promover uma
              experiência de apostas segura e divertida para todos os usuários.
              Neste item, forneceremos informações e dicas sobre como jogar com
              responsabilidade, reconhecer sinais de comportamento de risco e
              encontrar ajuda, se necessário: a) Conheça seus limites: É
              essencial estabelecer limites antes de começar a jogar. Defina um
              orçamento para o jogo e respeite-o. Nunca aposte mais do que você
              pode perder confortavelmente. Lembre-se de que o jogo é uma forma
              de entretenimento, e não uma fonte de renda garantida; b) Gerencie
              seu tempo: Defina limites de tempo para suas sessões de jogo.
              Mantenha-se consciente do tempo que você gasta jogando e
              equilibre-o com outras atividades importantes em sua vida, como
              trabalho, estudo, relacionamentos e hobbies. Evite o jogo
              excessivo, que pode levar ao isolamento e à negligência de
              responsabilidades; c) Reconheça os sinais de jogo problemático: É
              crucial estar ciente dos sinais de um possível problema de jogo.
              Fique atento a mudanças em seu comportamento, como dificuldade em
              parar de jogar, irritabilidade quando tenta reduzir ou parar,
              mentiras sobre suas atividades de jogo e problemas financeiros
              decorrentes do jogo. Se você notar esses sinais em si mesmo ou em
              alguém próximo a você, é importante buscar ajuda; d) Utilize
              ferramentas de controle: A maioria das plataformas de apostas
              oferece recursos de controle que podem ajudar você a gerenciar seu
              jogo de forma responsável. Isso inclui limites de depósito,
              limites de perdas, limites de tempo e opções de auto exclusão.
              Aproveite essas ferramentas para manter o controle sobre sua
              atividade de jogo e garantir que ela permaneça divertida e segura;
              e) Busque suporte quando necessário: Se você sentir que seu jogo
              está se tornando problemático, não hesite em procurar ajuda.
              Existem organizações e linhas de ajuda especializadas em jogo
              responsável, prontas para fornecer suporte e orientação.
              Compartilhe suas preocupações com amigos ou familiares confiáveis
              e considere buscar aconselhamento profissional.
            </Text>
            <Text mb={4}>
              5.2. Para os casos de auto exclusão, a VINTE ZERO CINCO dispõe de
              canal para que isso ocorra, embora o esforço gerir o comportamento
              seja apenas do usuário, não sendo a auto exclusão o substituto
              ideal para prevenir ou encorajar atitudes. Nesse caso, havendo a
              auto exclusão, os saldos serão vertidos ao usuário no tempo e modo
              devido.
            </Text>
            <Text mb={4}>
              5.3. O jogo responsável é essencial para desfrutar de uma
              experiência de apostas positiva. Lembre-se de que o jogo deve ser
              uma atividade divertida e de lazer, e que a responsabilidade está
              sob controle do usuário.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              6 – DOS DIREITOS AUTORAIS
            </Heading>

            <Text mb={4}>
              6.1. A VINTE ZERO CINCO é única proprietária dos direitos de
              tecnologia de software e sistemas de negócios de perguntas e
              respostas usadas neste site, incluindo textos, gráficos, códigos,
              arquivos e links.
            </Text>
            <Text mb={4}>
              6.2. O site www.quizcash.com.br não pode ser reproduzido,
              transmitido ou armazenado no todo ou em parte sem o consentimento
              por escrito de seu proprietário. O cadastro, identificação,
              registro e uso do nosso é cortesia e não dá nenhum direito ao
              usuário à propriedade intelectual contida em nosso sistema.
            </Text>
            <Text mb={4}>
              6.3. Os esquemas técnicos, a bases de dados, as metodologias de
              trabalho e outras ferramentas utilizadas ou que venham a ser
              administradas ou desenvolvidas pela VINTE ZERO CINCO, são de sua
              exclusiva propriedade e estão devidamente protegidos nos termos da
              Lei.
            </Text>
            <Text mb={4}>
              6.4. As invenções, descobertas e desenvolvimentos ou
              aprimoramentos de esquemas técnicos e de negócios, listas, dados e
              informações veiculadas no site, e qualquer outra informação a que
              tiver acesso ou desenvolver durante ou em virtude da relação de
              prestação de serviços, serão de única e exclusiva propriedade da
              VINTE ZERO CINCO.
            </Text>
            <Text mb={4}>
              6.5. Os links ou hiperlinks contidos no site da VINTE ZERO CINCO
              não poderão ser transmitidos ou incluídos em nenhum outro site,
              exceto desde que autorizado pela mesma, sendo que qualquer uso ou
              reprodução não autorizada poderá sofrer as consequências legais.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              7 – DA SEGURIDADE DOS ACESSOS E APOSTAS
            </Heading>

            <Text mb={4}>
              7.1. Compete à VINTE ZERO CINCO alterar, modificar, suspender ou
              encerrar o site, jogos ou modalidades, a qualquer hora, sem a
              necessidade de aviso prévio, ou assim que julgar conveniente,
              sobretudo nos casos em que os cadastros, acessos, as informações
              ou o mecanismo do site for utilizada de forma adulterada, for
              preenchida de forma imprecisa, incompleta ou fraudulenta.
            </Text>
            <Text mb={4}>
              7.2. A VINTE ZERO CINCO é detentora dos direitos explícitos no
              site e mecânica de funcionamento, assim como os direitos relativos
              à propriedade intelectual.
            </Text>
            <Text mb={4}>
              7.3. A VINTE ZERO CINCO não será responsável por qualquer
              ociosidade ou período de latência do usuário, inatividade,
              atrasos, falhas de conexão, problemas técnicos do usuário,
              inclusive eventuais perdas experimentadas com o corte ou
              interrupção das apostas por falha do usuário.
            </Text>
            <Text mb={4}>
              7.4. Nos casos de instabilidade ou falhas de sistema, a VINTE ZERO
              CINCO poderá anular todas as apostas, pelo que o valor da aposta
              será estornado ao usuário.
            </Text>
            <Text mb={4}>
              7.5. Nos casos de inatividade do usuário após o período de XXX
              minutos, a VINTE ZERO CINCO poderá desconectar o apostador, sem
              necessidade de prévio aviso.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              8 – DAS VEDAÇÕES
            </Heading>

            <Text mb={4}>
              8.1. É proibido ao usuário ao ter acesso aos dados do site a não
              coletar, fotografar, filmografar, registrar, divulgar, revelar,
              reproduzir, transmitir, modificar, transferir, difundir, utilizar,
              armazenar, para si ou para outrem, dar conhecimento, publicar, em
              hipótese alguma, a terceiros, o material disponível no site.
            </Text>
            <Text mb={4}>
              8.2. Fazer uso de engenharia reversa ou descompilação de qualquer
              software utilizado em conexão com o site da VINTE ZERO CINCO.
            </Text>
            <Text mb={4}>
              8.3. Eventuais falhas nos acessos decorrentes de problemas
              relativos a conexão do usuário, hardware ou software, que,
              inclusive possam afetar o funcionamento do encontro de débitos e
              créditos não poderão ser responsabilidade da VINTE ZERO CINCO.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              9 – REGRAS DO CHAT
            </Heading>

            <Text mb={4}>
              9.1. O usuário entende que os grupos/chats são ferramentas e/ou
              meios de comunicação formais, devendo-se observar as regras de
              ética e moralidade no envio e/ou encaminhamento de mensagens de
              texto, voz ou arquivos.
            </Text>
            <Text mb={4}>
              9.2. O usuário está consciente que os grupos de mensagens que
              utilizem aplicativos multiplataformas são considerados como
              ferramentas de uso oficial da VINTE ZERO CINCO.
            </Text>
            <Text mb={4}>
              9.3. A fim de se prevenir responsabilidades cíveis, criminais ou
              fiscais, o usuário está ciente que os teores das conversas dos
              grupos serão monitorados por intermédio dos moderados ou
              administradores do site, sem que, com isso, se configure infração
              ética por parte da VINTE ZERO CINCO, a fim de manter a ordem.
            </Text>
            <Text mb={4}>
              9.4. Em caso de receio de dano, perecimento de direitos ou
              suspeita de atos ilícitos, a VINTE ZERO CINCO poderá suspender o
              acesso ao site, bloquear o registro do usuário para averiguação, a
              fim de concretizar ou afastar a suspeita, sem que, com isso, se
              configure violão de direitos fundamentais, dignidade, imagem ou
              honra.
            </Text>
            <Text mb={4}>
              9.5. O usuário declara ter assimilado as seguintes regras de
              utilização:
            </Text>
            <Text>
              I. Utilizar comunicação não violenta e adequada, além de utilizar
              os pronomes adequados respeitando os gêneros;
            </Text>
            <Text>
              II. Utilizar comunicação simples, preferencialmente escrita, em
              letras minúsculas, com transparência e objetividade;
            </Text>
            <Text>
              III. Atribuir conduta de acordo com a dinâmica e cultura do site;
            </Text>
            <Text>
              IV. Evitar o uso de “hashtag”, “stickers”, “gif”, “emojis”,
              “emoticons”, figuras, desenhos, vídeos, marcações, siglas e gírias
              utilizadas no ambiente de internet, sempre que possível;
            </Text>
            <Text>
              V. Não utilizar o grupo para discutir assuntos particulares;
            </Text>
            <Text>
              VI. Não utilizar o grupo para envio de correntes religiosas,
              políticas, esportivas ou sociais, enviar fotos ou vídeos
              particulares, assim como pornografia e/ou situações que possam
              comprometer a moralidade e os bons costumes, e incorrer em atitude
              de incontinência de conduta;
            </Text>
            <Text>
              VII. Não utilizar o grupo para promover atividades autônomas ou
              societárias particulares;
            </Text>
            <Text>
              VIII. É proibido o assédio moral e sexual, assim como comentários
              vexatórios, injuriosos ou difamatórios a qualquer participante,
              bem como discriminação por raça, sexo ou cor, preferências ou
              pretensões, assim como contra o site ou entidade governamental ou
              política;
            </Text>
            <Text mb={4}>
              IX. É proibido chamar qualquer outro usuário em conversa privada
              sem o expresso consentimento do terceiro, a fim de que se respeite
              as comunicações dos demais;
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              10 – DA COLETA DE INFORMAÇÕES, DADOS E COOKIES
            </Heading>

            <Text mb={4}>
              10.1. A política de informações, dados e cookies não armazenam
              informações pessoais sem que o usuário as tenha fornecido e não
              coletam informações registradas no seu computador ou celular.
            </Text>
            <Text mb={4}>
              10.2. O próprio usuário poderá usar os recursos do seu navegador
              para bloquear a instalação destes arquivos ou para ser informado
              sobre quando os cookies estão ativados, porém isso pode ocasionar
              a limitação no uso de alguns serviços.
            </Text>
            <Text mb={4}>
              10.3. Para saber mais detalhes sobre a nossa privacidade,
              informação, dados e cookies, sobre como bloqueá-los ou ainda como
              a VINTE ZERO CINCO utiliza suas informações, acesse o site
              www.quizcash.com.br
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              11 – TERMO DE USO DE TERCEIROS
            </Heading>

            <Text mb={4}>
              11.1. Determinadas funções do site da VINTE ZERO CINCO poderão
              conter links ou hiperlink que direcionam para outras empresas ou
              entidades, e estas, por sua vez, poderão conter seus próprios
              termos de uso, condições gerais e serviços, como é o caso da
              Receita Federal e do Sistema Bancário.
            </Text>
            <Text mb={4}>
              11.2. O presente termo e todas as informações fornecidos pela
              VINTE ZERPO CINCO que tratam de informações, dados e privacidade
              referem-se exclusivamente ao site da VINTE ZERO CINCO, de modo que
              esta não pode ser responsabilizada pelos termos ou condições de
              terceiro ou de outras formas de divulgação e acesso.
            </Text>
            <Text mb={4}>
              11.3. Em caso de conflito com estes termos, valerão os termos de
              uso dos parceiros ou terceiros e contra esses a reclamação, dúvida
              ou sugestão deverá ser intentada.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              12 – DA PRIVACIDADE INTERINA NO USO DO SITE
            </Heading>

            <Text mb={4}>
              12.1. Concordando com o termo e suas condições, o usuário assume
              que: a) a utilização do portal e, eventualmente, do aplicativo, e
              as apostas efetuadas são restritos a seu uso pessoal; b) somente
              ele está autorizado a utilizar a sua conta conforme estabelecido
              no presente termo; c) Não irá transmitir qualquer conteúdo, por
              meio do portal ou aplicativo, que seja suscetível a causar
              prejuízo ou dano, em qualquer grau, aos sistemas de tecnologia da
              VINTE ZERO CINCO; d) Declara conhecer que perdas e danos, neles
              incluídos custos ou despesas, direta ou indiretamente, causadas à
              VINTE ZERO CINCO, acarretará possíveis sanções judiciais.
            </Text>
            <Text mb={4}>
              12.2. A VINTE ZERO CINCO resguarda os dados e processamento de
              seus jogos e cadastros em relação à Segurança da Informação e em
              conformidade com as normas vigentes no Brasil.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              13 – ATENDIMENTO AO USUÁRIO
            </Heading>

            <Text mb={4}>
              13.1 O atendimento ao usuário pode ser realizado com o nosso
              Encarregado de Dados para quaisquer esclarecimentos e informações,
              referente aos dados: <br />
              NOME DO DPO: EDUARDO PASINATTO <br />
              E-MAIL: adm@quizcash.com.br <br />
              TELEFONES: (49) 99901-9267.
            </Text>
            <Text mb={4}>
              13.2 O atendimento ao usuário de dúvidas gerais pode ser realizado
              por meio do contato abaixo, no serviço de atendimento ao
              consumidor: <br />
              NOME: EDUARDO PASINATTO <br />
              E-MAIL: adm@quizcash.com.br
            </Text>
            <Text mb={4}>
              13.3. Em caso de dúvidas ou contestações relacionadas às
              transações realizadas poderá ser promovida por meio de processo
              administrativo de atendimento ao usuário. A análise, que será
              adotada de todos os esforços no sentido de agilidade, das
              transações questionadas e contestadas será sentenciada com o
              julgamento final da VINTE ZERO CINCO, sendo incabível qualquer
              recurso.
            </Text>

            <Heading
              as="h2"
              textAlign={"center"}
              mt={6}
              mb={4}
              fontWeight={"bold"}
            >
              14 - DA LEGISLAÇÃO APLICÁVEL
            </Heading>

            <Text mb={4}>
              14.1. Todas as questões originadas deste contrato serão resolvidas
              pela aplicação da legislação em vigor.
            </Text>
            <Text mb={4}>
              14.2. Em caso de qualquer conflito existente entre o termo e as
              normativas vigentes sempre, prevalecerá o entendimento contido no
              presente, sem prejuízo de responsabilização individual pela
              conduta contrária às regras existentes, bem como a legislação
              extraordinária em matéria cível e penal.
            </Text>
            <Text mb={4}>
              14.3 Caso qualquer das disposições do presente instrumento sejam
              declaradas inválidas ou ilegais, todas as disposições
              remanescentes permanecerão em plena vigência e aplicação, devendo
              as partes, antes, buscarem disposições que substituam a previsão
              considerada inválida ou ilegal, prevalecendo, neste ato, a boa-fé
              entre elas.
            </Text>
            <Text mb={4}>
              14.4. Este termo pode ser impresso pelo usuário quando devidamente
              comprovado sua justa causa, por meio dos contatos disponíveis, e
              poderá ser utilizado em processos extrajudiciais ou judiciais.
            </Text>
            <Text mb={4}>
              14.5. Nenhuma das partes poderá ofertar, exigir ou comprometer a
              outrem a qualquer pagamento, doação, compensação, vantagens ou
              benefícios, pecuniários ou não, que possam constituir condutas não
              recomendadas de boas práticas ou que possam ser aduzidas como
              corrupção de qualquer forma relacionada a este contrato, a todas
              as pessoas que dele dependam.
            </Text>
            <Text mb={4}>
              14.6. Este termo continuará a ser revisto de acordo com a dinâmica
              dos processos existentes, com notícias agregadas e ajustadas de
              acordo com os requisitos legais, e tem sempre como objetivo a
              proteção da privacidade dos titulares dos dados e a garantia do
              uso legal destas informações
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={"green"}
              spinnerPlacement="start"
              onClick={handleSubmit}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Register;
