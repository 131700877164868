import React, { useEffect, useState } from "react";
import { ChakraBaseProvider, extendBaseTheme, Box } from "@chakra-ui/react";
import chakraTheme from "@chakra-ui/theme";

import Router from "./routes";
import ContextProvider from "./contexts";
import { Toaster } from "react-hot-toast";
import GlobalStyles from "./constants/GlobalStyles";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { AuthProvider } from "./contexts/auth";


const { Button, Modal } = chakraTheme.components;

const theme = extendBaseTheme({
  components: {
    Button,
    Modal,
  },
});

const App: React.FC = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // retorna uma função de limpeza para remover o listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <AuthProvider>
      <ChakraBaseProvider theme={theme}>
        <Box
          height={windowSize.height}
          // maxH={"100%"}
          m="auto"
          // p="0.5"
          // maxW={"768px"}
          backgroundColor={"var(--background-gray)"}
        >
          <Router />
        </Box>
      </ChakraBaseProvider>
      <Toaster />
      <GlobalStyles />
    </AuthProvider>
  );
};

export default App;
