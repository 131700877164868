import Toast from "../components/Toast";
import provider from "./config";

interface ILogin {
  username: string;
  password: string;
}

interface IUserGroupPermissao {
  id: string;
  nome: string;
}

interface IClassePermissao {
  id: string;
  nome: string;
}

interface IPermission {
  id: number;
  classe: IClassePermissao;
  funcao: string;
  userGroup: IUserGroupPermissao;
  inactive: boolean;
  criadoEm: string;
  atualizadoEm: string;
}

interface IUserGroup {
  id: number;
  name: string;
  Permissoes: Array<IPermission>;
}

export interface IEntidade {
  id: string | null;
  codigo: string | null;
  situacao: string | null;
  email: string;
  nome: string;
  foto: string | null;
  telefone: string | null;
  celular: string | null;
  cpf: string | null;
  cnpj: string | null;
  cpfCnpj: string | null;
  dataNascimento: string | null;
  genero: string | null;
  banco: string | null;
  agencia: string | null;
  conta: string | null;
  codigoFebraban: string | null;
  limiteCredito: string | null;
  bloqueadoEm: string | null;
  negativado: string | null;
  referenciaComercial: string | null;
  endereco: string | null;
  numeroEndereco: string | null;
  bairro: string | null;
  complemento: string | null;
  UF: any | null;
  Cidade: any | null;
  cep: string;
  inativo: boolean;
  criadoEm: string;
  atualizadoEm: string;
  idBloqueador: string;
  idLogin: string | null;
  onesignalId: string | null;
  dUserGroupId: string;
  DUserGroup: IUserGroup;
  Login: any;
  Estab: any;
  LocEscrit: ILocEscrit;
  devariPayCustomerId: string;
  token?: string;
  satp?: string;
  zCodigoTelegram?: string;
  zRecebeNotificacaoTelegram: boolean;
  zRecebeNotificacaoEmail: boolean;
  zBinanceApiActivated: boolean;
  binanceAPI?: boolean;
}

interface ILoginResponse {
  token: string;
  entidade: IEntidade;
}

interface ILocEscrit {
  id: string;
  nome: string;
}

export interface ICountie {
  id: string;
  nome: string;
}

export interface IState {
  id: string;
  nome: string;
}

export const login = async ({
  username,
  password,
}: ILogin): Promise<IEntidade> => {
  try {
    const payload = {
      usuario: username,
      senha: password,
    };

    const response = await provider.post("authentication/", payload);

    const userResponse: ILoginResponse = response.data;

    const user: IEntidade = {
      ...userResponse.entidade,
      token: userResponse.token,
    };
    return user;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message);
      throw Error(err.response.data.message);
    } else {
      throw Error(
        "As credenciais utilizadas são inválidas, verifique-as novamente."
      );
    }
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const response = await provider.post("entidades/recuperar-senha", {
      email: email.toLowerCase().trim(),
    });

    return response;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message);
      throw Error(err.response.data.message);
    } else {
      Toast.show(err.response.data.message);
      throw Error("Erro ao enviar endereço de email.");
    }
  }
};

export const validateToken = async (token: any) => {
  try {
    const response = await provider.get(
      "entidades/recuperar-senha/validar-token",
      { params: { token: token } }
    );

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
      throw Error(err.response.data.message);
    } else {
      Toast.show(err.response.data.message, "error");
      throw Error("Erro ao validar token");
    }
  }
};

export const resetPassword = async (payload: any) => {
  try {
    const response = await provider.post(
      "entidades/recuperar-senha/redefinir",
      {
        senha: payload.senha,
        token: payload.token,
      }
    );

    return response;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message);
      throw Error(err.response.data.message);
    } else {
      Toast.show(err.response.data.message);
      throw Error("Erro ao redefinir senha.");
    }
  }
};