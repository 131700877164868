import styled from "styled-components";
import { Link as _Link } from "react-router-dom";
import Background from "../../assets/fundo.png";

export const Container = styled.div`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Content = styled.div`
  position: relative;
  background: linear-gradient(230deg, #22da0d, #51aa3d) ;
  border-width: 4px;
  border-style: solid;
  border-color: #7BFF38;
  border-radius: 10px;
  width: 100% !important;

  & > div > img {
    margin-top: -30px
  }

  & > div > label {
    color: var(--white);
    font-size: 1.4rem;
    font-weight: bold;
  }

  & > div > input:nth-of-type(1){
    margin-top: 14px;
  }

  .token {
    margin-bottom: 30px;
    text-align: center;
  }

  .token > span {
    color: var(--white);
    font-size: 30px;
  }
`;

export const Link = styled(_Link)`
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
`;
