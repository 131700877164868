enum Bases {
  LOCAL = "local",
  HOMOLOG = "homolog",
  PROD = "prod",
}

let BASE: Bases = Bases.HOMOLOG;

const currentDomain = window.location.href;

if (currentDomain.includes("devaribox.co")) {
  BASE = Bases.HOMOLOG;
} else if (currentDomain.includes("quizcash.com.br")) {
  BASE = Bases.PROD;
} else {
  BASE = Bases.HOMOLOG;
}

let CURRENT_STORE = "quiz";

if (currentDomain.includes("quiz")) {
  CURRENT_STORE = "quiz";
}

export { BASE, CURRENT_STORE };
