import styled from 'styled-components'


export const DivTop = styled.div`
  background: var(--black-lighter-300);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 60px;
  padding: 0 10px 0 10px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  
  #checkbox-menu{
    opacity: 0;
    height: 0;
    transition: height .8s;
    transform: translateY(250px);
  }

  #checkbox-menu:checked + label span:nth-child(1){
    transform: rotate(-45deg);
    top: 8px;
  }

  #checkbox-menu:checked + label span:nth-child(2){
    opacity: 0;
  }

  #checkbox-menu:checked + label span:nth-child(3){
    transform: rotate(45deg);
    top:8px;
  }

  #checkbox-menu~.item-menu {
    @media(max-width: 950px){
      display: none;
    }
  }
  
  #checkbox-menu:checked~.item-menu {
    display: block;
  }

  #checkbox-menu:checked~div {
    @media(max-width: 414px){
      display: flex;
    }
  }

  #checkbox-menu:checked~#home {
    @media(min-width: 950px){
      display: none;
    }

    @media(max-width: 414px){
      display: block;
    }
  }

  @media (max-width: 951px) {
    & > div {
      order: -1;
    }
  }
`;

export const Profile = styled.div`
  /* background: var(--white); */
  /* border-radius: 6px; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  display: flex;
  align-items: flex-end;
  /* padding: 10px; */
  /* height: 60px; */
  width: 100%;

  img {
    /* background: var(--white);
    border-radius: 50%;
    border: 1px solid var(--white);
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2); */
    margin-right: 0;
    height: 45px;
    width: 160px;

    @media (max-width: 656px) {
      height: 41px;
      width: 140px;
    }
  }

  & > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 80px; */
    width: 40%;
    min-width: 420px;


    .bold {
      background: var(--green-lighter400);
      color: var(--black);
      font-size: 16px;
      font-weight: 600;
      /* margin-right: 20%; */
      padding: 2px 12px;
      height: min-content;
      white-space: nowrap;
      min-width: 60px;
      text-align: center;
    }

    @media (max-width: 950px) {
     width: 50%;
    }

    @media (max-width: 830px) {
      min-width: 390px;
    }
    @media (max-width: 770px) {
      min-width: 360px;
    }
    @media (max-width: 710px) {
      min-width: max-content;
      .bold {
        font-size: 14px;
      }
    }
    @media (max-width: 640px) {
      .bold{
        margin-right: 10px;
        padding: 4px 8px;
      }
    }
    @media (max-width: 600px) {
      .bold{
        font-size: 12px;
        padding: 4px 6px;
      }
    }

    @media (max-width: 550px) {
      .logo > img {
        height: 34px;
        width: 90px;
      }
    }
    @media (max-width: 490px) {
      .logo > button {
        margin-right: 0;
        padding: 4px 0;
      }
      .logo img {
        height: 28px;
        width: 80px;
      }
      .bold {
        font-size: 10px;
        padding: 4px;
        margin-right: 4px;
      }
    }
    @media (max-width: 410px) {
      .logo > img {
        height: 30px;
        width: 70px;
      }
    }

    @media (max-width: 390px) {
      .logo {
        margin-right: 4px;
      }
      .logo > button {
        height: 24px;
      }
      .bold {
        font-size: 8px;
        margin-right: 4px;
        padding: 2px;
      }
    }
  }

  & > div:nth-of-type(2){
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    min-width: 420px;

    @media (max-width: 950px) {
     width: 50%;
    }

    @media (max-width: 830px) {
      min-width: 390px;
    }
    @media (max-width: 770px) {
      min-width: 360px;
      button:not(.bg-login) {
        margin-right: 0;
      }
    }
    @media (max-width: 710px) {
      min-width: max-content;
      button {
        font-size: 14px;
      }
    }
    @media (max-width: 640px) {
      button{
        padding: 4px 6px;
      }
    }
    @media (max-width: 600px) {
      button{
        font-size: 12px;
        margin-bottom: 4px;
        padding: 4px;
      }
    }
    @media (max-width: 490px) {
      button{
        font-size: 10px;
      }
    }
    @media (max-width: 390px) {
      button {
        font-size: 8px;
        margin-bottom: 0;
        padding: 2px 4px;
      }
      .bg-login {
        height: 24px;
      }
    }
  }

  .logo{
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 900;
    line-height: 27px;
    width: max-content;

    button {
      margin-bottom: 0;
    }

    span{
      color: var(--alert);
    }
  }
  
  div > div {
    /* border-left: 1px solid var(--white); */
    color: var(--white);
    display: flex;
    /* padding: 6px; */
    text-align: left;
    width: min-content;

    &>p:nth-of-type(1){
      margin-right: 20px;
    }

    &>p{
      cursor: pointer;
      height: 45px;
    }

    .live {
      border-bottom: 4px solid var(--white);
    }

    @media (max-width: 414px){
      & > p {
        font-size: 12px;
      }

    }
  }

  button {
    color: var(--white);
    font-weight: 600;
    height: 32px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 4px 14px;
    transition: ease-in-out;
    transition-duration: 0.3s;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    
    &.bg-login{
      border: 1px solid var(--white);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      font-weight: 700;
    }
    
    &:hover {
      transform: scale3d(1.1, 1.1, 1.1);
    }

    @media (max-width: 830px) {
     padding: 4px 8px;
    }
  }
  
  @media (max-width: 546px) {
    & > div {
      justify-content: space-between;

      & > div {
        width: auto;
      }
    }
    img {
      height: 33px;
      width: 100px;
    }
    & > div:nth-of-type(1) {
      width: 70%;
    }
    & > div:nth-of-type(2) {
      width: 30%;
    }
  }

  @media (max-width: 487px) {
    & > div > div > .bold {
      font-size: 14px;
      height: 40px;
    }

    & > div > div:nth-of-type(1) {
      margin-right: 0;
    }

    button {
      font-size: 12px;
    }
  }

  @media (max-width: 426px) {
    button {
      padding: 4px 8px;
    }

    button:nth-of-type(2){
      margin-right: 0;
    }
    /* .logo > img {
      margin-bottom: 10px;
    } */
  }
`;

export const Select = styled.select`
  border: 1px solid gray;
  border-radius: 8px;
  padding: 4px 10px;
  width: 90%;

  &:disabled {
    font-size: 12px;
    opacity: .6;
  }
`