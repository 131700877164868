import axios from "axios";
const currentUrl = window.location.href;

export let chatUrl = "";

if (currentUrl.includes(".com")) {
  chatUrl = "https://api.quizcash.com.br";
} else if (currentUrl.includes("devaribox.co")) {
  chatUrl = "https://quiz-api.devaribox.co/";
} else if (currentUrl.includes(":300")) {
  //change this if needed for local development
  chatUrl = "https://quiz-api.devaribox.co";
  // chatUrl = "https://api.quizcash.com.br";
  // chatUrl = "http://192.168.1.9:3010";
  // chatUrl = "http://localhost:3010";
}

const chatProvider = axios.create({
  baseURL: `${chatUrl}`,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export async function getChats(id: string) {
  try {
    const resp = await chatProvider.get("chats/", {
      params: {
        query: id,
      },
    });

    return resp.data;
  } catch (err: any) {
    throw Error(err);
  }
}

export async function getChat(roomId: string) {
  try {
    const response = await chatProvider.get("chat-room/", {
      params: {
        query: roomId,
      },
    });

    const chats = response.data.chatList;

    const chat = chats.filter((responseChat: any) => {
      if (responseChat.room_id === `${roomId}`) {
        return responseChat;
      }
    })[0];

    if (chat) {
      return chat;
    }

    throw Error("No chat");
  } catch (err: any) {
    throw Error(err);
  }
}

export async function getChatHistory(room: any, timestamp = null) {
  try {
    const response = await chatProvider.get("/chat-history/", {
      params: {
        query: room,
        date: timestamp,
      },
    });

    return response.data.sortedList;
  } catch (err: any) {
    throw Error(err);
  }
}
