import {
  HashRouter,
  createBrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { useAuth } from "./contexts/auth";
import { BASE, CURRENT_STORE } from "./environments";
import Home from "./pages/home";
import Login from "./pages/login";
import Register from "./pages/Register";
import Room from "./pages/room";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RetrievePassword from "./pages/retrievePassword";
import ResetPassword from "./pages/resetPassword";

const Router = () => {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={
            // <PrivateRoute redirectTo="/">
              <Home />
            // </PrivateRoute>
          }
        />
        <Route
          path="/room"
          element={
            // <PrivateRoute redirectTo="/">
              <Room />
            // </PrivateRoute>
          }
        />
        <Route path="/política-privacidade" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cadastro" element={<Register />} />
        <Route path="/recuperar-senha" element={<RetrievePassword />} />
        <Route path="/redefinir-senha" element={<ResetPassword />} />
        {/* <RouterProvider router={user ? privateRouter : openRouter} /> */}
      </Routes>
    </HashRouter>
  );
};

const PrivateRoute = ({ children, redirectTo }: any) => {
  const isAuthenticated =
    localStorage.getItem(`@${CURRENT_STORE}${BASE}:user`) !== null;

  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

export default Router;
