import Provider from "./config";
import Toast from "../components/Toast";

export const getUser = async (id: any) => {
  try {
    const response = await Provider.get(`entidades/${id}`);

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
    } else {
      throw Error("Erro ao buscar usuário");
    }
  }
};

export const getBalance = async (id: any) => {
  try {
    const response = await Provider.get("entidades/saldo/", {
      params: {
        idUser: id
      }
    });

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
    } else {
      throw Error("Erro ao buscar saldo do usuário");
    }
    
  }
}

export const getBalanceRoom = async (id: any) => {
  try {
    const response = await Provider.get("entidades/saldo-sala/", {
      params: {
        idLocEscritu: id
      }
    });

    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
    } else {
      throw Error("Erro ao buscar saldo da sala");
    }
    
  }
}


export const editUser = async (id: any, payload: object) => {
  try {
    const response = await Provider.put(`entidades/${id}`, payload);

    return response.data
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      Toast.show(err.response.data.message, "error");
    } else {
      throw Error("Erro ao editar usuário");
    }
  }
}