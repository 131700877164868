import styled from "styled-components";

export const Container = styled.div`
	background: var(--background-gray);
	padding: 80px 0 100px 0;
`;

export const Title = styled.p`
	color: var(--green-lighter400);
	font-size: 2rem;
	font-weight: bold;
	line-height: 2.2rem;
	width: 250px;
`;

export const Subtitle = styled.p`
	color: var(--white);
	font-size: .9rem;
	margin-top: 14px;
	width: 350px;

	@media (max-width: 480px) {
		width: 94%;
	}
`;

export const Row = styled.div`
	display: flex;
	justify-content: center;

	@media (max-width: 730px) {
		flex-direction: column;
		align-items: center;

		& > div:nth-of-type(1){
			order: 1;
			margin-bottom: 30px;
			width: 60%;
		}
		
		& > div:nth-of-type(2){
			order: 2;
			width: 60% !important;
		}
	}

	@media (max-width: 650px) {
		& > div:nth-of-type(1) {
			width: 70%;
		}
		& > div:nth-of-type(2) {
			width: 70% !important;
		}
	}
	@media (max-width: 480px) {
		& > div:nth-of-type(1) {
			width: 90%;
		}
		& > div:nth-of-type(2) {
			width: 90% !important;
		}
	}
`;

export const Collumn = styled.div`
	display: flex;
	flex-direction: column;
	width: 40%;

	& .socials {
		display: flex;
		align-items: center;
		margin-top: 10px;
	}

	@media (max-width: 875px) {
		width: 48%;
	}
`;

export const ButtonReadMore = styled.button`
	background: var(--green-lighter400);
	border: none;
	border-radius: 10px;
	color: var(--black);
	font-size: 1.2rem;
	font-weight: 700;
	margin-top: 40px;
	padding: 4px 40px;
	width: max-content;
`;