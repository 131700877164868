import { MutableRefObject } from "react";
import styled from "styled-components";

interface ICardStyleProps {
  listRef: MutableRefObject<HTMLDivElement | null> | null;
  key: number;
}

export const TitleRoom = styled.p`
  color: var(--white);
  font-size: 22px;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 10px;
`;

export const Container = styled.div<ICardStyleProps>`
  background:  #2c2c2c;//#0d4337;
  margin-bottom: 20px;
  padding: 10px;
  margin-inline: auto;
  scroll-behavior: smooth;
  width: 94%;
  
  & > .div-scrollbar.scrollbar-visivel::-webkit-scrollbar {
    display: block;
    height: 4px;
  }
`

export const DivRow = styled.div`
  display: flex;
  width: 100%;

  & > div {
    width: 100%;
  }

  & .buttonArrow {
    background: #082620;
    color: var(--green-lighter400);
    margin-top: auto;
    margin-bottom: auto;
    margin-inline: 4px;
    height: 50px;
  }

  /* &::-webkit-scrollbar {
    height: 0;
  }

  &.div-scrollbar.scrollbar-visivel::-webkit-scrollbar {
    display: block;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--form-background);
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--green-lighter300);
    outline: 1px solid var(--green-lighter200);
  } */
  @media (max-width: 390px) {
    margin-right: 0 !important;
   & > div {
     max-width: 280px;
     min-width: 280px;
   }
  }
`;

export const CardStyle = styled.div`
  background: #464646;//#0b392f;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* cursor: pointer; */
  min-height: 80px;
  min-width: 300px;
  margin-bottom: 6px;
  padding: 10px 0;
  transition: 0.3s;

  div:not(.inscription){
    margin-inline: 18px;
  }
  
  h2 {
    color: var(--white);
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    width: 90%;

    span{
      background-color: var(--green-lighter300);
      border-radius: 50%;
      margin-right: 8px;
      height: 6px;
      width: 6px;
    }

    .money {
      color: var(--alert);
      display: contents;
      font-size: 1rem;
      font-weight: 800;
      margin-right: 10px;
      margin-left: 10px;

      & > svg {
        margin-right: -10px;
      }
    }
  }
  
  div {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-right: 10px;
    width: 50%;
    min-width: max-content;

    @media (max-width: 425px) {
      width: max-content;
    }
  }

  div:nth-of-type(2) {
    position: relative;
  }

  div:nth-last-of-type(2) > p::before{
    content: "";
    border: 1px solid var(--disabled);
    border-radius: 50%;
    margin-right: 6px;
    height: 6px;
    width: 6px;
  }

  div:nth-last-of-type(2) > p::after{
    content: "";
    position: absolute;
    top: 15px;
    left: 2.5px;
    height: 17px;
    width: 1px;
    background-color: var(--disabled);
  }

  p {
    color: var(--disabled);
    display: flex;
    align-items: center;
    font-size: .9rem;
    white-space: nowrap;
  }

  &.inactive {
    cursor: no-drop;
  }

  .alert {
    color: var(--alert);
  }

  .open {
    color: var(--green-lighter300);
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 800;
  }

  .closed {
    color: var(--warning) !important;
    font-size: 1rem;
    font-weight: 800;
  }

  .inscription {
    border-top: 2px solid #166b59;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    height: 40px;
    width: 100%;

    & button {

    }
  }

  .money {
    color: var(--green-lighter300);
    display: flex;
    font-size: 1rem;
    font-weight: 800;
    margin-right: 10px;

    & > svg {
      margin-right: -10px;
    }
  }

  .openCard {
    /* border: 1px solid var(--disabled); */
    color: var(--green-lighter300);
    font-size: 1rem;
    font-weight: 600;
    width: 120px;
    text-align: center;
  }
  
  .alertCard {
    /* border: 1px solid var(--disabled); */
    color: var(--alert);
    font-size: 1rem;
    font-weight: 600;
    width: 120px;
    text-align: center;
  }
`;