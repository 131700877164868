import React from "react";
import { Container, Numbers } from "./styles"

const SectionNumbers = () => {

	return (
		<Container>
			<Numbers>
				<p>+1000</p>
				<p>Prêmios recebidos</p>
			</Numbers>
			<Numbers>
				<p>+1000</p>
				<p>Cadastros</p>
			</Numbers>
			<Numbers>
				<p>+500</p>
				<p>Salas criadas</p>
			</Numbers>
			<Numbers>
				<p>+10</p>
				<p>Categorias</p>
			</Numbers>
		</Container>
	)
}

export default SectionNumbers;