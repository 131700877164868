import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Aside,
  Container,
  Content,
  DivBottom,
  DivColumn,
  DivContainer,
  DivContainerSidebar,
  DivRow,
  LabelMenu,
  Loading,
  Offer,
  SearchContainer,
  SearchIcon,
  SearchInput,
  Sidebar,
  SlideShow,
  TextAside,
  TitleRoom,
  TitleSidebar,
} from "./styles";
import { useAuth } from "../../contexts/auth";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { formatMoney } from "../../utils/format";
import { BASE, CURRENT_STORE } from "../../environments";
import Toast from "../../components/Toast";
import { getMenu, postRoom } from "../../services/room";
import ImageQuizz from "../../assets/banner_quizz_cash_1.webp";
import Slider from "react-slick";
import { IoColorPalette } from "react-icons/io5";
import { FaFilm, FaBalanceScaleLeft, FaFootballBall } from "react-icons/fa";
import { FiX, FiSearch } from "react-icons/fi";
import { MdClearAll, MdPool } from "react-icons/md";
import { HiOutlineMusicNote } from "react-icons/hi";
import { BsGlobeAmericas } from "react-icons/bs";
import {
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosBaseball,
} from "react-icons/io";
import {
  GiBookCover,
  GiBookshelf,
  GiScrollUnfurled,
  GiNewspaper,
  GiHighHeel,
  GiVolleyballBall,
  GiGolfFlag,
  GiChefToque,
  GiBoxingGloveSurprise,
} from "react-icons/gi";
import { RiStethoscopeFill } from "react-icons/ri";
import Image from "../../assets/image-banner.png";
import { format, parseISO } from "date-fns";
import { isBefore } from "date-fns/fp";
import { ProfileMenu } from "../../components/ProfileMenu";
import { editUser, getBalance } from "../../services/user";
import {
  ID_CLASSE_SALA_AO_VIVO,
  ID_CLASSE_SALA_AUTOMATICA,
  ONESIGNAL_APP_ID,
} from "../../utils/classes";
import { Header } from "../../components/Header";
import Card from "../../components/Card";
import SvgComponent from "../../components/SvgComponent";
import IconFutebol from "../../assets/icons/futebol.png";
import IconBasebol from "../../assets/icons/basebol.png";
import IconBasquete from "../../assets/icons/basquete.png";
import IconModa from "../../assets/icons/moda.png";
import OneSignal from "react-onesignal";
import Footer from "../../components/Footer";
import CookieConsent from "react-cookie-consent";
import SectionNumbers from "../../components/SectionNumbers";
import SectionInvite from "../../components/SectionInvite";
import SectionGalery from "../../components/SectionGallery";
import ImageQuizz1 from "../../assets/banner_1920x660px_001.webp";
import ImageQuizz3 from "../../assets/banner_1920x660px_003.webp";

export interface ICard {
  id: string;
  room?: string;
  dateStart?: string;
  hour?: string;
  status?: boolean;
  qtdParticipants?: Number;
  value?: string | Number;
  registered?: boolean;
  type?: string;
  content?: string;
  time?: number;
  dataInicio?: string;
  idModalidadeSala?: string;
  modalidade?: string;
  nome?: string;
  valorIngresso?: string;
  idClasse?: string;
}

export interface IParams {
  param: ICard;
}

export interface IGroupRoom {
  nomeClasse: string;
  item: IRoom;
  modality?: IModality;
}

export interface IRoom {
  dataInicio: string;
  id?: string;
  idModalidadeSala: string;
  modalidade?: string;
  idClasse?: string;
  nome: string;
  valorIngresso: number;
  numeroParticipantesSala: number;
  senha?: string;
  senhaSala?: string;
  SalaEvento?: IEventRoom[];
}

export interface IEventRoom {
  id?: string;
  idPessoa?: string;
  idClasse?: string;
}

export interface IModality {
  Class: IClass;
  atualizadoEm: string;
  criadoEm: string;
  id: string;
  idClasse: string;
  nome: string;
  foto?: string;
  page?: number;
  totalPage?: number;
}

export interface IRequestRoom {
  currentPage: number;
  docs: IRoom[];
  total: number;
  totalPages: number;
}

interface IClass {
  id: string;
  nome: string;
}

export interface IBalance {
  saldoAposta: number;
  saldoSaque: number;
}

export interface IBalanceRoom {
  saldo: number;
}

declare global {
  interface Window {
    OneSignal: any;
  }
}

const Home: React.FC = () => {
  const { user, updateUser } = useAuth();
  const navigate = useNavigate();
  const sobreRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const dateNow = new Date();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const ulRef = useRef<HTMLUListElement>(null);
  const divPaiRefs = useRef<Array<HTMLDivElement | null>>([]);
  const { onClose: onRoomClose } = useDisclosure();
  const {
    isOpen: isNotificationOpen,
    onOpen: onNotificationOpen,
    onClose: onNotificationClose,
  } = useDisclosure();
  const [itemActive, setItemActive] = useState<string>("todos");
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const [live, setLive] = useState<boolean>(false);
  const [roomFuture, setRoomFuture] = useState<Array<IGroupRoom[]>>([]);
  const [roomClosed, setRoomClosed] = useState<Array<IGroupRoom[]>>([]);
  const [roomFutureLive, setRoomFutureLive] = useState<Array<IGroupRoom[]>>([]);
  const [roomClosedLive, setRoomClosedLive] = useState<Array<IGroupRoom[]>>([]);
  const [myRoom, setMyRoom] = useState<Array<IGroupRoom>>([]);
  const [modality, setModality] = useState<Array<IModality>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingRoom, setLoadingRoom] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState("tab1");
  const [scrollbarVisivel, setScrollbarVisivel] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [payload, setPayload] = useState<IRoom>({
    dataInicio: "",
    idModalidadeSala: "",
    nome: "",
    valorIngresso: 0,
    idClasse: "64381977694bc59bbe9a6ec9",
    numeroParticipantesSala: 0,
    SalaEvento: [],
  });

  const localStorageCookie = localStorage.getItem("cookie");
  let cookie =
    localStorageCookie !== null ? JSON.parse(localStorageCookie) : [new Date()];

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const verificarScrollbar = () => {
      const lista = ulRef.current;
      const alturaLista = lista?.getBoundingClientRect().height;
      const alturaItens = lista?.scrollHeight;
      const larguraTela = window.innerWidth;

      if (larguraTela > 950 && alturaItens && alturaItens > alturaLista!) {
        setScrollbarVisivel(true);
      } else {
        setScrollbarVisivel(false);
      }
    };

    window.addEventListener("resize", verificarScrollbar);
    verificarScrollbar();

    window.addEventListener("resize", handleResize);

    // retorna uma função de limpeza para remover o listener
    return () => {
      window.removeEventListener("resize", verificarScrollbar);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const ulElement = ulRef.current;
    const containerElement = ulElement?.parentElement;

    const checkScrollable = () => {
      const isScrollable =
        ulElement?.scrollWidth &&
        containerElement?.clientWidth &&
        ulElement.scrollWidth > containerElement.clientWidth;
      setShowLeftArrow(!!isScrollable);
      setShowRightArrow(!!isScrollable);
    };

    const handleResize = () => {
      if (window.innerWidth <= 950) {
        checkScrollable();
      }
    };

    window.addEventListener("resize", handleResize);
    if (window.innerWidth <= 950) {
      checkScrollable();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [modality]);

  useEffect(() => {
    if (!localStorage.getItem(`@${CURRENT_STORE}${BASE}:user`)) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (loading) {
      setSelectedTab("tab1");
    }
  }, [loading]);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        await getRoom(user.id);
      } else {
        await getRoom();
      }
    };

    fetchData();
  }, [user]);

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;

  useEffect(() => {
    if (user) {
      if (OneSignal.length > 0 || Object.keys(OneSignal).length > 0) {
        OneSignal.getUserId()
          .then((userId: any) => {
            if (user.onesignalId === null || user.onesignalId !== userId) {
              if (userId) submitUpdateUser(userId);
            }
          })
          .catch((e: any) => console.error("Erro: ", e));
      } else {
        onNotificationOpen();
      }
    }
  }, [user]);

  // Atualizar usuário com o oneSignalId
  const submitUpdateUser = async (id: string) => {
    try {
      const result = await editUser(user?.id, { onesignalId: id });

      if (result) {
        updateUser(result);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const updateLocalStorage = () => {
    localStorage.setItem("cookie", JSON.stringify(cookie));
  };

  const getRoom = async (id?: any) => {
    try {
      setLoading(true);
      const resultMenu = await getMenu(dateNow);

      if (resultMenu) {
        const newResult = resultMenu.map((item: any) => {
          return {
            page: 1,
            ...item,
          };
        });

        setModality(newResult);
      }
      setLoading(false);
    } catch (error) {
      console.error("Erro: ", error);
      setLoading(false);
    }
  };

  function totalPageGroup(item: IGroupRoom[]) {
    console.log(item);
  }

  // Criar sala
  const handleSubmit = async () => {
    if (validate()) {
      setLoadingRoom(true);

      try {
        delete payload.SalaEvento;
        const result = await postRoom(user?.id, payload);
        if (result?.status === 201) {
          onRoomClose();
          setLoadingRoom(false);

          const _roomFuture = roomFuture;
          const _myRoom = myRoom;
          let future: IGroupRoom[] = [];

          modality.map((itemMenu: IModality) => {
            if (itemMenu.id === result.data.idModalidadeSala) {
              future.push({
                nomeClasse: itemMenu.nome,
                item: result.data,
                modality: itemMenu,
              });
            }
          });

          // const newRoomFuture = groupNewItems(_roomFuture, future);
          // const newMyRoomFuture = groupNewItems(_myRoom, future);

          // setRoomFuture(newRoomFuture);
          setMyRoom(future);
          Toast.show("Sala criada com sucesso!", "success");
        }
      } catch (error) {
        setLoadingRoom(false);
        onRoomClose();
        Toast.show(`${error}`, "error");
      }
    }
  };

  // Validação dos campos para criar sala
  const validate = () => {
    if (!payload.nome) {
      Toast.show("O campo nome precisa ser preenchido", "error");
    }
    if (!payload.idModalidadeSala) {
      Toast.show("O campo modalidade precisa ser preenchido", "error");
      return false;
    }
    if (payload.dataInicio === "") {
      Toast.show("O campo data de início precisa ser preenchido", "error");
      return false;
    } else if (isBefore(dateNow, parseISO(payload.dataInicio))) {
      Toast.show("Digite uma data válida", "error");
    }
    if (payload.numeroParticipantesSala === 0) {
      Toast.show(
        "O campo quantidade de participantes precisa ser preenchido",
        "error"
      );
      return false;
    } else if (payload.numeroParticipantesSala < 0) {
      Toast.show("Digite uma quantidade de participantes válida", "error");
      return false;
    }
    if (payload.valorIngresso === 0) {
      Toast.show("O campo valor precisa ser preenchido", "error");
      return false;
    } else if (payload.valorIngresso > 0 && payload.valorIngresso < 5) {
      Toast.show("O valor mínimo da sala deve ser R$ 5,00");
      return false;
    }
    return true;
  };

  // Configuração do slideshow
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    pauseOnHover: true,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const refresh = () => {
    // setState({
    //   items: [...state.items, "new item"],
    // });
  };

  // Selectionar icone da modalidate
  const iconSelected = (name: string) => {
    if (
      name.toLocaleLowerCase() === "esportes" ||
      name.toLocaleLowerCase() === "futsal" ||
      name.toLocaleLowerCase() === "futebol"
    ) {
      return <SvgComponent svgPath={IconFutebol} />;
    } else if (name.toLocaleLowerCase() === "história") {
      return <GiScrollUnfurled />;
    } else if (name.toLocaleLowerCase() === "música") {
      return <HiOutlineMusicNote />;
    } else if (name.toLocaleLowerCase() === "geografia") {
      return <BsGlobeAmericas />;
    } else if (name.toLocaleLowerCase() === "arte") {
      return <IoColorPalette />;
    } else if (name.toLocaleLowerCase() === "cinema") {
      return <FaFilm />;
    } else if (name.toLocaleLowerCase() === "literatura") {
      return <GiBookCover />;
    } else if (name.toLocaleLowerCase() === "conhecimentos gerais") {
      return <GiBookshelf />;
    } else if (name.toLocaleLowerCase() === "politica") {
      return <FaBalanceScaleLeft />;
    } else if (name.toLocaleLowerCase() === "atualidades") {
      return <GiNewspaper />;
    } else if (name.toLocaleLowerCase() == "futebol americano") {
      return <FaFootballBall />;
    } else if (name.toLocaleLowerCase() === "moda") {
      return <SvgComponent svgPath={IconModa} />;
    } else if (name.toLocaleLowerCase() === "natação") {
      return <MdPool />;
    } else if (name.toLocaleLowerCase() === "vôlei") {
      return <GiVolleyballBall />;
    } else if (name.toLocaleLowerCase() === "golfe") {
      return <GiGolfFlag />;
    } else if (name.toLocaleLowerCase() === "gastronomia") {
      return <GiChefToque />;
    } else if (name.toLocaleLowerCase() === "beisebol") {
      return <SvgComponent svgPath={IconBasebol} />;
    } else if (name.toLocaleLowerCase() === "anatomia") {
      return <RiStethoscopeFill />;
    } else if (name.toLocaleLowerCase() === "mma") {
      return <GiBoxingGloveSurprise />;
    } else if (name.toLocaleLowerCase() === "basquete") {
      return <SvgComponent svgPath={IconBasquete} />;
    } else {
      return <MdClearAll />;
    }
  };

  // Alterar exibição dos dados do usuário
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Alterar exibição dos dados do usuário
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Função responsável por scrollar os items do menu com o clique do botão
  const scrollToNextItem = (direction: "left" | "right") => {
    const ulElement = ulRef.current;
    const containerElement = ulElement?.parentElement;
    const itemWidth = containerElement?.querySelector("li")?.clientWidth || 0;
    const scrollAmount = itemWidth;
    const scrollOptions = {
      left: -scrollAmount,
      top: 0,
      behavior: "smooth" as ScrollBehavior,
    };

    if (ulElement && containerElement) {
      if (direction === "left") {
        ulElement.scrollBy(scrollOptions);
      } else if (direction === "right") {
        scrollOptions.left = scrollAmount;
        ulElement.scrollBy(scrollOptions);
      }
    }
  };

  const handleValuePayload = (key: string, value: any) => {
    setPayload({
      ...payload,
      [key]: key === "dataInicio" ? new Date(value).toISOString() : value,
    });
  };

  const handleTabChange = (value: string) => {
    setSelectedTab(value);
  };

  const handleInputChange = (value: string) => {
    setSearchValue(value);
  };

  const handleClearSearch = () => {
    setSearchValue("");
  };

  const handleBackClick = (value: string) => {
    if (value === "sobre") {
      if (sobreRef.current)
        sobreRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (value === "footer") {
      if (footerRef.current)
        footerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <Header
        toggleMenu={toggleMenu}
        toggleSidebar={toggleSidebar}
        handleSubmit={handleSubmit}
        handleValuePayload={handleValuePayload}
        modality={modality}
        loadingRoom={loadingRoom}
        onBackClick={handleBackClick}
      />
      <Content>
        <DivContainer wide={"100%"}>
          <DivBottom style={{ position: "relative" }}>
            <DivColumn>
              <SlideShow>
                <Slider {...settings}>
                  <div>
                    <img
                      src={ImageQuizz1}
                      height={280}
                      className="slide-number"
                    />
                  </div>
                  <div>
                    <img
                      src={ImageQuizz3}
                      height={280}
                      className="slide-number"
                    />
                  </div>
                  {/* <div>
                    <img src={ImageMusic} height={280} className="slide-number" />
                  </div>
                  <div>
                    <img src={ImageFilm} height={280} className="slide-number" />
                  </div> */}
                </Slider>
              </SlideShow>
              {/* <Offer>
                <div>
                  <TitleSidebar bgColor>Oferta para Novo Cliente</TitleSidebar>
                  <p>Vantagens para quem fizer um novo cadastro!</p>
                  <div className={"openCard"}>
                    Registre-se
                  </div>
                </div>
                <div>
                  <img src={Image} alt="" />
                </div>
              </Offer> */}
              {loading ? <Loading /> : null}
              <SectionGalery />
              {!loading ? (
                <DivColumn>
                  <SearchContainer>
                    <SearchInput
                      type="search"
                      placeholder="Pesquisar sala"
                      value={searchValue}
                      onChange={({ target }) => handleInputChange(target.value)}
                    />
                    {searchValue ? (
                      <SearchIcon onClick={handleClearSearch}>
                        <FiX />
                      </SearchIcon>
                    ) : (
                      <SearchIcon>
                        <FiSearch />
                      </SearchIcon>
                    )}
                  </SearchContainer>
                  <div>
                    <input
                      type="radio"
                      name="tabs"
                      id="tab1"
                      value="tab1"
                      checked={selectedTab === "tab1"}
                      onChange={({ target }) => handleTabChange(target.value)}
                    />
                    {/* <label htmlFor="tab1" className="tab-button">
                      <span>Todas</span> as Salas
                    </label> */}
                    <input
                      type="radio"
                      name="tabs"
                      id="tab2"
                      value="tab2"
                      checked={selectedTab === "tab2"}
                      onChange={({ target }) => handleTabChange(target.value)}
                    />
                    {/* {user ? (
                      <label htmlFor="tab2" className="tab-button">
                        <span>Minhas Salas</span>
                      </label>
                    ) : null} */}
                    {/* <input type="search" placeholder="Pesquisar sala"/> */}
                    {/* <input type="radio" name="tabs" id="tab3" value="tab3" />
                    <label htmlFor="tab3" className="tab-button">
                      <span>Encerradas</span>
                    </label> */}
                    <section id="content1">
                      {modality.length > 0
                        ? modality.map((item: IModality) => (
                            <Card
                              key={item.id}
                              room={roomFuture}
                              modality={item}
                              itemActive={itemActive}
                              newRoom={myRoom}
                              searchValue={searchValue}
                            />
                          ))
                        : null}

                      {/* {roomFutureLive.length > 0 ? (
                        <Card
                          room={roomFuture}
                          itemActive={itemActive}
                        />
                      ) : null} */}

                      {/* {myRoom.length > 0 ? (
                        <Card
                          room={myRoom}
                          itemActive={itemActive}
                        />
                      ) : null} */}
                    </section>

                    <section id="content2">
                      {modality.length > 0
                        ? modality.map((item: IModality) => (
                            <Card
                              key={item.id}
                              room={roomFuture}
                              modality={item}
                              itemActive={itemActive}
                              newRoom={myRoom}
                              myCards={true}
                              searchValue={searchValue}
                            />
                          ))
                        : null}
                      {/* {roomFutureLive.length > 0 ? (
                        <Card
                          room={roomFutureLive}
                          itemActive={itemActive}
                        />
                      ) : null} */}
                    </section>

                    {/* <section id="content3"></section> */}
                    {/* <SearchContainer style={{ marginTop: 0, marginBottom: 10 }}>
                      <SearchInput
                        type="search"
                        placeholder="Pesquisar sala"
                        value={searchValue}
                        onChange={({ target }) =>
                          handleInputChange(target.value)
                        }
                      />
                      {searchValue ? (
                        <SearchIcon onClick={handleClearSearch}>
                          <FiX />
                        </SearchIcon>
                      ) : (
                        <SearchIcon>
                          <FiSearch />
                        </SearchIcon>
                      )}
                    </SearchContainer> */}
                  </div>
                  <SearchContainer>
                    <SearchInput
                      type="search"
                      placeholder="Pesquisar sala"
                      value={searchValue}
                      onChange={({ target }) => handleInputChange(target.value)}
                    />
                    {searchValue ? (
                      <SearchIcon onClick={handleClearSearch}>
                        <FiX />
                      </SearchIcon>
                    ) : (
                      <SearchIcon>
                        <FiSearch />
                      </SearchIcon>
                    )}
                  </SearchContainer>
                </DivColumn>
              ) : null}
              {/* <Aside className="small">
                <TitleSidebar
                  bgColor
                  style={{ fontSize: "2rem", width: "90%", lineHeight: 1 }}
                >
                  Como Funciona
                </TitleSidebar>

                <TextAside>
                  Quiz Cash é um site de apostas para testar seu
                  autoconhecimento, aonde que para entrar em uma partida é
                  necessário desembolsar do valor que a sala cobra para jogar
                  com seus oponentes. A cada Quiz que você se inscreve para
                  jogar terá na tela o valor para jogar e o horário do jogo.
                </TextAside>
                <TextAside>
                  Após inscrito para o mesmo é necessário deixar a sala aberta 1
                  minuto antes dela iniciar, quando o jogo se inicia todos os
                  participantes precisam responder às perguntas mostradas na
                  tela do seu celular ou computador todos os participantes
                  precisam responder no mesmo limite de tempo, pois cada
                  pergunta tem um tempo limite para respostas, então terá 4
                  alternativas e uma pergunta na tela. Exemplo:
                </TextAside>
                <TextAside>Quem Ganhou a Cop do Mundo de 2022:</TextAside>
                <TextAside>A) França</TextAside>
                <TextAside style={{ marginTop: 0 }}>B) Alemanhã</TextAside>
                <TextAside style={{ marginTop: 0 }}>C) Brasil</TextAside>
                <TextAside style={{ marginTop: 0 }}>D) Argentina</TextAside>
                <TextAside>
                  Pronto, agora basta você selecionar a alternativa correta e
                  continuar para as próximas perguntas.
                </TextAside>
                <TextAside style={{ marginTop: 0 }}>
                  Até a 10ª pergunta será permitido ter a reentrada da sala caso
                  você não responda de forma correta uma das perguntas, basta
                  pagar o valor da sala novamente. Chega na 11ª pergunta não
                  haverá mais a opção de reentrada.
                </TextAside>
                <TextAside style={{ marginTop: 0 }}>
                  O jogo finaliza quando o número limite de perguntas da rodada
                  terminar, então caso na noite tenha 100 perguntas e tenha 1000
                  participantes e cada um destes fez um depósito de R$20,00 e ao
                  final restaram somente 10 participantes o prêmio final será
                  dividido entre os 10, então neste caso cada um participante
                  ganharia R$1.600,00.
                </TextAside>
                <TextAside style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Boa sorte e bons ganhos!
                </TextAside>
              </Aside> */}
              <SectionInvite />
              <SectionNumbers />
              <div ref={footerRef}>
                <Footer />
              </div>
            </DivColumn>
            {user ? (
              <ProfileMenu isOpenMenu={menuOpen} toggleMenu={toggleMenu} />
            ) : null}
            <DivContainerSidebar isOpen={sidebarOpen}>
              <Sidebar>
                <TitleSidebar bgColor>Tópicos</TitleSidebar>
                {/* {showLeftArrow ? (
                  <button onClick={() => scrollToNextItem("left")}>
                    <IoIosArrowBack id="arrow-left" size={20} />
                  </button>
                ) : null} */}
                <ul
                  className={`lista-com-scrollbar ${
                    scrollbarVisivel ? "scrollbar-visivel" : ""
                  }`}
                  ref={ulRef}
                >
                  <li
                    className={itemActive === "todos" ? "active" : ""}
                    onClick={() => setItemActive("todos")}
                  >
                    {iconSelected("all")}
                    <p>Todos</p>
                  </li>
                  {modality.length > 0
                    ? modality.map((item: any) => {
                        return (
                          <li
                            key={item}
                            className={itemActive === item.nome ? "active" : ""}
                            onClick={() => setItemActive(`${item.nome}`)}
                          >
                            {item.foto ? (
                              <div>
                                <img src={item.foto} alt={item.nome} />
                              </div>
                            ) : (
                              iconSelected(item.nome)
                            )}
                            <p>{item.nome}</p>
                          </li>
                        );
                      })
                    : null}
                </ul>
                {/* {showRightArrow ? (
                  <button onClick={() => scrollToNextItem("right")}>
                    <IoIosArrowForward id="arrow-right" size={20} />
                  </button>
                ) : null} */}
              </Sidebar>
            </DivContainerSidebar>
          </DivBottom>
        </DivContainer>
      </Content>

      {localStorageCookie === null ? (
        <CookieConsent
          debug={true}
          buttonText="Aceitar"
          onAccept={updateLocalStorage}
          style={{ background: "#2B373B" }}
          buttonStyle={{
            background: "var(--green-lighter400)",
            borderRadius: 8,
            color: "var(--white)",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Utilizamos cookies essenciais e tecnologias semelhantes de acordo com
          nossa&nbsp;
          <Link
            className="openModalBtn"
            style={{
              border: "1px solid var(--white)",
              backgroundColor: "var(--white)",
              color: "var(--black)",
              padding: "0 1px",
              textDecoration: "none",
            }}
            to="/política-privacidade"
            target="_blank"
          >
            política de privacidade
          </Link>{" "}
          e, ao continuar navegando, você concorda com estas condições.
        </CookieConsent>
      ) : (
        <></>
      )}

      <Modal
        isOpen={isNotificationOpen}
        onClose={onNotificationClose}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Habilite as notificações</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
              Por favor, habilite as notificações para receber atualizações
              importantes.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={onNotificationClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Home;
