import Provider from "./config";
import Toast from "../components/Toast";

export const postHelp = async (payload: object) => {
	try {
		const response = await Provider.post("tabela/", {...payload});

		return response
	} catch (err: any) {
		if (err.response && err.response.data && err.response.data.message) {
			Toast.show(err.response.data.message, "error");
		} else {
			throw Error("Erro ao enviar formulário");
		}
	}
}