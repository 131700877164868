import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Button } from "@chakra-ui/react";
import InputComponent from "../../components/input";
import { Container, Content, Link } from "./styles";
import { useAuth } from "../../contexts/auth";
import Toast from "../../components/Toast";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE, CURRENT_STORE } from "../../environments";
import Logo from "../../assets/logo_quizz_cash.webp";
import { resetPassword, validateToken } from "../../services/auth";

export interface IResetPassword {
  senha: string;
  confirm_password: string;
  token: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [validToken, setValidToken] = useState<boolean>(false);
  const [payload, setPayload] = useState<IResetPassword>({
    senha: "",
    confirm_password: "",
    token: "",
  });

  useEffect(() => {
    if (localStorage.getItem(`@${CURRENT_STORE}${BASE}:user`)) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const token = query.get("token");
    if (token) {
      setPayload({
        ...payload,
        token: token,
      });
      handleValidateToken(token);
    }
  }, []);

  const handleValidateToken = async (token: any) => {
    try {
      const response = await validateToken(token);
      if (response) {
        setValidToken(response.valid);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();

    setLoading(true);
    if (validate()) {
      try {
        const result = await resetPassword(payload);
        if (result) {
          Toast.show("Senha alterada com sucesso!", "success");
          navigate("/login");
          setLoading(false);
        }
      } catch (error) {
        console.error("Erro: ", error);
      }
    }
    setLoading(false);
  };

  const validate = () => {
    if (!payload.senha) {
      Toast.show("O campo senha é obrigatório", "error");
      return false;
    } else if (payload.senha.length < 8) {
      Toast.show("A senha precisa ter no mínimo 8 dígitos", "error");
      return false;
    }
    if (!payload.confirm_password.trim()) {
      Toast.show("O campo confirmar senha é obrigatório", "error");
      return false;
    } else if (payload.confirm_password !== payload.senha) {
      Toast.show(
        "O campo confirmar senha precisa ser igual ao campo senha",
        "error"
      );
      return false;
    }
    return true;
  };

  const handlePayload = (key: string, value: string) => {
    setPayload({ ...payload, [key]: value });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit} autoComplete="chrome-off">
        <Content>
          <Box
            p={"9px"}
            rounded={"lg"}
            boxShadow={"md"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            w={310}
            h={validToken ? 350 : 280}
          >
            <img src={Logo} width={50} />
            {validToken ? (
              <>
                <label>Redefinir Senha</label>
                <InputComponent
                  placeholder="Digite sua senha"
                  type="password"
                  autoComplete="chrome-off"
                  onChange={(ev: any) =>
                    handlePayload("senha", ev.target.value)
                  }
                />
                <InputComponent
                  placeholder="Digite sua senha novamente"
                  type="password"
                  autoComplete="chrome-off"
                  onChange={(ev: any) =>
                    handlePayload("confirm_password", ev.target.value)
                  }
                />
                <Button
                  width={"full"}
                  mt={5}
                  mb={2}
                  isLoading={loading}
                  bg={"#0a4f00"}
                  border={"2px"}
                  borderRadius={"3xl"}
                  color={"white"}
                  borderColor={"#0a4f00"}
                  type="submit"
                >
                  ENVIAR
                </Button>
                <Button
                  w={"full"}
                  bg={"light"}
                  border={"2px"}
                  borderColor={"white"}
                  borderRadius={"3xl"}
                  isDisabled={loading}
                >
                  <Link to={"/login"}>LOGIN</Link>
                </Button>
              </>
            ) : (
              <>
                <div className="token">
                  <span>Token Inválido</span>
                </div>
                <Button
                  width={"full"}
                  mt={5}
                  mb={2}
                  bg={"#0a4f00"}
                  border={"2px"}
                  borderRadius={"3xl"}
                  color={"white"}
                  borderColor={"#0a4f00"}
                >
                  <Link to={"/login"}>LOGIN</Link>
                </Button>
                <Button
                  width={"full"}
                  bg={"light"}
                  border={"2px"}
                  borderColor={"white"}
                  borderRadius={"3xl"}
                >
                  <Link to={"/cadastro"}>CRIAR CADASTRO</Link>
                </Button>
              </>
            )}
          </Box>
        </Content>
      </form>
    </Container>
  );
};

export default ResetPassword;
