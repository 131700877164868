import React from "react";
import {
  ButtonReadMore,
  Collumn,
  Container,
  Row,
  Subtitle,
  Title,
} from "./styles";
import ImageInvites from "../../assets/image-invite.webp";
import { BsInstagram } from "react-icons/bs";

const SectionInvite = () => {
  return (
    <Container>
      <Row>
        <Collumn>
          <Title>Jogue e se Divirta.</Title>
          <Subtitle>
            Sabia que com o Quiz Cash você tem a opção de jogar com seus amigos
            através da opção "Adicionar Sala" pelo botão Superior da tela. Além
            do Quiz testar seu conhecimento em perguntas de diversas categorias
            você também é recompensado ao ganhar o Quiz, o jogo consiste em
            responder um total de 15 perguntas sobre o tema escolhido, desta
            forma o ganhador com mais acertos receberá o prêmio em dinheiro,
            caso o número de acertos empate o prêmio será dividido, venha ser um
            mito do Quiz!!!
          </Subtitle>
          {/* <ButtonReadMore>Read More</ButtonReadMore> */}
        </Collumn>
        <Collumn style={{ width: "46%" }}>
          <img src={ImageInvites} height={350} width={500} />
          <div className="socials">
            <a href="https://www.instagram.com/quizcash_ofc/" target="_blank">
              <BsInstagram
                style={{
                  color: "var(--white)",
                  height: 22,
                  width: 22,
                  marginRight: 10,
                }}
              />
            </a>
          </div>
        </Collumn>
      </Row>
    </Container>
  );
};

export default SectionInvite;
