import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	/* flex-wrap: wrap; */
	justify-content: center;
	width: 100%;
	padding: 50px 0;
`;

export const CardGreen = styled.div`
	background: var(--green-lighter500);
	margin-bottom: 20px;
	height: 270px;
	width: 260px;
	padding: 10px;
	text-align: center;

	&:hover{
		box-shadow: 0 0 10px 0 var(--muted);
	}

	@media (max-width: 786px) {
		height: 220px;
		width: 210px;
	}

	@media (max-width: 665px) {
		height: 200px;
		width: 190px;
	}
	@media (max-width: 600px) {
		flex: 1;
		height: auto;
		width: 28%;
	}
`;

export const CardGreenLighter = styled.div`
	background: var(--green-lighter400);
	margin-inline: 20px;
	margin-bottom: 20px;
	height: 270px;
	width: 260px;
	padding: 10px;
	text-align: center;

	&:hover{
		box-shadow: 0 0 10px 0 var(--muted);
	}

	@media (max-width: 786px) {
		height: 220px;
		width: 210px;
	}
	@media (max-width: 665px) {
		height: 200px;
		width: 190px;
	}
	@media (max-width: 600px) {
		flex: 1;
		height: auto;
		width: 28%;
	}
	@media (max-width: 460px) {
		margin-inline: 10px;
	}
`;

export const Title = styled.p`
	color: var(--white);
	font-size: 2rem;
	font-weight: bold;
	margin-top: 10px;

	@media (max-width: 786px) {
		font-size: 1.5rem;
	}
	@media (max-width: 665px) {
		font-size: 1.2rem;
	}
	@media (max-width: 529px) {
		font-size: 1rem;
	}
	@media (max-width: 460px) {
		font-size: .8rem;
	}
	@media (max-width: 386px) {
		font-size: .7rem;
	}
`;

export const Content = styled.p`
	color: var(--white);
	font-size: 1rem;
	font-weight: 500;
	margin-top: 10px;

	@media (max-width: 786px) {
		font-size: .8rem;
	}
	@media (max-width: 529px) {
		font-size: .7rem;
	}
	@media (max-width: 460px) {
		font-size: .6rem;	
	}
	@media (max-width: 386px) {
		font-size: .5rem;
	}
`;