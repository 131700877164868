import React from "react";
import {
  CardGreen,
  CardGreenLighter,
  Container,
  Content,
  Title,
} from "./styles";

const SectionGalery = () => {
  return (
    <Container>
      <CardGreen>
        <Title>Conhecimento</Title>
        <Content>
          Através do Quiz Cash você absorve conhecimento e utiliza do mesmo para
          responder as perguntas e ganhar a premiação.
        </Content>
      </CardGreen>
      <CardGreenLighter>
        <Title>Diversão</Title>
        <Content>
          Divirta-se jogando com seus amigos, convidando eles para jogar e
          testando o conhecimento deles para saber quem é o mito do quiz.
        </Content>
      </CardGreenLighter>
      <CardGreen>
        <Title>Lucros</Title>
        <Content>
          Ganhe a premiação da sala em valor através do seu conhecimento sobre
          perguntas e respostas de categoria de sua preferencia.
        </Content>
      </CardGreen>
    </Container>
  );
};

export default SectionGalery;
