import React, { ChangeEvent, useCallback, useState } from "react";
import { Card, Container, DivColumn, DivRow, Title } from "./styles";
import { GiWallet, GiCash } from "react-icons/gi";
import { FiMail } from "react-icons/fi";
import { MdManageAccounts, MdOutlineLiving } from "react-icons/md";
import { BsShieldLock } from "react-icons/bs";
import { TbChartHistogram } from "react-icons/tb";
import { FaHistory, FaUserEdit } from "react-icons/fa";
import { MouseEvent, useEffect, useRef } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
  Textarea,
} from "@chakra-ui/react";
import { IBalance } from "../../pages/home";
import Toast from "../Toast";
import InputMask from "react-input-mask";
import { useAuth } from "../../contexts/auth";
import { editUser, getBalance } from "../../services/user";
import { formatMoney } from "../../utils/format";
import {
  getAccountBank,
  getConfirmPix,
  getHistoryAvailable,
  getPayments,
  postAccountBank,
  postAlterBank,
  postPayment,
  postRequestSack,
} from "../../services/payment";
import QRCode from "qrcode.react";
import axios from "axios";
import Select from "react-select";
import { format, isAfter } from "date-fns";
import { AiFillCheckCircle } from "react-icons/ai";
import { validateCPF } from "validations-br";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { postHelp } from "../../services/contact";
import { copyToClipboard } from "../../utils/copyToClipboard";

interface ProfileMenuProps {
  isOpenMenu: boolean;
  toggleMenu: () => void;
}

interface ICard {
  numeroCartao: string;
  nomeCartao: string;
  taxpayer: string;
  exp_mes: string;
  exp_ano: string;
  cvv: number;
  quantidade: number;
}

interface ITypePayment {
  id: string;
  nome: string;
}

interface IUser {
  nome?: string;
  email?: string;
  dataNascimento?: string;
  cpfCnpj?: string;
  telefone?: string;
}

interface IBank {
  userId: string;
  bank: IListBank;
  agency: string;
  number: string;
  accountType: string;
  cpf: string;
}

interface IListBank {
  code: number;
  id: string;
  name: string;
  value: string;
  label: string;
  inativo?: boolean;
  conta?: string;
  devariPayBancoToken?: string;
}

interface IAccountType {
  id: string;
  label: string;
  value: string;
}

interface IPix {
  ok: boolean;
  qrCode: string;
  chcriacao: number;
}

interface IHistory {
  id: string;
  idDisponivel: string;
  chave: number;
  chcriacao: number;
  valor: number;
  juros?: number;
  multa?: number;
  historico?: string;
  idClasse: string;
  excluido: boolean;
  excluivel: boolean;
  editavel: boolean;
  criadoEm: string;
  atualizadoEm: string;
}

interface IHelp {
  idPessoa: string;
  idClasse: string;
  nome: string;
  codigo: string;
  descricao: string;
}

export function ProfileMenu({ isOpenMenu, toggleMenu }: ProfileMenuProps) {
  const menuRef = useRef<HTMLDivElement>(null);
  const { user, updateUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isSackOpen,
    onOpen: onSackOpen,
    onClose: onSackClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const {
    isOpen: isHistoryOpen,
    onOpen: onHistoryOpen,
    onClose: onHistoryClose,
  } = useDisclosure();
  const {
    isOpen: isHelpOpen,
    onOpen: onHelpOpen,
    onClose: onHelpClose,
  } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const INTERVAL = useRef<NodeJS.Timer | undefined | null>(null);
  const transaoRef = useRef();
  const [typePayment, setTypePayment] = useState<ITypePayment[]>([]);
  const [paymentSelected, setPaymentSelected] = useState<ITypePayment | null>(
    null
  );
  const [balanceUser, setBalanceUser] = useState<IBalance | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [loadingAddAccount, setLoadingAddAccount] = useState<boolean>(false);
  const [loadingSack, setLoadingSack] = useState<boolean>(false);
  const [loadingAlterBank, setLoadingAlterBank] = useState<boolean>(false);
  const [loadingHelp, setLoadingHelp] = useState<boolean>(false);
  const [masked, setMasked] = useState<string>("999.999.999-999");
  const [confirmReply, setConfirmReply] = useState<boolean>(false);
  const [qrCodeData, setQrCodeData] = useState<any>("");
  const [visibleQrCode, setVisibleQrCode] = useState<IPix | null>(null); // simulação de qr code
  const [loadingQrCode, setLoadingQrCode] = useState<boolean>(false);
  const [listBank, setListBank] = useState<IListBank[]>([]);
  const [itensSelectHelp, setItensSelectHelp] = useState([
    {
      value: "Dúvidas",
      label: "Dúvidas"
    },
    {
      value: "Sugestões",
      label: "Sugestoes"
    },
    {
      value: "Elogios",
      label: "Elogios"
    }
  ])
  const [inputValueSack, setInputValueSack] = useState("");
  const [isSmallerThanCertainSize] = useMediaQuery("(max-width: 400px)");
  const [myListBank, setMyListBank] = useState<IListBank[]>([]);
  const [bankSelected, setBankSelected] = useState<IListBank | null>(null);
  const [valuePaymentPix, setValuePaymentPix] = useState<number>(0);
  const [valuePaymentCard, setValuePaymentCard] = useState<number>(0);
  const [administrateTaxPix, setAdministrateTaxPix] = useState<number>(0);
  const [administrateTaxCard, setAdministrateTaxCard] = useState<number>(0);
  const [statusPayment, setStatusPayment] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPages] = useState<number>(1);
  const [history, setHistory] = useState<Array<IHistory>>([]);
  const [payloadCard, setPayloadCard] = useState<ICard>({
    numeroCartao: "",
    nomeCartao: "",
    taxpayer: "",
    exp_mes: "",
    exp_ano: "",
    cvv: 0,
    quantidade: 0,
  });
  const [payloadUser, setPayloadUser] = useState<IUser>({
    nome: "",
    cpfCnpj: "",
    dataNascimento: "",
    email: "",
    telefone: "",
  });
  const [payloadBank, setPayloadBank] = useState<IBank>({
    userId: user && user.id ? user.id : "",
    bank: { code: 0, id: "", name: "", label: "", value: "" },
    number: "",
    agency: "",
    accountType: "",
    cpf: "",
  });
  const [payloadHelp, setPayloadHelp] = useState<IHelp>({
    idPessoa: user && user.id ? user.id : "",
    idClasse: "64943634df9b3be638e13f0b",
    nome: "",
    codigo: "",
    descricao: ""
  })
  const idAccountType: IAccountType[] = [
    {
      id: "61cd272ead47e0a02eab67d1",
      label: "Conta corrente",
      value: "Conta corrente",
    },
    {
      id: "6479efaff1aefa8614d9ce45",
      label: "Conta poupança",
      value: "Conta poupança",
    },
  ];

  useEffect(() => {
    if (user) {
      getTypePayments();
      getListBank();
      if (isSackOpen) {
        getMyListBank();
      }
    }
  }, [user, isSackOpen]);

  useEffect(() => {
    if (user) {
      setPayloadUser({
        nome: user.nome ? user.nome : "",
        cpfCnpj: user.cpfCnpj ? user.cpfCnpj : "",
        dataNascimento: user.dataNascimento ? user.dataNascimento : "",
        email: user.email ? user.email : "",
        telefone: user.telefone ? user.telefone : "",
      });
    }
  }, [user]);

  useEffect(() => {
    if (isOpenMenu) getUserBalance();
  }, [isOpenMenu]);

  useEffect(() => {
    if (isOpenMenu) getHistory();
  }, [isOpenMenu, page]);

  // useEffect(() => {
  //   const limparCampoData = () => {
  //     const dateInput = document.getElementById('validade') as HTMLInputElement;
  //     if (dateInput) {
  //       dateInput.value = '';
  //     }
  //   };

  //   // Executa a limpeza do campo de data quando o modal é aberto
  //   limparCampoData();
  // }, [isOpen, payloadCard])

  useEffect(() => {
    if (inputValueSack === "R$ 0,00") {
      setInputValueSack("");
    }
  }, [inputValueSack]);

  useEffect(() => {
    if (inputRef.current) {
      const inputElement = inputRef.current;
      inputElement.setSelectionRange(
        inputElement.value.length,
        inputElement.value.length
      );
    }
  }, []);

  useEffect(() => {
    if (confirmReply) {
      sendAlterBank();
    }
  }, [confirmReply]);

  // Buscar saldo do usuário
  const getUserBalance = async () => {
    try {
      const result = await getBalance(user?.id);

      if (result) {
        setBalanceUser(result);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const getTypePayments = async () => {
    try {
      const result = await getPayments();

      if (result) {
        setTypePayment(result);
        setPaymentSelected(result[0]);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const getListBank = async () => {
    try {
      const result = await axios.get(
        "https://pay.devari.com.br/api/v1/bank/code/",
        {
          headers: {
            Authorization: "Token d41b413d803b6814b322c49ff69e7c2c0c905bf1",
          },
        }
      );
      if (result) {
        let _list: IListBank[] = [];

        result.data.map((item: IListBank) =>
          _list.push({
            id: item.id,
            code: item.code,
            name: item.name,
            label: item.name,
            value: item.name,
          })
        );

        setListBank(_list);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  // Buscar histórico de pagamentos
  const getHistory = async () => {
    try {
      if (user && user.LocEscrit.id) {
        const result = await getHistoryAvailable(user?.LocEscrit.id, page);

        if (result) {
          setTotalPages(result.totalPages);
          setHistory(result.docs);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  // Buscar lista de bancos do usuário
  const getMyListBank = async () => {
    try {
      if (user) {
        const result = await getAccountBank(user?.LocEscrit.id);

        if (result) {
          let list: IListBank[] = [];

          result.map((item: any) =>
            list.push({
              id: item.id,
              code: +item.codigoFebraban,
              label: item.nome,
              name: item.nome,
              value: item.nome,
              inativo: item.inativo,
              conta: item.conta,
              devariPayBancoToken: item.devariPayBancoToken,
            })
          );

          list.sort((a, b) => {
            if (a.inativo === false && b.inativo === true) {
              return -1; // 'a' vem antes de 'b'
            } else if (a.inativo === true && b.inativo === false) {
              return 1; // 'b' vem antes de 'a'
            } else {
              return 0; // não há diferença de ordem entre 'a' e 'b'
            }
          });

          setMyListBank(list);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  // Alterar banco principal do usuário
  const sendAlterBank = async () => {
    try {
      if (user && user.id && bankSelected) {
        setLoadingAlterBank(true);
        const result = await postAlterBank(
          user.id,
          user.LocEscrit.id,
          bankSelected.id
        );

        if (result?.status === 201) {
          let list: IListBank[] = myListBank;

          list.map((item: IListBank) => {
            if (result.data.id === item.id) {
              item.inativo = false;
            } else {
              item.inativo = true;
            }
          });

          list.sort((a, b) => {
            if (a.inativo === false && b.inativo === true) {
              return -1; // 'a' vem antes de 'b'
            } else if (a.inativo === true && b.inativo === false) {
              return 1; // 'b' vem antes de 'a'
            } else {
              return 0; // não há diferença de ordem entre 'a' e 'b'
            }
          });
          Toast.show("Novo banco ativo!", "success");
          setMyListBank(list);
          setBankSelected(null);
          setConfirmReply(false);
          onConfirmClose();
        }
      }
    } catch (error) {
      setBankSelected(null);
      onConfirmClose();
      setConfirmReply(false);
      console.error("Erro: ", error);
    }
    setLoadingAlterBank(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      toggleMenu();
    }
  };

  const handleMouseDown = (event: Event) => {
    const mouseEvent = event as unknown as MouseEvent;
    handleClickOutside(mouseEvent);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [toggleMenu]);

  const handlePayloadCard = (key: string, value: string | number) => {
    if (key === "cvv" && value) {
      if (typeof value === "string" && value.length > 4) {
        return;
      } else {
        setPayloadCard({
          ...payloadCard,
          cvv: +value,
        });
      }
    } else if (key === "date" && typeof value === "string") {
      const newValue = value.split("/");
      if (newValue[0] !== "_" && newValue[0] !== "__") {
        setPayloadCard({
          ...payloadCard,
          exp_mes: newValue[0],
        });
      }
      if (newValue[1] !== "_" && newValue[1] !== "__") {
        setPayloadCard({
          ...payloadCard,
          exp_ano: newValue[1],
        });
      }
    } else if (key === "numeroCartao" && typeof value === "string") {
      const newValue = value.replaceAll(" ", "");
      setPayloadCard({
        ...payloadCard,
        [key]: newValue,
      });
    } else if (key === "quantidade") {
      const _value = Math.ceil(+value / (1 - 4.45 / 100));
      setPayloadCard({
        ...payloadCard,
        [key]: +value,
      });
      setAdministrateTaxCard(_value - Number(value));
      setValuePaymentCard(_value);
    } else {
      setPayloadCard({
        ...payloadCard,
        [key]: value,
      });
    }
  };

  const handleEditUser = (key: string, value: string | number) => {
    if (key === "cpfCnpj" || key === "telefone") {
      const newValue =
        typeof value === "string"
          ? value.replaceAll(/[^a-zA-Z0-9]/g, "")
          : value;
      setPayloadUser({
        ...payloadUser,
        [key]: newValue,
      });
    } else {
      setPayloadUser({ ...payloadUser, [key]: value });
    }
  };

  const handleSubmitEditUser = async () => {
    if (payloadUser !== null && validateUser()) {
      setLoadingEdit(true);
      try {
        const result = await editUser(user?.id, payloadUser);

        if (result) {
          updateUser(result);
          setLoadingEdit(false);
          onEditClose();
        }
      } catch (error) {
        onEditClose();
        setLoadingEdit(false);
        console.error("Erro: ", error);
      }
    }
  };

  const validateUser = () => {
    let isValid;
    if (
      payloadUser.cpfCnpj &&
      payloadUser.cpfCnpj.replace(/\D/g, "").length < 12
    ) {
      isValid = validateCPF(payloadUser.cpfCnpj);
      if (!isValid) {
        Toast.show("CPF inválido", "error");
        return false;
      }
    } else {
      return true;
    }
  };

  const inputFilter = (event: any) => {
    const keyCode = event.which || event.keyCode;
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault(); // Impede a entrada de caracteres não numéricos
    }
  };

  const handleSubmitCard = async () => {
    if (validate()) {
      setLoading(true);
      try {
        const result = await postPayment({
          ...payloadCard,
          userId: user?.id,
          condicaoPagamento: paymentSelected?.id,
        });

        if (result?.status === 201) {
          Toast.show("Pagamento enviado com sucesso!", "success");
          onClose();
          await getUserBalance();
          setLoading(false);
        } else {
          onClose();
          setLoading(false);
        }
      } catch (error) {
        onClose();
        setLoading(false);
        console.error("Erro: ", error);
      }
    }
  };

  // Submit para adicionar conta do banco
  const handleSubmitAddAccount = async () => {
    if (validatePayloadBank()) {
      setLoadingAddAccount(true);

      let _payload = { ...payloadBank };
      let cpf = _payload.cpf.replaceAll(".", "").replace("-", "");
      _payload.cpf = cpf;

      try {
        const result = await postAccountBank(_payload);

        if (result?.status === 201) {
          onAddClose();
          Toast.show("Banco adicionado com sucesso.", "success");
        }
      } catch (error) {
        console.error("Erro: ", error);
      }
      setLoadingAddAccount(false);
    }
  };

  // Submit para solicitar saque
  const submitRequestSack = async (ev: any) => {
    ev.preventDefault();
    if (validateSack()) {
      setLoadingSack(true);
      const myBank = myListBank.find((item) => item.inativo === false);
      const value = inputValueSack.split(" ");
      if (user && myBank && value) {
        try {
          const result = await postRequestSack({
            value: parseFloat(value[1].replace(".", "").replace(",", ".")),
            idLocEscritu: user.LocEscrit.id,
            devariPayCustomerId: user.devariPayCustomerId,
            idTokenBanco: myBank.devariPayBancoToken,
            userId: user.id,
          });

          if (result?.status === 201) {
            onSackClose();
            setInputValueSack("");
            Toast.show("Solicitação enviada com sucesso.", "success");
          }
        } catch (error) {
          console.error("Erro: ", error);
        }
      }
      setLoadingSack(false);
    }
  };

  // Submit ajuda/fale conosco
  const handleSubmitHelp = async () => {
    if(validateHelp()){
      setLoadingHelp(true);
      try {
        const result = await postHelp(payloadHelp)

        if(result?.status === 201){
          Toast.show("Enviado com sucesso.", "success");
          onHelpClose()
        }
      } catch (error) {
        console.error("Erro: ", error)
        onHelpClose()
        setLoadingHelp(false)
      }
    }
  }

  // Validação do payloadCard
  const validate = () => {
    if (payloadCard.numeroCartao === "") {
      Toast.show("O campo número do cartão precisa ser preenchido", "error");
      return false;
    }
    if (payloadCard.nomeCartao === "") {
      Toast.show(
        "O campo nome impresso no cartão precisa ser preenchido",
        "error"
      );
      return false;
    }
    if (payloadCard.taxpayer === "") {
      Toast.show(
        "O campo CPF do titular do cartão precisa ser preenchido",
        "error"
      );
      return false;
    }
    if (payloadCard.exp_mes === "" && payloadCard.exp_ano === "") {
      Toast.show("O campo validade precisa ser preenchido", "error");
      return false;
    }
    if (payloadCard.cvv === 0) {
      Toast.show("O campo CVV precisa ser preenchido", "error");
      return false;
    }
    if (payloadCard.quantidade === 0) {
      Toast.show("O Campo valor precisa ser preenchido", "error");
      return false;
    } else if(payloadCard.quantidade < 5) {
      Toast.show("O valor mínimo para depósito é de R$ 5,00", "error");
      return false;
    }
    if (user?.cpfCnpj === null) {
      Toast.show("Cadastre o CPF/CNPJ para poder prosseguir.");
      return false;
    } else {
      return true;
    }
  };

  // Validação do payloadBank
  const validatePayloadBank = () => {
    if (!payloadBank.bank) {
      Toast.show("O campo nome do banco precisa ser preenchido", "error");
      return false;
    }
    if (!payloadBank.agency) {
      Toast.show("O campo agência precisa ser preenchido", "error");
      return false;
    }
    if (!payloadBank.number) {
      Toast.show("O campo conta precisa ser preenchido", "error");
      return false;
    }
    if (!payloadBank.accountType) {
      Toast.show("O campo tipo da conta precisa ser preenchido", "error");
      return false;
    }
    if (!payloadBank.cpf) {
      Toast.show("O campo CPF precisa ser preenchido", "error");
      return false;
    }
    return true;
  };

  // Validação do valor digitado para solicitar o saque
  const validateSack = () => {
    let _value = inputValueSack.split(" ");
    let newValue = Number(_value[1].replaceAll(".", "").replace(",", ""));
    if (balanceUser && Number(`${balanceUser.saldoSaque}00`) < newValue) {
      Toast.show(
        "O valor do saque não pode ser superior ao valor disponível",
        "error"
      );
      return false;
    }
    return true;
  };

  // Validação do payloadHelp
  const validateHelp = () => {
    if(!payloadHelp.nome){
      Toast.show("O campo título precisa ser preenchido.", "error");
      return false;
    }
    if(!payloadHelp.codigo){
      Toast.show("O campo assunto precisa ser preenchido.", "error");
      return false;
    }
    if(!payloadHelp.descricao){
      Toast.show("O campo mensagem precisa ser preenchido.", "error");
      return false;
    }
    return true;
  }

  // Recebe os dados digitados do input para gerar qr code
  const handleInputChange = (value: string) => {
    const _value = Math.ceil(+value / (1 - 1 / 100));
    setQrCodeData(+value);
    setValuePaymentPix(_value);
    setAdministrateTaxPix(_value - Number(value));
  };

  // Recebe os dados digitados do input para setar no payloadBank
  const handleInputBank = (key: string, value: any) => {
    if (key === "accountType") {
      setPayloadBank({ ...payloadBank, [key]: value.id });
    } else {
      setPayloadBank({ ...payloadBank, [key]: value });
    }
  };

  const submitQrCode = async () => {
    if (!qrCodeData) {
      Toast.show("Preencha o valor do PIX.");
      return;
    } else if(qrCodeData < 5) {
      Toast.show("O valor mínimo para depósito é de R$ 5,00", "error")
      return;
    } else {
      try {
        setLoadingQrCode(true);
        const result = await postPayment({
          quantidade: qrCodeData,
          userId: user?.id,
          condicaoPagamento: paymentSelected?.id,
        });

        if (result) {
          const { expiracao } = result.data;

          // if (!isAfter(new Date(), new Date(expiracao))) {
          transaoRef.current = result.data;
          setVisibleQrCode(result.data);

          INTERVAL.current = setInterval(
            () => watchPaymentStatus(result.data.chcriacao),
            2000
          );
          // }
          setLoadingQrCode(false);
        }
      } catch (error) {
        setLoadingQrCode(false);
        onClose();
        console.error(error);
        Toast.show("Erro ao gerar QR code.", "error");
      }
    }
  };

  const watchPaymentStatus = async (chcriacao: number) => {
    try {
      // if (!visibleQrCode) {
      //   return; // Retorna caso transaoRef.current seja undefined
      // }

      const response = await getConfirmPix(chcriacao);

      const { baixado } = response;
      if (baixado === true) {
        clearInterval(INTERVAL.current!);
        INTERVAL.current = null;
        setStatusPayment(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChangeSack = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/[^\d]/g, ""); // Remove tudo que não é dígito

      let integerPart = value.slice(0, -2);
      let decimalPart = value.slice(-2);

      if (integerPart === "" && decimalPart === "") {
        setInputValueSack("");
        return;
      }

      if (integerPart === "") {
        integerPart = "0";
      }

      if (decimalPart.length === 1) {
        decimalPart = "0" + decimalPart;
      }

      // Remove os zeros à esquerda da parte inteira
      integerPart = parseInt(integerPart).toString();

      // Adiciona o ponto na casa de milhar
      const parts = integerPart.split("").reverse();
      let formattedIntegerPart = parts.reduce((acc, digit, index) => {
        if (index !== 0 && index % 3 === 0) {
          return digit + "." + acc;
        } else {
          return digit + acc;
        }
      }, "");

      const formattedValue = `R$ ${formattedIntegerPart},${decimalPart}`;

      setInputValueSack(formattedValue);
    },
    []
  );

  const handleInputClick = useCallback(() => {
    if (inputRef.current) {
      const inputElement = inputRef.current;
      inputElement.setSelectionRange(
        inputElement.value.length,
        inputElement.value.length
      );
    }
  }, []);

  const handleConfirmModal = (value: IListBank) => {
    setBankSelected(value);
    onConfirmOpen();
  };

  const handleHelp = (key: string, value: any) => {
    if(key === "codigo")
      setPayloadHelp({...payloadHelp, [key]: value.value});
    else
      setPayloadHelp({...payloadHelp, [key]: value})
  }

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleCopyPix = async (text: string) => {
    copyToClipboard(text)
    return Toast.show("O link foi copiado", "success");
  }

  return (
    <Container isOpen={isOpenMenu}>
      <div>
        <h2>{user?.nome}</h2>
        <div>
          <div>
            <div style={{marginTop: 50}}>
              {/* <span>Dudex</span>
              <strong className="green">R$ 0,00</strong> */}
            </div>

            <div>
              <span>Disponível</span>
              <strong className="green">
                {balanceUser && balanceUser.saldoSaque
                  ? formatMoney(balanceUser?.saldoSaque)
                  : "R$ 0,00"}
              </strong>
            </div>
          </div>

          <div>
            <button onClick={onOpen}>
              <strong>Depositar $</strong>
            </button>

            <div>
              <span>Crédito de Aposta</span>
              <strong className="green">
                {balanceUser && balanceUser.saldoAposta
                  ? formatMoney(balanceUser?.saldoAposta)
                  : "R$ 0,00"}
              </strong>
            </div>
          </div>
        </div>

        <ul>
          <li>
            <a href="#">
              <strong>Conta</strong>
            </a>
            <div></div>
          </li>
          <li>
            <a href="#">
              <strong>Minhas ofertas</strong>
            </a>
            <div></div>
          </li>
          <li>
            <a href="#">
              <strong>Preferências</strong>
            </a>
            <div></div>
          </li>
        </ul>
      </div>

      <div>
        <div>
          <div>
            <div className="button" onClick={onAddOpen}>
              <GiWallet color="#40FD26" size={"40px"} />
              <strong>Banco</strong>
            </div>
            <div className="button" onClick={onSackOpen}>
              <GiCash color="#40FD26" size={"40px"} />
              <strong>Sacar</strong>
            </div>
            <div className="button">
              <MdManageAccounts color="#40FD26" size={"40px"} />
              <strong>Minha conta</strong>
            </div>
            <div className="button" onClick={onEditOpen}>
              <FaUserEdit color="#40FD26" size={"40px"} />
              <strong>Editar conta</strong>
            </div>
            <div className="button">
              <BsShieldLock color="#40FD26" size={"40px"} />
              <strong>
                Controle
                <br />
                de aposta
              </strong>
            </div>
            <div className="button">
              <TbChartHistogram color="#40FD26" size={"40px"} />
              <strong>Minha atividade</strong>
            </div>
            <div className="button" onClick={onHistoryOpen}>
              <FaHistory color="#40FD26" size={"40px"} />
              <strong>Histórico</strong>
            </div>
            {/* <div className="button">
              <MdOutlineLiving color="#40FD26" size={"40px"} />
              <strong>Minhas salas</strong>
            </div> */}
            <div className="button">
              <FiMail color="#40FD26" size={"40px"} />
              <strong>Mensagens</strong>
            </div>
          </div>
        </div>

        <div>
          <button>
            <span>Jogo Responsável</span>
          </button>
          <hr />
          <button onClick={onHelpOpen}>
            <span>Ajuda</span>
          </button>
          {/* <hr />
          <span>Sair</span> */}
        </div>
      </div>

      {/* Modal  depósito */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW={"95%"} w={500} minH={450}>
          <ModalHeader>Depósito</ModalHeader>
          <ModalCloseButton isDisabled={loading || loadingQrCode} />
          <ModalBody pb={6}>
            <Stack spacing={5} direction="row">
              {typePayment.length > 0
                ? typePayment.map((item: ITypePayment) =>
                    item.nome !== "A Vista" ? (
                      <Button
                        colorScheme="green"
                        isDisabled={loading || loadingQrCode}
                        variant={
                          paymentSelected && paymentSelected.id !== item.id
                            ? "outline"
                            : "solid"
                        }
                        onClick={() => setPaymentSelected(item)}
                      >
                        {item.nome}
                      </Button>
                    ) : null
                  )
                : null}
            </Stack>
            {paymentSelected?.nome === "Cartão de Crédito" ? (
              <>
                <FormControl mt={4}>
                  <FormLabel fontSize={12}>Número do cartão</FormLabel>
                  <InputMask
                    className="chakra-input css-1jfbvon"
                    mask="9999 9999 9999 9999"
                    maskChar={null}
                    name="Número do cartão"
                    placeholder="Digite o número do cartão"
                    type="tel"
                    disabled={loading}
                    onChange={({ target }) =>
                      handlePayloadCard("numeroCartao", target.value)
                    }
                    style={{
                      border: "1px solid gray",
                      borderRadius: 8,
                      padding: "4px 10px",
                      width: "100%",
                    }}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel fontSize={12}>Nome impresso no cartão</FormLabel>
                  <Input
                    type="text"
                    name="Nome"
                    isInvalid
                    errorBorderColor="crimson"
                    variant="outline"
                    placeholder="Digite o número do cartão"
                    border={"1px"}
                    borderColor={"gray"}
                    borderRadius={8}
                    p={"4px 10px"}
                    w={"100%"}
                    _placeholder={{ fontSize: 12, opacity: 0.6 }}
                    isDisabled={loading}
                    onChange={({ target }) =>
                      handlePayloadCard("nomeCartao", target.value)
                    }
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel fontSize={12}>CPF titular do cartão</FormLabel>
                  <InputMask
                    className="chakra-input css-1jfbvon"
                    mask="999.999.999-99"
                    maskChar={null}
                    placeholder="Digite o CPF do titular do cartão"
                    type="tel"
                    name="CPF"
                    disabled={loading}
                    onChange={({ target }) =>
                      handlePayloadCard("taxpayer", target.value)
                    }
                    style={{
                      border: "1px solid gray",
                      borderRadius: 8,
                      padding: "4px 10px",
                      width: "100%",
                    }}
                  />
                </FormControl>
                <Flex mt={4} flexWrap={"wrap"}>
                  <form autoComplete={"chrome-off"}>
                    <FormLabel>Validade</FormLabel>
                    <InputMask
                      className="chakra-input css-1jfbvon"
                      name="Validade"
                      mask={"99/99"}
                      id="validade"
                      autoComplete="chrome-off"
                      maskPlaceholder={"mm/yy"}
                      placeholder="__/__"
                      maskChar={"_"}
                      type="tel"
                      disabled={loading}
                      style={{
                        border: "1px solid gray",
                        borderRadius: 8,
                        padding: "4px 10px",
                        width: "70%",
                      }}
                      onChange={({ target }) =>
                        handlePayloadCard("date", target.value)
                      }
                    />
                  </form>
                  <FormControl>
                    <FormLabel>CVV</FormLabel>
                    <Input
                      type="text"
                      name="cvv"
                      isInvalid
                      errorBorderColor="crimson"
                      variant="outline"
                      placeholder="CVV"
                      border={"1px"}
                      borderColor={"gray"}
                      borderRadius={8}
                      p={"4px 10px"}
                      w={"70%"}
                      maxLength={4}
                      pattern="([0-9]{4})"
                      _placeholder={{ fontSize: 12, opacity: 0.6 }}
                      isDisabled={loading}
                      onChange={({ target }) =>
                        handlePayloadCard("cvv", target.value)
                      }
                      onKeyPress={(event) => inputFilter(event)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Valor</FormLabel>
                    <InputGroup>
                      <InputLeftAddon
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1.3em"
                        children="R$"
                        border={"1px"}
                        borderColor={"gray"}
                        borderRadius={"8px 0 0 8px"}
                        p={"2px 8px"}
                      />
                      <Input
                        type="number"
                        name="Valor"
                        errorBorderColor="crimson"
                        variant="outline"
                        placeholder="Digite o valor"
                        border={"1px"}
                        borderColor={"gray"}
                        borderRadius={8}
                        p={"4px 10px"}
                        isDisabled={loading}
                        onChange={({ target }) =>
                          handlePayloadCard("quantidade", target.value)
                        }
                      />
                    </InputGroup>
                  </FormControl>
                  <Stack mt={3}>
                    <Text mb={0}>
                      Taxa administrativa: {formatMoney(administrateTaxCard)}
                    </Text>
                    <Text style={{ marginTop: 0 }}>
                      Total à pagar: {formatMoney(valuePaymentCard)}
                    </Text>
                  </Stack>
                </Flex>
                <Stack direction={"row"} mt={4} justifyContent={"flex-end"}>
                  <Button
                    colorScheme="red"
                    variant={"outline"}
                    onClick={onClose}
                    isDisabled={loading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    colorScheme="green"
                    isLoading={loading}
                    onClick={() => handleSubmitCard()}
                  >
                    Enviar
                  </Button>
                </Stack>
              </>
            ) : paymentSelected?.nome === "Pix" ? (
              <>
                <Flex
                  mt={4}
                  justifyContent={"space-between"}
                  alignItems={"flex-end"}
                  flexWrap={"wrap"}
                >
                  <FormControl mb={2}>
                    <FormLabel>Valor</FormLabel>
                    <InputGroup>
                      <InputLeftAddon
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1.3em"
                        children="R$"
                        border={"1px"}
                        borderColor={"gray"}
                        borderRadius={"8px 0 0 8px"}
                        p={"2px 8px"}
                      />
                      <Input
                        type="number"
                        errorBorderColor="crimson"
                        variant="outline"
                        placeholder="Digite o valor"
                        border={"1px"}
                        borderColor={"gray"}
                        borderRadius={8}
                        p={"4px 10px"}
                        isDisabled={loadingQrCode}
                        onChange={({ target }) =>
                          handleInputChange(target.value)
                        }
                      />
                    </InputGroup>
                  </FormControl>
                  <Button
                    mb={2}
                    isLoading={loadingQrCode}
                    colorScheme="green"
                    onClick={() => submitQrCode()}
                  >
                    Gerar QR code
                  </Button>
                </Flex>
                <Stack>
                  <Text mt={3} mb={0}>
                    Taxa administrativa: {formatMoney(administrateTaxPix)}
                  </Text>
                  <Text style={{ marginTop: 0 }}>
                    Total à pagar: {formatMoney(valuePaymentPix)}
                  </Text>
                </Stack>
                <Flex justifyContent={"center"} alignItems={"center"} p={5}>
                  {visibleQrCode && !statusPayment ? (
                    <Stack alignItems={"center"}>
                      <QRCode value={visibleQrCode.qrCode} />
                      <Button mt={2} colorScheme="green" onClick={() => handleCopyPix(visibleQrCode.qrCode)}>Copiar código</Button>
                    </Stack>
                  ) : visibleQrCode && statusPayment ? (
                    <AiFillCheckCircle color="green" size={85} />
                  ) : null}
                </Flex>
              </>
            ) : null}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal editar usuário */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isEditOpen}
        onClose={onEditClose}
        motionPreset="slideInBottom"
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW={"95%"} w={500} minH={450}>
          <ModalHeader>Editar usuário</ModalHeader>
          <ModalCloseButton isDisabled={loadingEdit} />
          <ModalBody
            pb={6}
            paddingInline={isSmallerThanCertainSize ? "1rem" : "1.5rem"}
          >
            <FormControl>
              <FormLabel fontSize={isSmallerThanCertainSize ? "sm" : "md"}>
                Nome
              </FormLabel>
              <Input
                type="text"
                isInvalid
                errorBorderColor="crimson"
                variant="outline"
                placeholder="Digite seu nome"
                border={"1px"}
                borderColor={"gray"}
                borderRadius={8}
                p={"4px 10px"}
                value={payloadUser.nome}
                w={"100%"}
                _placeholder={{ fontSize: 12, opacity: 0.6 }}
                isDisabled={loadingEdit}
                onChange={({ target }) => handleEditUser("nome", target.value)}
              />
            </FormControl>
            <FormControl mt={2}>
              <FormLabel fontSize={isSmallerThanCertainSize ? "sm" : "md"}>
                Email
              </FormLabel>
              <Input
                type="text"
                isInvalid
                errorBorderColor="crimson"
                variant="outline"
                placeholder="Digite seu email"
                border={"1px"}
                borderColor={"gray"}
                borderRadius={8}
                p={"4px 10px"}
                value={payloadUser.email}
                w={"100%"}
                _placeholder={{ fontSize: 12, opacity: 0.6 }}
                isDisabled={loadingEdit}
                onChange={({ target }) => handleEditUser("email", target.value)}
              />
            </FormControl>

            <Stack direction={"row"} mt={2} w={"100%"}>
              <FormControl w={"50%"}>
                <FormLabel
                  id="dataNascimento"
                  fontSize={isSmallerThanCertainSize ? "sm" : "md"}
                >
                  Data de Nascimento
                </FormLabel>
                <Input
                  type="date"
                  isInvalid
                  errorBorderColor="crimson"
                  variant="outline"
                  border={"1px"}
                  borderColor={"gray"}
                  borderRadius={8}
                  isDisabled={loadingEdit}
                  p={"4px 10px"}
                  value={payloadUser.dataNascimento?.slice(0, 10)}
                  w={"100%"}
                  _placeholder={{ fontSize: 12, opacity: 0.6 }}
                  onChange={({ target }) =>
                    handleEditUser("dataNascimento", target.value)
                  }
                />
              </FormControl>
              <FormControl w={"50%"}>
                <FormLabel fontSize={isSmallerThanCertainSize ? "sm" : "md"}>
                  CPF
                </FormLabel>
                <InputMask
                  mask={"999.999.999-99"}
                  maskChar={"_"}
                  value={payloadUser.cpfCnpj?.toString()}
                  disabled={loadingEdit}
                  style={{
                    border: "1px solid gray",
                    borderRadius: 8,
                    padding: "4px 10px",
                    width: "100%",
                  }}
                  onChange={({ target }) =>
                    handleEditUser("cpfCnpj", target.value)
                  }
                />
              </FormControl>
            </Stack>
            <Stack direction={"row"}>
              <FormControl w={"50%"}>
                <FormLabel fontSize={isSmallerThanCertainSize ? "sm" : "md"}>
                  Telefone
                </FormLabel>
                <InputMask
                  mask={"(99) 99999-9999"}
                  value={payloadUser.telefone}
                  disabled={loadingEdit}
                  style={{
                    border: "1px solid gray",
                    borderRadius: 8,
                    padding: "4px 10px",
                    width: "100%",
                  }}
                  onChange={({ target }) =>
                    handleEditUser("telefone", target.value)
                  }
                />
              </FormControl>
              <Flex
                justifyContent={"flex-end"}
                alignItems={"flex-end"}
                w={"50%"}
              >
                <Button
                  isLoading={loadingEdit}
                  colorScheme="green"
                  onClick={() => handleSubmitEditUser()}
                >
                  Salvar
                </Button>
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal adicionar banco */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isAddOpen}
        onClose={onAddClose}
        motionPreset="slideInBottom"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar conta do banco</ModalHeader>
          <ModalCloseButton isDisabled={loadingAddAccount} />
          <ModalBody>
            <FormControl>
              <FormLabel>Nome Banco</FormLabel>
              {/* <Input
                type="text"
                isInvalid
                errorBorderColor="crimson"
                variant="outline"
                placeholder="Digite o nome do banco"
                border={"1px"}
                borderColor={"gray"}
                borderRadius={8}
                p={"4px 10px"}
                value={payloadBank.bank}
                w={"100%"}
                _placeholder={{ fontSize: 12, opacity: 0.6 }}
                onChange={({ target }) =>
                  handleInputBank("bank", target.value)
                }
              /> */}
              <Select
                className="basic-single"
                classNamePrefix="select"
                // defaultValue={listBank[0].name}
                isSearchable={true}
                name="color"
                placeholder="Selecione o banco"
                options={listBank.map((item: IListBank) => item)}
                isDisabled={loadingAddAccount}
                styles={{
                  control: (base, props) => ({
                    ...base,
                    borderRadius: 8,
                    borderColor: "gray",
                    fontSize: 12,
                  }),
                }}
                onChange={(ev) => handleInputBank("bank", ev)}
              />
            </FormControl>
            <Stack direction={"row"} mt={2}>
              <FormControl>
                <FormLabel>Agência</FormLabel>
                <Input
                  type="number"
                  isInvalid
                  errorBorderColor="crimson"
                  variant="outline"
                  placeholder="Digite o número da agência"
                  border={"1px"}
                  borderColor={"gray"}
                  borderRadius={8}
                  p={"4px 10px"}
                  value={payloadBank.agency}
                  w={"100%"}
                  _placeholder={{ fontSize: 12, opacity: 0.6 }}
                  isDisabled={loadingAddAccount}
                  onChange={({ target }) =>
                    handleInputBank("agency", target.value)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Conta</FormLabel>
                <Input
                  type="number"
                  isInvalid
                  errorBorderColor="crimson"
                  variant="outline"
                  placeholder="Digite o número da conta"
                  border={"1px"}
                  borderColor={"gray"}
                  borderRadius={8}
                  p={"4px 10px"}
                  value={payloadBank.number}
                  w={"100%"}
                  _placeholder={{ fontSize: 12, opacity: 0.6 }}
                  onChange={({ target }) =>
                    handleInputBank("number", target.value)
                  }
                />
              </FormControl>
            </Stack>
            <Stack direction={"row"} mt={2}>
              <FormControl w={"50%"}>
                <FormLabel>Tipo de conta</FormLabel>
                {/* <Input
                  type="text"
                  isInvalid
                  errorBorderColor="crimson"
                  variant="outline"
                  placeholder="Digite o tipo da sua conta"
                  border={"1px"}
                  borderColor={"gray"}
                  borderRadius={8}
                  p={"4px 10px"}
                  value={payloadBank.accountType}
                  w={"100%"}
                  _placeholder={{ fontSize: 12, opacity: 0.6 }}
                  onChange={({ target }) =>
                    handleInputBank("accountType", target.value)
                  }
                /> */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  // defaultValue={listBank[0].name}
                  isSearchable={true}
                  name="color"
                  placeholder="Tipo de conta"
                  options={idAccountType.map((item: IAccountType) => item)}
                  isDisabled={loadingAddAccount}
                  styles={{
                    control: (base, props) => ({
                      ...base,
                      borderRadius: 8,
                      borderColor: "gray",
                      fontSize: 12,
                    }),
                  }}
                  onChange={(ev) => handleInputBank("accountType", ev)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>CPF</FormLabel>
                <InputMask
                  className="chakra-input css-1jfbvon"
                  mask="999.999.999-99"
                  maskChar={null}
                  placeholder="Digite o CPF"
                  type="tel"
                  disabled={loadingAddAccount}
                  onChange={({ target }) =>
                    handleInputBank("cpf", target.value)
                  }
                  style={{
                    border: "1px solid gray",
                    borderRadius: 8,
                    padding: "4px 10px",
                    width: "100%",
                  }}
                />
              </FormControl>
            </Stack>
            <Stack direction={"row"} justifyContent={"flex-end"} mt={3} mb={2}>
              <Button
                isLoading={loadingAddAccount}
                colorScheme="green"
                onClick={() => handleSubmitAddAccount()}
              >
                Salvar
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal solicitar saque */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isSackOpen}
        onClose={onSackClose}
        motionPreset="slideInBottom"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Solicitar saque</ModalHeader>
          <ModalCloseButton isDisabled={loadingSack} />
          <ModalBody mb={4}>
            <Text
              fontSize={"1.3rem"}
              fontWeight="semibold"
              mb="1rem"
              textAlign={"center"}
            >
              Saldo Disponível:{" "}
              <span style={{ color: "var(--primary)", fontWeight: "800" }}>
                {balanceUser && balanceUser.saldoSaque
                  ? formatMoney(balanceUser.saldoSaque)
                  : "R$ 0,00"}
              </span>
            </Text>
            <FormControl mb={4}>
              {myListBank.length > 0 ? (
                <>
                  <FormLabel>Selecione o banco</FormLabel>
                  <DivRow>
                    {myListBank.length > 0
                      ? myListBank.map((bank: IListBank) => (
                          <DivRow
                            key={bank.id}
                            onClick={() => handleConfirmModal(bank)}
                          >
                            <input
                              type="radio"
                              name="bank"
                              id={`${bank.id}`}
                              checked={!bank.inativo}
                              disabled={loadingSack}
                            />
                            <Card>
                              <Title>{bank.name.replace("CA - ", "")}</Title>
                              <p>Conta: {bank.conta}</p>
                            </Card>
                          </DivRow>
                        ))
                      : null}
                  </DivRow>
                </>
              ) : (
                <FormLabel>Adicione um banco para continuar</FormLabel>
              )}
            </FormControl>
            <form onSubmit={submitRequestSack}>
              <Flex justifyContent={"center"} alignItems={"center"}>
                <input
                  ref={inputRef}
                  className="chakra-input css-1jfbvon"
                  placeholder="Digite o valor"
                  type="tel"
                  value={inputValueSack}
                  disabled={loadingSack}
                  onChange={handleInputChangeSack}
                  onClick={handleInputClick}
                  style={{
                    border: "1px solid gray",
                    borderRadius: 8,
                    marginLeft: 4,
                    padding: "4px",
                    textAlign: "right",
                    width: "40%",
                  }}
                />
                <Button
                  isLoading={loadingSack}
                  colorScheme="green"
                  ml={3}
                  type="submit"
                  isDisabled={myListBank.length === 0}
                >
                  Enviar
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal confirmar conta principal */}
      <Modal
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar</ModalHeader>
          <ModalCloseButton isDisabled={loadingAlterBank} />
          <ModalBody>Deseja tornar esse banco como principal?</ModalBody>
          <ModalFooter>
            <Button
              colorScheme={"green"}
              isLoading={loadingAlterBank}
              spinnerPlacement="start"
              onClick={() => setConfirmReply(true)}
            >
              Confirmar
            </Button>
            <Button
              colorScheme="blue"
              // disabled={loadingInscription}
              spinnerPlacement="start"
              ml={3}
              isDisabled={loadingAlterBank}
              onClick={onConfirmClose}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal histórico */}
      <Modal
        isOpen={isHistoryOpen}
        onClose={onHistoryClose}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent minW={"60%"} minH={"70%"}>
          <ModalHeader>Histórico</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer
              border={"1px solid var(--muted)"}
              rounded={"md"}
              maxW={"100%"}
            >
              <Table w={"100%"} minH={"90%"}>
                <Thead
                  style={{
                    borderBottom: "1px solid var(--muted)",
                    backgroundColor: "var(--alert)",
                  }}
                >
                  <Tr>
                    <Th>Tipo</Th>
                    <Th isNumeric>Valor</Th>
                    <Th>Data</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {history.map((item: IHistory) => (
                    <Tr
                      key={item.id}
                      textAlign={"center"}
                      color={item.valor > 0 ? "green" : "red"}
                    >
                      <Td>{item.historico}</Td>
                      <Td>
                        {item.valor ? formatMoney(item.valor) : "R$ 0,00"}
                      </Td>
                      <Td>
                        {item.criadoEm
                          ? format(
                              new Date(item.criadoEm),
                              "dd/MM/yyyy 'ás' H:mm"
                            )
                          : null}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter justifyContent={"center"}>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => handlePageChange(page - 1)}
                isDisabled={page === 1}
                colorScheme={"green"}
              >
                <SlArrowLeft />
              </Button>
              <Button
                _hover={{ cursor: "default" }}
                _active={{ cursor: "default" }}
                _focus={{ boxShadow: "none" }}
                colorScheme={"green"}
              >
                {page}
              </Button>
              <Button
                onClick={() => handlePageChange(page + 1)}
                isDisabled={totalPage === page}
                colorScheme={"green"}
              >
                <SlArrowRight />
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal ajuda */}
      <Modal
        isOpen={isHelpOpen}
        onClose={onHelpClose}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajuda</ModalHeader>
          <ModalCloseButton isDisabled={loadingHelp}/>
          <ModalBody>
            <FormControl>
              <FormLabel>Título</FormLabel>
              <Input
                type="text"
                errorBorderColor="crimson"
                variant="outline"
                placeholder="Digite o título"
                border={"1px"}
                borderColor={"gray"}
                borderRadius={8}
                p={"4px 10px"}
                w={"100%"}
                isDisabled={loadingHelp}
                onChange={({target}) => handleHelp("nome", target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Assunto</FormLabel>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable={false}
                name="color"
                placeholder="Selecione uma opção"
                options={itensSelectHelp.map((item: any) => item)}
                isDisabled={loadingHelp}
                styles={{
                  control: (base, props) => ({
                    ...base,
                    borderRadius: 8,
                    borderColor: "gray",
                    fontSize: 12,
                  }),
                }}
                onChange={(ev) => handleHelp("codigo", ev)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Mensagem</FormLabel>
              <Textarea
                errorBorderColor="crimson"
                variant="outline"
                placeholder="Digite sua mensagem"
                border={"1px"}
                borderColor={"gray"}
                borderRadius={6}
                p={"4px 6px"}
                w={"100%"}
                minH={100}
                isDisabled={loadingHelp}
                onChange={({ target }) => handleHelp("descricao", target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme="green"
              isLoading={loadingHelp}
              onClick={() => handleSubmitHelp()}
            >Enviar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
}
