import styled from "styled-components";

export const Container = styled.footer`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: var(--background-gray);
	min-height: 60px;

	& .socials {
		padding: 60px 40px;
		width: 36%;
		min-width: 350px;

		& p {
			font-size: 2rem;
			font-weight: bold;
			color: var(--green-lighter400);
		}

		& div {
			display: flex;
			margin-top: 20px;
		}
	}

	& .contact {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 60px 40px;
		width: 46%;
		min-width: 520px;

		& p {
			margin-left: 40px;
		}

		& p:nth-of-type(1) {
			color: var(--green-lighter400);
			font-size: 2rem;
			font-weight: bold;
		}
		& p:not(:nth-of-type(1)) {
			color: var(--white);
		}

		@media (max-width: 690px) {
			& p {
				margin-left: 0;
			}
		}
	}

	& .copy {
		background-color: var(--green-lighter500);
		display: flex;
		justify-content: center;
		align-items: center;
		height: max-content;
		padding: 4px;
		width: 100%;
	}

	& > .copy > p {
		color: var(--white);

		& > a {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	@media (max-width: 1000px) {
		& .socials {
			width: 45%;
		}
		
		& .contact {
			width: 45%;

			/* & p {
				white-space: nowrap;
			} */
		}
	}
	
	@media (max-width: 800px) {
		& > div {
			flex-wrap: wrap;
		}
		& .socials {
			width: 50%;
		}
		
		& .contact {
			width: 50%;
		}
	}

	@media (max-width: 650px) {
		& .socials {
			padding: 40px 40px 20px;
		}
		& .socials p {
			font-size: 1.8rem;
		}
		& .socials > div {
			margin-top: 10px;
		}
		& .socials > div svg {
			height: 24px;
			width: 24px;
		}
		& .contact {
			padding: 20px 40px 40px;
		}
		& .contact p:nth-of-type(1) {
			font-size: 1.8rem;
		}
	}

	@media (max-width: 475px) {
		& .contact {
			min-width: 96%;
		}
	}
`;

export const Row = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;