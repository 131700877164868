import React from "react";
import { Input, MaskedInput } from "./styles";

interface IInputComponent {
  placeholder: string;
  type?: string;
  onChange?: (ev: any) => void;
  masked?: boolean;
  mask?: string | (string | RegExp)[];
  value?: any;
  icon?: any;
  autoComplete?: string;
}

const InputComponent = ({
  placeholder,
  type,
  onChange,
  masked,
  mask = "",
  value,
  autoComplete
}: IInputComponent) => {
  return masked ? (
    <MaskedInput
      mask={mask}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      value={value}
    />
  ) : (
    <Input
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      value={value}
      autoComplete={autoComplete}
    />
  );
};

export default InputComponent;
