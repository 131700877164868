import { BASE, CURRENT_STORE } from ".";

interface IStoreAPI {
  local?: string;
  homolog?: string;
  prod?: string;
}

interface IStoresAPIs {
  [key: string]: IStoreAPI;
}

export interface IStoreColors {
  primary?: string;
  primaryDarker?: string;
  primaryLighter?: string;
  secondary?: string;
  cardColor?: string;
  formBackground?: string;
  formSelected?: string;
  opacityBackground?: string;
  whiteOpacityBackground?: string;
  primaryTextColor?: string;
  primaryComplementaryTextColor?: string;
  inputBorderFocused?: string;
  inputBorderBlurred?: string;
  background?: string;
  backgroundGray?: string;
  white?: string;
  black?: string;
  blackLighter200?: string;
  blackLighter300?: string;
  green?: string;
  greenLighter?: string;
  greenLighter100?: string;
  greenLighter200?: string;
  greenLighter300?: string;
  greenLighter400?: string;
  greenLighter500?: string;
  greenLighter600?: string;
  muted?: string;
  disabled?: string;
  warning?: string;
  danger?: string;
  alert?: string;
  menuBackground?: string;
}

interface IStoresColors {
  [key: string]: IStoreColors;
}

const StoresAPIs: IStoresAPIs = {
  quiz: {
    local: "http://192.168.1.9:3010/api/v1",
    homolog: "https://quiz-api.devaribox.co/api/v1",
    // homolog: "https://api.quizcash.com.br/api/v1",
    prod: "https://api.quizcash.com.br/api/v1",
  },
};

const StoresColors: IStoresColors = {
  quiz: {
    primary: "#7030A0",
    primaryDarker: "#57267c",
    primaryLighter: "#B04DFA",
    secondary: "#4C3EC4",
    cardColor: "#2D343C",
    formBackground: "#505050",
    formSelected: "#40404E",
    opacityBackground: "rgba(0, 0, 0, .1)",
    whiteOpacityBackground: "rgba(255, 255, 255, 0.5)",
    primaryTextColor: "#94949b",
    primaryComplementaryTextColor: "#9F8CB7",
    inputBorderBlurred: "rgba(0, 0, 0, 0.2)",
    inputBorderFocused: "#2C7D95",
    white: "#FFFFFF",
    black: "#000000",
    blackLighter200: '#1C1C1C',
    blackLighter300: '#0D0D0D',
    green:  "#116E51", //"#126E51", // "#1AAA42",
    greenLighter: "#58D7AF",
    greenLighter100: "#3BBE92",
    greenLighter200: "#2BC016",
    greenLighter300: "#40FD26",//"#1DAF09",
    greenLighter400: "#0DB38F",
    greenLighter500: "#156F5B",
    greenLighter600: "#0CF7C1",
    muted: "#888", //#9F8CB7
    disabled: "#ccc",
    background: "#151521",
    backgroundGray: "#222222",
    warning: "#FF7575",
    danger: "#FE1111",
    alert: "#FFDF2E",
    menuBackground: "#383838",
  },
};

export const BASE_URL = StoresAPIs[CURRENT_STORE][BASE];
export const COLORS = StoresColors[CURRENT_STORE];
