import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Button } from "@chakra-ui/react";
import InputComponent from "../../components/input";
import { Container, Content, Link } from "./styles";
import { ISignInPayload, useAuth } from "../../contexts/auth";
import Toast from "../../components/Toast";
import { useNavigate } from "react-router-dom";
import { BASE, CURRENT_STORE } from "../../environments";
import Logo from "../../assets/logo_quizz_cash.webp";

const Login = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [password, setPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<ISignInPayload>({
    email: "",
    password: "",
  });
  const initialValues: ISignInPayload = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (localStorage.getItem(`@${CURRENT_STORE}${BASE}:user`)) {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();

    setLoading(true);
    if (validate()) {
      try {
        const result = await signIn(payload);
        if (result) {
          navigate("/");
          setLoading(false);
        }
      } catch (err: any) {
        Toast.show(err, "error");
      }
    }
    setLoading(false);
  };

  const validate = () => {
    if (!payload.email) {
      Toast.show("O campo email é obrigatório", "error");
      return false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(payload.email)
    ) {
      Toast.show("Este endereço de e-mail é inválido", "error");
      return false;
    }
    if (!payload.password) {
      Toast.show("O campo senha é obrigatório", "error");
      return false;
    } else if (payload.password.length < 8) {
      Toast.show("A senha precisa ter no mínimo 8 dígitos", "error");
      return false;
    }
    return true;
  };

  const handlePayload = (key: string, value: string) => {
    setPayload({ ...payload, [key]: value });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Flex h={"full"} align={"center"} justify={"center"}>
          <Content>
            <Box
              p={"9px"}
              rounded={"lg"}
              boxShadow={"md"}
              display={"flex"}
              flexDir={"column"}
              alignItems={"center"}
              w={310}
              h={350}
            >
              <img src={Logo} width={50} />
              <label>Login</label>
              <InputComponent
                placeholder="E-mail"
                type="email"
                onChange={(ev: any) => handlePayload("email", ev.target.value)}
              />
              {/* <label>Senha</label> */}
              <InputComponent
                placeholder="Senha"
                type="password"
                onChange={(ev: any) =>
                  handlePayload("password", ev.target.value)
                }
              />
              <Button
                width={"full"}
                mt={5}
                mb={2}
                isLoading={loading}
                bg={"#0a4f00"}
                border={"2px"}
                borderRadius={"3xl"}
                color={"white"}
                borderColor={"#0a4f00"}
                type="submit"
                // onClick={() => handleSubmit()}
              >
                ACESSAR
              </Button>
              <Button
                w={"full"}
                bg={"light"}
                border={"2px"}
                borderColor={"white"}
                borderRadius={"3xl"}
              >
                <Link to={"/cadastro"}>CRIAR CADASTRO</Link>
              </Button>

              <Button w={"full"} mt={4} color={"white"} variant="link">
                <Link to={"/recuperar-senha"}>Esqueceu a Senha</Link>
              </Button>
            </Box>
          </Content>
        </Flex>
      </form>
    </Container>
  );
};

export default Login;
