import { Container, Row } from "./styles";
import { Link } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";

const Footer = () => {
  const fullYear = new Date().getFullYear();

  return (
    <Container>
      <Row>
        <div className="socials">
          <p>Siga-nos</p>
          <div>
            <a href="https://www.instagram.com/quizcash_ofc/" target="_blank">
              <BsInstagram
                style={{
                  color: "var(--white)",
                  height: 30,
                  width: 30,
                  marginRight: 10,
                }}
              />
            </a>
          </div>
        </div>
        <div className="contact">
          <p>Contato</p>
          <p>
            Está com dúvidas sobre como nosso site funciona? Em nosso Instagram
            você encontra muitas informações, acesse{" "}
            <a href="https://www.instagram.com/quizcash_ofc/" target="_blank" style={{ color: "var(--green-lighter300)" }}>
              @quizcash_ofc
            </a>{" "}
            e siga nosso perfil para ficar por dentro das novidades!
          </p><br />
          <p>
            Por favor, sinta-se à vontade para nos enviar uma mensagem ou enviar
            um <a href="mailto:contato@quizcash.com.br" target="_blank" style={{ color: "var(--green-lighter300)" }}>e-mail</a>, ou use nosso formulário para entrar em contato conosco.
            Estamos ansiosos para ouvir de você!
          </p><br />
          <p>Vamos construir coisas incríveis juntos!</p>
        </div>
      </Row>
      <div className="copy">
        <p>
          &copy; {fullYear} - QuizCash{" "}
          <Link to="/política-privacidade" target="_blank">
            Política de privacidade
          </Link>
        </p>
      </div>
    </Container>
  );
};

export default Footer;
