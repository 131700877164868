import React from 'react';

interface SvgComponentProps {
  svgPath: string;
}

const SvgComponent: React.FC<SvgComponentProps> = ({ svgPath }) => {
  return (
    <div>
      <img src={svgPath}/>
    </div>
  );
};

export default SvgComponent;