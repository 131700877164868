import React, { useEffect, useRef, useState } from "react";
import { DivTop, Profile, Select } from "./styles";
import { useAuth } from "../../contexts/auth";
import Logo from "../../assets/logo_quizz_cash_certa_fundo_verde.webp";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { TfiMenuAlt } from "react-icons/tfi";
import { IModality } from "../../pages/home";
import Tooltip from "../Tooltip/Tooltip";

interface IHeader {
  toggleMenu: () => void;
  toggleSidebar: () => void;
  handleSubmit: () => void;
  handleValuePayload: (key: string, value: any) => void;
  modality: IModality[];
  loadingRoom: boolean;
  onBackClick: (value: string) => void;
}

export function Header({
  toggleMenu,
  toggleSidebar,
  handleSubmit,
  handleValuePayload,
  modality,
  loadingRoom,
  onBackClick,
}: IHeader) {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const {
    isOpen: isRoomOpen,
    onOpen: onRoomOpen,
    onClose: onRoomClose,
  } = useDisclosure();
  const {
    isOpen: isAboutOpen,
    onOpen: onAboutOpen,
    onClose: onAboutClose,
  } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  useEffect(() => {
    if (!loadingRoom) {
      onRoomClose();
    }
  }, [loadingRoom]);

  // Logout
  const handleSignOut = () => {
    signOut();
    navigate("/");
  };

  // Login
  const handleLogin = () => {
    navigate("/login");
  };

  // Cadastro
  const handleRegister = () => {
    navigate("/cadastro");
  };

  return (
    <DivTop>
      <Profile>
        <div>
          <div className="logo">
            <button onClick={() => toggleSidebar()}>
              <TfiMenuAlt />
            </button>
            <img
              src={Logo}
              height={45}
              width={160}
              alt="Logo QuizCash, na logo tem o nome quiz cash e entre as duas palavras tem uma moeda dentro de um balão de notificação"
            />
          </div>
          {/* {user ? ( */}
          <div>
            <p className={"bold"} onClick={user ? onRoomOpen : handleLogin}>
              Adicionar Sala
            </p>
          </div>
          {/* ) : null} */}
        </div>
        <div>
          {!user ? (
            <>
              <button onClick={onAboutOpen}>Sobre nós</button>
              <button onClick={() => onBackClick("footer")}>Contato</button>
              <button onClick={() => handleRegister()}>Registre-se</button>
              <button className="bg-login" onClick={() => handleLogin()}>
                Login
              </button>
            </>
          ) : null}
          {user ? (
            <>
              <button onClick={onAboutOpen}>Sobre nós</button>
              <button onClick={() => onBackClick("footer")}>Contato</button>
              <button className="bg-login" onClick={() => handleSignOut()}>
                Sair
              </button>

              <button onClick={() => toggleMenu()} style={{ paddingTop: 0 }}>
                <BiUserCircle color={"#ffffff"} size={"30px"} />
                {/* <img style={{width: '25px', height: '25px'}} src={ ProfileIcon } alt="ícone profile" /> */}
              </button>
            </>
          ) : null}
        </div>
      </Profile>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isRoomOpen}
        onClose={onRoomClose}
        motionPreset="slideInBottom"
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW={"95%"} w={500}>
          <ModalHeader>Criar Sala</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel fontSize={12}>Nome</FormLabel>
              <Input
                ref={initialRef}
                type="text"
                isInvalid
                errorBorderColor="crimson"
                variant="outline"
                placeholder="Digite o nome da sala"
                border={"1px"}
                borderColor={"gray"}
                borderRadius={8}
                p={"4px 10px"}
                w={"90%"}
                _placeholder={{ fontSize: 12, opacity: 0.6 }}
                onChange={({ target }) =>
                  handleValuePayload("nome", target.value)
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel fontSize={12}>Modalidade</FormLabel>
              <Select
                onChange={({ target }) =>
                  handleValuePayload("idModalidadeSala", target.value)
                }
              >
                <option selected disabled value={""}>
                  Selecione a modalidade
                </option>
                {modality.map((item: IModality) => (
                  <option key={item.id} value={item.id}>
                    {item.nome}
                  </option>
                ))}
              </Select>
              <FormControl mt={4}>
                <FormLabel fontSize={12}>Data de Início</FormLabel>
                <Input
                  type="datetime-local"
                  border={"1px"}
                  borderColor={"gray"}
                  borderRadius={8}
                  p={"4px 10px"}
                  w={"90%"}
                  _placeholder={{ fontSize: 12, opacity: 0.6 }}
                  onChange={({ target }) =>
                    handleValuePayload("dataInicio", target.value)
                  }
                />
              </FormControl>
              <Flex w={"90%"} justifyContent={"space-between"}>
                <FormControl mt={4}>
                  <FormLabel fontSize={12}>Qtd. de participantes</FormLabel>
                  <Input
                    type="number"
                    isInvalid
                    errorBorderColor="crimson"
                    variant="outline"
                    placeholder="Digite a quantidade de participantes"
                    border={"1px"}
                    borderColor={"gray"}
                    borderRadius={8}
                    min={1}
                    p={"4px 10px"}
                    w={"96%"}
                    _placeholder={{ fontSize: 12, opacity: 0.6 }}
                    onChange={({ target }) =>
                      handleValuePayload(
                        "numeroParticipantesSala",
                        +target.value
                      )
                    }
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel fontSize={12}>Valor</FormLabel>
                  <Input
                    type="number"
                    isInvalid
                    errorBorderColor="crimson"
                    variant="outline"
                    placeholder="Digite o valor do ingresso"
                    border={"1px"}
                    borderColor={"gray"}
                    borderRadius={8}
                    p={"4px 10px"}
                    w={"99%"}
                    _placeholder={{ fontSize: 12, opacity: 0.6 }}
                    onChange={({ target }) =>
                      handleValuePayload("valorIngresso", +target.value)
                    }
                  />
                </FormControl>
              </Flex>
              <FormControl mt={4}>
                <Flex alignItems={"center"}>
                  <FormLabel fontSize={12}>
                    Senha(opcional)
                    <span aria-label="Informações adicionais">
                      <Tooltip text="Caso adicione a senha ela será exigida para se inscrever na sala">
                        <AiOutlineQuestionCircle
                          color="var(--green-lighter200)"
                          style={{
                            display: "inline-block",
                            height: 16,
                            width: 16,
                          }}
                        />
                      </Tooltip>
                    </span>
                  </FormLabel>
                </Flex>
                <Input
                  type="text"
                  isInvalid
                  errorBorderColor="crimson"
                  variant="outline"
                  placeholder="Digite a senha"
                  border={"1px"}
                  borderColor={"gray"}
                  borderRadius={8}
                  p={"4px 10px"}
                  w={"44%"}
                  _placeholder={{ fontSize: 12, opacity: 0.6 }}
                  onChange={({ target }) =>
                    handleValuePayload("senha", target.value)
                  }
                />
              </FormControl>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onRoomClose}
              variant={"outline"}
              colorScheme="red"
              mr={2}
            >
              Cancelar
            </Button>
            <Button
              isLoading={loadingRoom}
              colorScheme="whatsapp"
              spinnerPlacement="end"
              onClick={handleSubmit}
            >
              Criar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal Sobre nós */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isAboutOpen}
        onClose={onAboutClose}
        motionPreset="slideInBottom"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sobre Nós</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <p>
              Nossa missão é oferecer uma plataforma divertida e trivial que
              ajude você a aprender coisas novas enquanto se diverte e ao mesmo
              tempo ganha algum dinheiro, o Quiz Cash tem algo para todos.
              Projetado para entreter e recompensar você.
            </p>
            <p>
              Com questionários emocionantes que cobrem uma ampla gama de temas,
              você nunca mais ficará entediado, jogue sozinho ou com amigos,
              escolhendo entre diferentes categorias e desafie a si mesmo.
            </p>
            <p>
              Junte-se ao Quiz Cash hoje e descubra um mundo com infinitas
              possibilidades!
            </p>
            <p>Quiz Cash, uma forma divertida de ganhar dinheiro.</p>
          </ModalBody>
        </ModalContent>
      </Modal>
    </DivTop>
  );
}
