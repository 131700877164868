import React, { useEffect, useRef, useState } from "react";
import { IEventRoom, IGroupRoom, IModality, IRoom } from "../../pages/home";
import { DivRow, TitleRoom, CardStyle, Container } from "./styles";
import { IoFootballOutline } from "react-icons/io5";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { format, isBefore, parseISO } from "date-fns";
import { formatMoney } from "../../utils/format";
import { useAuth } from "../../contexts/auth";
import Toast from "../Toast";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ID_CLASSE_SALA_AUTOMATICA } from "../../utils/classes";
import {
  getMyRooms,
  getNewRoom,
  getRooms,
  inscriptionRoom,
  queryPassword,
} from "../../services/room";

interface ICard {
  room: IGroupRoom[][];
  modality: IModality;
  itemActive: string;
  myCards?: boolean;
  newRoom?: IGroupRoom[] | null;
  searchValue: string;
}

const Card: React.FC<ICard> = ({
  room,
  modality,
  itemActive,
  myCards,
  newRoom,
  searchValue,
}) => {
  const { user } = useAuth();
  const ulRef = useRef<HTMLUListElement>(null);
  const divPaiRef = useRef<HTMLDivElement>(null);
  const divFilhasRefs = useRef<HTMLDivElement[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const dateNow = new Date();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirmReply, setConfirmReply] = useState<boolean>(false);
  const [cardSelected, setCardSelected] = useState<IRoom | null>(null);
  const [itemCard, setItemCard] = useState<IRoom | null>(null);
  const [scrollbarVisivel, setScrollbarVisivel] = useState<boolean>(false);
  const [itemWidth, setItemWidth] = useState<number>(0);
  const [roomFuture, setRoomFuture] = useState<Array<IGroupRoom[]>>([]);
  const [roomClosed, setRoomClosed] = useState<Array<IGroupRoom[]>>([]);
  const [myRoom, setMyRoom] = useState<Array<IGroupRoom[]>>([]);
  const [newModality, setNewModality] = useState<IModality | null>(null);
  const [loadingInscription, setLoadingInscription] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [onSearchValue, setOnSearchValue] = useState<string>("");
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [passwordRoom, setPasswordRoom] = useState<string>("");

  useEffect(() => {
    if (closeModal) {
      onClose();
    }
  }, [closeModal]);

  useEffect(() => {
    if (divPaiRef.current) {
      verificarScrollbar();
    }
    window.addEventListener("resize", verificarScrollbar);

    return () => {
      window.removeEventListener("resize", verificarScrollbar);
    };
  }, [divPaiRef.current, divFilhasRefs.current]);

  // useEffect(() => {
  //   const ulElement = ulRef.current;
  //   const containerElement = ulElement?.parentElement;

  //   const checkScrollable = () => {
  //     const isScrollable =
  //       ulElement?.scrollWidth &&
  //       containerElement?.clientWidth &&
  //       ulElement.scrollWidth > containerElement.clientWidth;
  //     setShowLeftArrow(!!isScrollable);
  //     setShowRightArrow(!!isScrollable);
  //   };

  //   const handleResize = () => {
  //     checkScrollable();
  //   };

  //   window.addEventListener("resize", handleResize);
  //   checkScrollable();

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [roomFuture, myRoom]);

  useEffect(() => {
    if (modality) {
      getRoom(modality);
      setNewModality(modality);
    }
  }, [modality]);

  useEffect(() => {
    if (confirmReply && cardSelected) {
      const { id } = cardSelected;
      handleInscription(cardSelected);
    }
  }, [confirmReply, cardSelected]);

  useEffect(() => {
    if (cardSelected) {
      setCloseModal(false);
    }
  }, [cardSelected]);

  useEffect(() => {
    if (
      newRoom &&
      newRoom.length > 0 &&
      newRoom[0].modality &&
      newRoom[0].item.idModalidadeSala === newModality?.id
    ) {
      getRoom(newRoom[0].modality);
    }
  }, [newRoom]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      // setOnSearchValue(searchValue);
      getRoom(modality, searchValue);
    }, 500);

    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [searchValue]);

  const getRoom = async (modalidade: IModality, filter?: string) => {
    try {
      if (modalidade) {
        const results = await getRooms(
          ID_CLASSE_SALA_AUTOMATICA,
          modalidade.id,
          user?.id,
          new Date().toISOString(),
          1,
          filter
        );

        // const resultLive = await getRooms(
        //   ID_CLASSE_SALA_AO_VIVO,
        //   idsModality,
        //   id,
        //   new Date().toISOString()
        // );

        if (results) {
          let future: IGroupRoom[] = [];
          results.docs.map((item: IRoom) => {
            if (modalidade.id === item.idModalidadeSala) {
              future.push({
                nomeClasse: modalidade.nome,
                item,
                modality: modalidade,
              });
            }
          });

          const filterRoomFuture = uniqueGroup(future);

          setRoomFuture(filterRoomFuture);
          if (newModality)
            setNewModality({
              ...newModality,
              totalPage: results.totalPages,
            });
        }

        if (user) {
          const resultMyRooms = await getMyRooms(
            ID_CLASSE_SALA_AUTOMATICA,
            modalidade.id,
            user?.id,
            1,
            filter
          );

          if (resultMyRooms) {
            let myFuture: IGroupRoom[] = [];

            resultMyRooms.docs.map((item: IRoom) => {
              if (modalidade.id === item.idModalidadeSala) {
                myFuture.push({
                  nomeClasse: modalidade.nome,
                  item,
                  modality: modalidade,
                });
              }
            });

            const filterMyRoomFuture = uniqueGroup(myFuture);

            setMyRoom(filterMyRoomFuture);
          }
        }
        // if (resultLive) {
        //   let liveFuture: IGroupRoom[] = [];
        //   let liveClosed: IGroupRoom[] = [];

        //   resultLive.map((result: IRoom[]) => {
        //     result.map((item: IRoom) => {
        //       resultMenu.map((itemMenu: IModality) => {
        //         if (itemMenu.id === item.idModalidadeSala) {
        //           if (isBefore(dateNow, parseISO(item.dataInicio))) {
        //             liveClosed.push({ nomeClasse: itemMenu.nome, item });
        //           } else {
        //             liveFuture.push({ nomeClasse: itemMenu.nome, item });
        //           }
        //         }
        //       });
        //     });
        //   });

        //   const filterRoomFutureLive = uniqueGroup(liveFuture);
        //   const filterRoomClosedLive = uniqueGroup(liveClosed);

        //   setRoomFutureLive(filterRoomFutureLive);
        //   setRoomClosedLive(filterRoomClosedLive);
        // }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  // Função responsável por agrupar os multiplos arrays com o mesmo nome em um único array
  function uniqueGroup(item: IGroupRoom[]) {
    // Crie um objeto Map para agrupar os objetos pelo nomeClasse
    const gruposFuture = new Map<string, IGroupRoom[]>();

    // Percorra o array original e agrupe os objetos
    item.forEach((objeto) => {
      const { nomeClasse, item } = objeto;

      // Verifique se já existe um array para o nomeClasse atual
      if (gruposFuture.has(nomeClasse)) {
        // Se existir, adicione o objeto ao array correspondente
        const grupo = gruposFuture.get(nomeClasse);
        if (grupo) grupo.push(objeto);
      } else {
        // Se não existir, crie um novo array com o objeto atual
        gruposFuture.set(nomeClasse, [objeto]);
      }
    });

    // Converta o Map em um array de arrays (cada array representa um grupo)
    const gruposArray = Array.from(gruposFuture.values());
    return [...gruposArray];
  }

  // Função responsável por agrupar itens caso o antigo array tenha algum item com o mesmo nomeClasse, caso contrário ele acrescenta o item no final da lista
  function groupNewItems(oldRoom: IGroupRoom[][], newRoom: IGroupRoom[]) {
    let encontrado = false;
    const newRoomFuture = oldRoom.map((array) => [...array]);

    // Verificar se o item já existe no array
    for (let i = 0; i < newRoomFuture.length; i++) {
      const array: IGroupRoom[] = newRoomFuture[i];

      if (array.length > 1 && array[1].nomeClasse === newRoom[0].nomeClasse) {
        array.push(newRoom[0]);
        encontrado = true;
        break;
      }

      for (let j = 0; j < array.length; j++) {
        const item = array[j];
        if (item.nomeClasse === newRoom[0].nomeClasse) {
          encontrado = true;
          break;
        }
      }

      if (encontrado) {
        array.push(newRoom[0]);
        break;
      }
    }

    // Adicionar o item apenas se não foi encontrado
    if (!encontrado) {
      const newArray: IGroupRoom[][] = [...newRoomFuture, [newRoom[0]]];
      return newArray;
    }

    return newRoomFuture;
  }

  // Inscrição do usuário na sala
  const handleInscription = async (room: IRoom) => {
    if (!user) {
      navigate("/login");
    } else {
      if (user) {
        if (room.SalaEvento && room.SalaEvento?.length > 0) {
          const isIdPessoaIgualAoUsuario = room.SalaEvento.some(
            (item) => item.idPessoa === user.id
          );
          if (isIdPessoaIgualAoUsuario) {
            Toast.show("Você ja está inscrito nessa sala");
            return;
          } else {
            if (room.senhaSala && !passwordRoom) {
              return Toast.show(
                "É preciso uma senha para se inscrever nessa sala."
              );
            } else if (room.senhaSala && passwordRoom) {
              setLoadingInscription(true);
              try {
                const result = await queryPassword(passwordRoom, room.id);
                if (result.status === 201) {
                  await submitInscription(room);
                } else {
                  setLoadingInscription(false);
                  setConfirmReply(false);
                }
              } catch (error) {
                setLoadingInscription(false);
                console.error("Erro: ", error);
              }
            } else {
              setLoadingInscription(true);
              await submitInscription(room);
            }
          }
        } else {
          if (room.senhaSala && !passwordRoom) {
            return Toast.show(
              "É preciso uma senha para se inscrever nessa sala."
            );
          } else if (room.senhaSala && passwordRoom) {
            setLoadingInscription(true);
            try {
              const result = await queryPassword(passwordRoom, room.id);
              if (result.status === 201) {
                await submitInscription(room);
              } else {
                setLoadingInscription(false);
                setConfirmReply(false);
              }
            } catch (error) {
              setLoadingInscription(false);
              console.error("Erro: ", error);
            }
          } else {
            setLoadingInscription(true);
            await submitInscription(room);
          }
        }
      }
    }
  };

  const submitInscription = async (room: IRoom) => {
    if (user) {
      try {
        const result = await inscriptionRoom(user.id, room.id);
        if (result) {
          const _roomFuture = roomFuture;
          const _myRoom = myRoom;

          const filterRoomFuture = updateRoom(_roomFuture, room);
          const filterMyRoom = updateRoom(_myRoom, room);

          setRoomFuture(filterRoomFuture);
          setMyRoom(filterMyRoom);

          setLoadingInscription(false);
          setCloseModal(true);
          setCardSelected(null);
          setConfirmReply(false);
          Toast.show("Inscrição realizada com sucesso", "success");
        }
      } catch (error: any) {
        setCloseModal(true);
        setLoadingInscription(false);
        setCardSelected(null);
        setConfirmReply(false);

        Toast.show(`${error.message}`, "error");
      }
    }
  };

  function updateRoom(oldValue: IGroupRoom[][], room: IRoom): IGroupRoom[][] {
    if (user) {
      oldValue.map((group) => {
        group.map((item) => {
          if (item.item.id === room.id) {
            if (!item.item.SalaEvento) {
              item.item.SalaEvento = []; // Cria um novo array se SalaEvento não existir
            }
            const newEvent: IEventRoom = {
              idPessoa: `${user.id}`,
            };
            item.item.SalaEvento?.push(newEvent);
          }
        });
      });
    }
    return oldValue; // Retorna o valor atualizado
  }

  const verificarScrollbar = () => {
    const divPai = divPaiRef.current;
    const divFilhas = divFilhasRefs.current;

    if (divFilhas && divFilhas.length > 0) {
      const larguraDivPai = divPai?.getBoundingClientRect().width;
      const larguraDivFilhas = divFilhas.reduce(
        (largura, ref) => (largura += ref?.scrollWidth ?? 0),
        0
      );

      if (larguraDivFilhas > larguraDivPai!) {
        setScrollbarVisivel(true);
        setShowLeftArrow(true);
        setShowRightArrow(true);
      } else {
        setScrollbarVisivel(false);
        setShowLeftArrow(false);
        setShowRightArrow(false);
      }
    }
  };

  const handleOpenModalInscription = (room: IRoom) => {
    if (!user) {
      navigate("/login");
    }
    if (user) {
      if (room.SalaEvento && room.SalaEvento.length > 0) {
        const isIdPessoaIgualAoUsuario = room.SalaEvento.some(
          (item) => item.idPessoa === user.id
        );
        if (isIdPessoaIgualAoUsuario) {
          Toast.show("Você ja está inscrito nessa sala");
          return;
        } else {
          onOpen();
          setCardSelected(room);
          setItemCard(room);
        }
      } else {
        onOpen();
        setItemCard(room);
        setCardSelected(room);
      }
    }
  };

  // Navegar para a página room
  const handleNavigate = (card: IRoom) => {
    if (!user) {
      navigate("/login");
    }
    if (user) {
      // navigate("/room", { state: { card, user } });
      if (card.SalaEvento && card.SalaEvento?.length > 0) {
        const isIdPessoaIgualAoUsuario = card.SalaEvento.some(
          (item) => item.idPessoa === user.id
        );
        if (isIdPessoaIgualAoUsuario) {
          navigate("/room", { state: { card, user } });
        } else {
          Toast.show("Você não está inscrito nessa sala.", "error");
        }
      }
    }
  };

  const hendleConfirm = () => {
    setConfirmReply(true);
  };

  const checkInscription = (salaEvento: IEventRoom[], classStyle?: boolean) => {
    if (user) {
      const isIdPessoaIgualAoUsuario = salaEvento.some(
        (item) => item.idPessoa === user.id
      );
      if (isIdPessoaIgualAoUsuario) {
        if (classStyle) {
          return "openCard";
        } else {
          return "Inscrito";
        }
      } else {
        if (classStyle) {
          return "alertCard";
        } else {
          return "Inscrever-se";
        }
      }
    }
  };

  const handleScroll = (ev: any, index: number, item: IGroupRoom) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev;
    if (scrollLeft && scrollWidth && clientWidth) {
      const width = scrollLeft + clientWidth;

      if (Math.ceil(width) >= scrollWidth) {
        console.log(`Lista ${item.nomeClasse} chegou ao final`);
        const _modality = { ...newModality };
        if (_modality && _modality.page) {
          if (_modality.totalPage && _modality.totalPage > _modality.page) {
            getNewRooms(item, _modality.page);
          } else if (!_modality.totalPage && _modality.page === 1) {
            getNewRooms(item, _modality.page);
          }
        }
      }
    }
  };

  const handlePasswordRoom = (value: string) => {
    setPasswordRoom(value);
  };

  const getNewRooms = async (room: IGroupRoom, page: number) => {
    try {
      const results = await getNewRoom(
        ID_CLASSE_SALA_AUTOMATICA,
        room.item.idModalidadeSala,
        user?.id,
        new Date().toISOString(),
        page + 1
      );

      if (results) {
        const _room: IGroupRoom[][] = [...roomFuture];

        results.docs.map((item: any) => {
          _room[0].push({
            nomeClasse: room.nomeClasse,
            item: item,
          });
        });

        setRoomFuture(_room);
        if (newModality)
          setNewModality({
            ...newModality,
            page: results.currentPage,
            totalPage: results.totalPages,
          });
      }
    } catch (error) {}
  };

  // Função responsável por scrollar os items do menu com o clique do botão
  const scrollToNextItem = (direction: "left" | "right") => {
    const ulElement = divPaiRef.current;
    const containerElement = ulElement?.parentElement;
    const itemWidth = containerElement?.querySelector("div")?.clientWidth || 0;
    const scrollAmount = itemWidth;
    const scrollOptions = {
      left: -scrollAmount,
      top: 0,
      behavior: "smooth" as ScrollBehavior,
      scrollSnapType: "x mandatory",
    };
    console.log("chamou", itemWidth);

    if (ulElement && containerElement) {
      if (direction === "left") {
        scrollOptions.left = -scrollAmount - 20;
        ulElement.scrollBy(scrollOptions);
      } else if (direction === "right") {
        scrollOptions.left = scrollAmount;
        ulElement.scrollBy(scrollOptions);
      }
    }
  };

  return (
    <>
      {!myCards
        ? roomFuture.map((group: IGroupRoom[], index: number) => {
            const nomeClasse = group[0]?.nomeClasse;
            if (itemActive === "todos" || itemActive === nomeClasse)
              return (
                <Container
                  listRef={listRef.current !== null ? listRef : null}
                  key={index}
                >
                  {nomeClasse && <TitleRoom>{nomeClasse}</TitleRoom>}
                  <DivRow>
                    {showLeftArrow ? (
                      <button
                        className="buttonArrow"
                        onClick={() => scrollToNextItem("left")}
                      >
                        <IoIosArrowBack id="arrow-left" size={20} />
                      </button>
                    ) : null}
                    <DivRow
                      ref={divPaiRef}
                      onScroll={(ev) =>
                        handleScroll(ev.target, index, group[0])
                      }
                      key={group[0].item.id}
                      className={`div-scrollbar ${
                        scrollbarVisivel ? "scrollbar-visivel" : ""
                      }`}
                      style={{
                        // borderBottomColor: "var(--muted)",
                        // borderBottomWidth: 1,
                        marginBottom: 20,
                        overflowX: itemActive === "todos" ? "auto" : "hidden",
                        display: "flex",
                        flexWrap: itemActive === "todos" ? "nowrap" : "wrap",
                        paddingRight: 50,
                        scrollSnapType: "x mandatory",
                      }}
                    >
                      {group.map((card: IGroupRoom) => (
                        <DivRow
                          ref={(ref) => {
                            if (ref) divFilhasRefs.current.push(ref);
                          }}
                          key={card.item.id}
                          style={{
                            width:
                              itemActive === "todos" ? "100%" : "fit-content",
                            marginRight: 20,
                            minWidth: 300,
                            scrollSnapAlign: "start",
                          }}
                        >
                          <CardStyle>
                            <div>
                              {/* <IoFootballOutline color="var(--white)" size={16} /> */}
                              <h2>
                                {/* <span></span> */}
                                {card?.item?.nome.replace("LOC - ", "")}
                                <div className="money">
                                  <IoIosArrowForward />
                                  <IoIosArrowForward />
                                  <IoIosArrowForward />
                                </div>
                              </h2>
                              <p>
                                Início:{" "}
                                {format(
                                  new Date(card?.item?.dataInicio),
                                  "dd/MM/yyyy 'ás' H:mm"
                                )}
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  isBefore(
                                    dateNow,
                                    parseISO(card?.item?.dataInicio)
                                  )
                                    ? "open"
                                    : "closed"
                                }
                              >
                                status: &nbsp;
                                <span>
                                  {isBefore(
                                    dateNow,
                                    parseISO(card?.item?.dataInicio)
                                  )
                                    ? "Futura"
                                    : "Fechada"}
                                </span>
                              </p>
                              <p>
                                Participantes: {card?.item?.SalaEvento?.length}
                              </p>
                            </div>
                            <div>
                              <p>
                                <span className="money">
                                  <IoIosArrowForward />
                                  <IoIosArrowForward />
                                </span>
                                {formatMoney(card?.item?.valorIngresso)}
                              </p>
                            </div>
                            {user &&
                            card?.item?.SalaEvento &&
                            card?.item?.SalaEvento.length > 0 ? (
                              checkInscription(card?.item?.SalaEvento) ==
                              "Inscrito" ? (
                                <button
                                  onClick={() =>
                                    handleNavigate({
                                      ...card.item,
                                      modalidade: nomeClasse,
                                    })
                                  }
                                  style={{
                                    border: "1px solid #f1f1f1",
                                    borderRadius: 6,
                                    cursor: "pointer",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    padding: 4,
                                    width: "fit-content",
                                  }}
                                >
                                  <span style={{ color: "#ffffff" }}>
                                    Entrar na sala
                                  </span>
                                </button>
                              ) : (
                                <button
                                  onClick={() =>
                                    handleNavigate({
                                      ...card.item,
                                      modalidade: nomeClasse,
                                    })
                                  }
                                  style={{
                                    border: "none",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    padding: 16,
                                    width: "fit-content",
                                  }}
                                >
                                  <span></span>
                                </button>
                              )
                            ) : null}
                            <div className="inscription">
                              {isBefore(
                                dateNow,
                                parseISO(card?.item?.dataInicio)
                              ) ? (
                                <button
                                  type="button"
                                  className={
                                    user &&
                                    card?.item?.SalaEvento &&
                                    card?.item?.SalaEvento.length > 0
                                      ? checkInscription(
                                          card?.item?.SalaEvento,
                                          true
                                        )
                                      : "alertCard"
                                  }
                                  onClick={() =>
                                    handleOpenModalInscription(card.item)
                                  }
                                >
                                  {user &&
                                  card?.item?.SalaEvento &&
                                  card?.item?.SalaEvento.length > 0 ? (
                                    checkInscription(card?.item?.SalaEvento)
                                  ) : (
                                    <span>Inscrever-se</span>
                                  )}
                                </button>
                              ) : null}
                            </div>
                          </CardStyle>
                        </DivRow>
                      ))}
                    </DivRow>
                    {showRightArrow ? (
                      <button
                        className="buttonArrow"
                        onClick={() => scrollToNextItem("right")}
                      >
                        <IoIosArrowForward id="arrow-right" size={20} />
                      </button>
                    ) : null}
                  </DivRow>
                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent w={"90%"} maxW={400}>
                      <ModalHeader>Confirmar</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Text
                          mb={5}
                          textAlign={"center"}
                          fontSize={"xl"}
                          fontWeight={"bold"}
                          color={"blackAlpha.800"}
                        >
                          Confirmar inscrição na sala?
                        </Text>
                        <FormControl>
                          <Flex>
                            <Text>Sala:</Text>
                            <Text fontWeight={"bold"} ml={2}>
                              {itemCard?.nome.replace("LOC - SALA", "")}
                            </Text>
                          </Flex>
                          <Flex>
                            <Text>Participantes:</Text>
                            <Text fontWeight={"bold"} ml={2}>
                              {itemCard?.SalaEvento?.length}
                            </Text>
                          </Flex>
                          <Flex>
                            <Text>Valor:</Text>
                            <Text fontWeight={"bold"} ml={2}>
                              {itemCard?.valorIngresso
                                ? formatMoney(itemCard.valorIngresso)
                                : "R$ 0,00"}
                            </Text>
                          </Flex>
                        </FormControl>
                        {itemCard?.senhaSala ? (
                          <FormControl>
                            <FormLabel mr={2}>Senha:</FormLabel>
                            <Input
                              type="text"
                              isInvalid
                              errorBorderColor="crimson"
                              variant="outline"
                              placeholder="Digite a senha"
                              border={"1px"}
                              borderColor={"gray"}
                              borderRadius={8}
                              p={"4px 10px"}
                              w={"44%"}
                              isDisabled={loadingInscription}
                              _placeholder={{ fontSize: 12, opacity: 0.6 }}
                              onChange={({ target }) =>
                                handlePasswordRoom(target.value)
                              }
                            />
                          </FormControl>
                        ) : null}
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          colorScheme={"green"}
                          isLoading={loadingInscription}
                          spinnerPlacement="start"
                          onClick={() => hendleConfirm()}
                        >
                          Confirmar
                        </Button>
                        <Button
                          colorScheme="blue"
                          disabled={loadingInscription}
                          spinnerPlacement="start"
                          ml={3}
                          onClick={onClose}
                        >
                          Cancelar
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Container>
              );
          })
        : myRoom.map((group: IGroupRoom[], index: number) => {
            const nomeClasse = group[0]?.nomeClasse;
            if (itemActive === "todos" || itemActive === nomeClasse)
              return (
                <Container
                  listRef={listRef.current !== null ? listRef : null}
                  key={index}
                >
                  {nomeClasse && <TitleRoom>{nomeClasse}</TitleRoom>}
                  <DivRow
                    ref={divPaiRef}
                    onScroll={(ev) => handleScroll(ev.target, index, group[0])}
                    key={group[0].item.id}
                    className={`div-scrollbar ${
                      scrollbarVisivel ? "scrollbar-visivel" : ""
                    }`}
                    style={{
                      borderBottomColor: "var(--muted)",
                      borderBottomWidth: 1,
                      marginBottom: 20,
                      overflowX: itemActive === "todos" ? "auto" : "hidden",
                      display: "flex",
                      flexWrap: itemActive === "todos" ? "nowrap" : "wrap",
                      paddingRight: 50,
                    }}
                  >
                    {group.map((card: IGroupRoom) => (
                      <DivRow
                        ref={(ref) => {
                          if (ref) divFilhasRefs.current.push(ref);
                        }}
                        key={card?.item?.id}
                        style={{
                          width:
                            itemActive === "todos" ? "100%" : "fit-content",
                        }}
                      >
                        <CardStyle>
                          <div onClick={() => handleNavigate(card?.item)}>
                            <IoFootballOutline color="var(--white)" size={16} />
                            <h2>
                              <span></span>
                              {card?.item?.nome.replace("LOC - ", "")}
                            </h2>
                            <p>
                              Início:{" "}
                              {format(
                                new Date(card?.item?.dataInicio),
                                "dd/MM/yyyy 'ás' H:mm"
                              )}
                            </p>
                          </div>
                          <div>
                            <p
                              className={
                                isBefore(
                                  dateNow,
                                  parseISO(card?.item?.dataInicio)
                                )
                                  ? "open"
                                  : "closed"
                              }
                            >
                              status: &nbsp;
                              <span>
                                {isBefore(
                                  dateNow,
                                  parseISO(card?.item?.dataInicio)
                                )
                                  ? "Futura"
                                  : "Fechada"}
                              </span>
                            </p>
                            <p>
                              Participantes: {card?.item?.SalaEvento?.length}
                            </p>
                          </div>
                          <div>
                            <p>
                              <span className="money">
                                <IoIosArrowForward />
                                <IoIosArrowForward />
                              </span>
                              {formatMoney(card?.item?.valorIngresso)}
                            </p>
                            {isBefore(
                              dateNow,
                              parseISO(card?.item?.dataInicio)
                            ) ? (
                              <button
                                type="button"
                                className={
                                  user &&
                                  card?.item?.SalaEvento &&
                                  card?.item?.SalaEvento.length > 0
                                    ? checkInscription(
                                        card?.item?.SalaEvento,
                                        true
                                      )
                                    : "alertCard"
                                }
                                onClick={() =>
                                  handleOpenModalInscription(card?.item)
                                }
                              >
                                {user &&
                                card?.item?.SalaEvento &&
                                card?.item?.SalaEvento.length > 0 ? (
                                  checkInscription(card?.item?.SalaEvento)
                                ) : (
                                  <span>Inscrever-se</span>
                                )}
                              </button>
                            ) : null}
                          </div>
                        </CardStyle>
                      </DivRow>
                    ))}
                  </DivRow>
                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent w={"90%"} maxW={400}>
                      <ModalHeader>Confirmar</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Text
                          mb={5}
                          textAlign={"center"}
                          fontSize={"xl"}
                          fontWeight={"bold"}
                          color={"blackAlpha.800"}
                        >
                          Confirmar inscrição na sala?
                        </Text>
                        <FormControl>
                          <Flex>
                            <Text>Sala:</Text>
                            <Text fontWeight={"bold"} ml={2}>
                              {itemCard?.nome.replace("LOC - SALA", "")}
                            </Text>
                          </Flex>
                          <Flex>
                            <Text>Participantes:</Text>
                            <Text fontWeight={"bold"} ml={2}>
                              {itemCard?.SalaEvento?.length}
                            </Text>
                          </Flex>
                          <Flex>
                            <Text>Valor:</Text>
                            <Text fontWeight={"bold"} ml={2}>
                              {itemCard?.valorIngresso
                                ? formatMoney(itemCard.valorIngresso)
                                : "R$ 0,00"}
                            </Text>
                          </Flex>
                          {itemCard?.senhaSala ? (
                            <FormControl>
                              <FormLabel mr={2}>Senha:</FormLabel>
                              <Input
                                type="text"
                                isInvalid
                                errorBorderColor="crimson"
                                variant="outline"
                                placeholder="Digite a senha"
                                border={"1px"}
                                borderColor={"gray"}
                                borderRadius={8}
                                p={"4px 10px"}
                                w={"44%"}
                                isDisabled={loadingInscription}
                                _placeholder={{ fontSize: 12, opacity: 0.6 }}
                                onChange={({ target }) =>
                                  handlePasswordRoom(target.value)
                                }
                              />
                            </FormControl>
                          ) : null}
                        </FormControl>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          colorScheme={"green"}
                          isLoading={loadingInscription}
                          spinnerPlacement="start"
                          onClick={() => hendleConfirm()}
                        >
                          Confirmar
                        </Button>
                        <Button
                          colorScheme="blue"
                          disabled={loadingInscription}
                          spinnerPlacement="start"
                          ml={3}
                          onClick={onClose}
                        >
                          Cancelar
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Container>
              );
          })}
    </>
  );
};

export default Card;
