import styled from "styled-components";
import GreenRadial from "../../assets/radial-green.webp";
import Fireworks from "../../assets/fireworks.gif";
import TelaRoom from "../../assets/tela_interna.webp";

interface IReply {
  winner?: boolean;
}

interface IGame {
  gameOver?: boolean;
}

interface IActive {
  active?: boolean;
}

export const Container = styled.div`
  background: url(${TelaRoom});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
`;

export const ContainerModal = styled.div`
  padding: 10px 20px;
  height: 100%;
  width: 100%;
`;

export const Question = styled.div<IReply>`
  /* ${(props: any) => props.winner ? `background: url(${Fireworks}), url(${GreenRadial})` : `background-image: url(${GreenRadial})`};
  background-position: ${(props: any) => props.winner ? "left 80%, center" : "center"};
  background-repeat: ${(props: any) => props.winner ? "no-repeat, no-repeat" : "no-repeat"};
  background-size: ${(props: any) => props.winner ? "60%, cover" : "cover"}; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  video {
    width: 400px;
    height: 300px;
  }

  #localVideo {
    display: none;
  }

  @media (max-width: 425px) {
    background-size: cover;
  }
`;

export const DivMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 70%;
  width: 100%;
  position: relative;

  .start-game {
    border-right-width: 1px;
    border-right-color: var(--green-lighter600);
    border-right-style: solid;
    color: var(--white);
    display: flex;
    align-items: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    margin-right: 20px;
    padding-right: 20px;
    text-align: end;
    height: 150px;
    width: 60%;
  }
`;

export const DivRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 90%;

  .header{
    display: flex;
    align-items: center;
  }
  
  p {
    color: var(--white);
    font-size: 24px;
    font-weight: 500;
  }

  svg {
    color: var(--green-lighter600);
  }

  @media (max-width: 768px) {
    p {
      font-size: 18px;
    }

    svg {
      margin-left: -18px !important;
      height: 24px !important;
      width: 24px !important;
    }
  }
`;

export const Timer = styled.p<IGame>`
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;//"Times New Roman", Times, serif;
  font-size: 3.8rem;
  font-weight: 500;
  color: ${(props): any => props.gameOver ? "#f90505" : "var(--green-lighter600)"};
  ${(props): any => props.gameOver ? "text-shadow: 3px 0px 2px rgba(240, 180, 180, 0.4),-3px 0px 2px rgba(240, 180, 180, 0.4),0px -3px 2px rgba(240, 180, 180, 0.4),0px 3px 2px rgba(240, 180, 180, 0.4);" : null}
  text-align: center;

  &.pulse {
    animation: pulse 1.5s linear infinite;
    -webkit-animation: pulse 1.5s linear infinite;
    -moz-animation: pulse 1.5s linear infinite;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;

export const CardQuestion = styled.div`
  animation-name: fadeRight;
  animation-duration: .2s;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 10px 0 -5px #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  padding: 20px;
  min-height: 150px;
  min-width: 350px;
  max-width: 80%;
  user-select: none;

  @keyframes fadeRight {
    0% {
      margin-right: -1800px;
    }
    100% {
      margin-right: 0;
    }
  }
`;

export const QuestionCard = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  width: 90%;
  text-align: center;
`;

export const Reply = styled.div<IActive>`
  position: absolute;
  /* left: 0; */
  bottom: 0;
  ${(props): any => props.active ? "animation: bounceInLeft;" : null}
  ${(props): any => props.active ? "animation-duration: 0.02s;" : null}
  background: #464646d1;
  display: ${(props: any) => props.active ? "grid" : "none"};
  grid-template-columns: 2fr 2fr;
  grid-gap: 10px;
  height: 35%;
  max-height: 40%;
  width: 100%;
  padding: 10px;
  z-index: 1;
  /* transition: all linear .02s; */

  section {
    border-top: 1px solid #ddd;
    display: none;
    padding: 20px 8px 0;
    height: 100%;
  }

  #content1 > div {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
  }

  #content2 > div {
    background: var(--white);
    border-radius: 10px;
    height: 80%;
    width: 100%;
    margin-left: auto;
    padding: 4px;

    .chat {
      border: 1px solid var(--muted);
      border-radius: 8px;
      height: 100%;
      width: 100%;
      
      .chat-messages {
        padding: 8px;
        height: 84%;
        overflow-y: auto;

        & > span {
          display: flex;
          font-weight: 700;
          color: var(--primary-lighter);
          width: 100%;

          &.myMessage{
            color: var(--green-lighter200);
            justify-content: flex-end;
          }
        }

        & > p {
          margin-bottom: 10px;
        }
        
        & > .myMessage {
          text-align: right;
        }
      }
    }
  }

  input {
    display: none;
  }

  label {
    border: 1px solid transparent;
    color: #bbb;
    display: inline-block;
    font-weight: 600;
    margin: 0 0 -1px;
    padding: 15px 25px;
    text-align: center;
    width: 50%;
  }

  label:before {
    font-weight: normal;
    margin-right: 10px;
  }

  label:hover {
    color: #888;
    cursor: pointer;
  }

  input:checked + label {
    color: var(--white);
    border: 1px solid #ddd;
    border-top: 2px solid var(--alert);
    border-bottom: 1px solid var(--form-background);
  }

  #tab1:checked ~ #content1,
  #tab2:checked ~ #content2 {
    display: block;
  }

  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      left: -100%;
   }
   60% {
      opacity: 1;
      left: 30%;
   }
   80% {
      left: -10%;
   }
   100% {
      left: 0;
   }
  }
  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      left: -100%;
   }
   60% {
      opacity: 1;
      left: 30%;
   }
   80% {
      left: -10%;
   }
   100% {
      left: 0;
   }
  }
`;

export const DivChat = styled.div`
  border: 1px solid var(--muted);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: var(--form-background);
  display: flex;
  align-items: baseline;
  padding: 0 4px 6px 6px;
  height: 100%;
  max-height: 185px;

  .chat {
      border: 1px solid var(--muted);
      border-radius: 8px;
      height: 100%;
      max-height: 100%;
      width: 100%;
      
      .chat-messages {
        padding: 8px;
        height: 75%;
        max-height: 75%;
        overflow-y: auto;

        & > span {
          display: flex;
          font-weight: 700;
          color: var(--primary-lighter);
          width: 100%;

          &.myMessage{
            color: var(--green-lighter200);
            justify-content: flex-end;
          }
        }

        & > p {
          margin-bottom: 10px;
        }
        
        & > .myMessage {
          text-align: right;
        }
      }

      form {
        height: 25%;
      }
    }

  @media (max-width: 1024px) {
    max-height: 165px;
  }
  @media (max-width: 425px) {
    max-height: 140px;
  }
`

export const FormChat = styled.form`
  border: 1px solid var(--muted);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: var(--form-background);
  display: flex;
  align-items: baseline;
  padding: 0 4px 6px 6px;
  height: 100%;
  width: 100%;
  transition: linear 0.4s;

  .chat {
      border: 1px solid var(--muted);
      border-radius: 8px;
      height: 94%;
      width: 100%;
      
      .chat-messages {
        padding: 8px;
        height: 84%;
        overflow-y: auto;

        & > span {
          display: flex;
          font-weight: 700;
          color: var(--primary-lighter);
          width: 100%;

          &.myMessage{
            color: var(--green-lighter200);
            justify-content: flex-end;
          }
        }

        & > p {
          margin-bottom: 10px;
        }
        
        & > .myMessage {
          text-align: right;
        }
      }

      form {
        height: 26%;
      }
    }

  & > input {
    border-radius: 8px;
    background: #626262;
    color: var(--white);
    display: block;
    border: none;
    padding-left: 8px;
    height: 92%;
    text-align: left;

    &::placeholder {
      color: var(--primary-text-color);
    }
  }

  & > button {
    margin-left: 6px;

    & > svg {
      color: var(--alert);
    }
  }
`;

export const SectionChat = styled.div<IActive>`
  position: absolute;
  /* left: 0; */
  bottom: 0;
  ${(props): any => props.active ? "animation: bounceInLeft;" : null}
  ${(props): any => props.active ? "animation-duration: 0.02s;" : null}
  background: #464646d1;
  display: ${(props: any) => props.active ? "grid" : "none"};
  grid-template-columns: 2fr;
  grid-gap: 10px;
  height: 38%;
  max-height: 205px;
  width: 100%;
  padding: 10px;
  z-index: 1;
`;

export const CardReply = styled.div`
  background-color: var(--white);
  border: 1px solid var(--muted);
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 4px;
  padding: 6px;
  width: 100%;
  user-select: none;

  & > button {
    color: var(--black);
    font-weight: 600;
    text-align: left;
  }

  &:hover, &.active {
    background-color: var(--green);
    box-shadow: 0 0 8px 1px var(--muted);
    color: var(--white);
  }
  
  &.danger {
    background-color: var(--danger);
    box-shadow: 0 0 8px 1px var(--muted);
    color: var(--white);
  }

  &:nth-of-type(1) {
    animation: slide-right 0.3s .5s both;
    -webkit-animation: slide-right 0.3s .5s both;
  }
  &:nth-of-type(2) {
    animation: slide-left 0.3s .5s both;
    -webkit-animation: slide-left 0.3s .5s both;
  }
  &:nth-of-type(3) {
    animation: slide-right 0.3s .8s both;
    -webkit-animation: slide-right 0.3s .8s both;
  }
  &:nth-of-type(4) {
    animation: slide-left 0.3s .8s both;
    -webkit-animation: slide-left 0.3s .8s both;
  }

  @-webkit-keyframes slide-right {
    0% {
      -webkit-transform: translateX(-100vh);
      transform: translateX(-100vh);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes slide-right {
    0% {
      -webkit-transform: translateX(-100vh);
      transform: translateX(-100vh);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  
  @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(100vh);
      transform: translateX(100vh);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(100vh);
      transform: translateX(100vh);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
`;

export const FooterSection = styled.div`
  /* background: var(--form-selected);
  border-radius: 6px; */
  color: var(--white);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* display: flex;
  justify-content: space-between; */
  align-items: center;
  padding: 8px 0 8px 10px;
  margin-top: auto;
  height: 20%;
  width: 100%;
  max-width: 100%;
  position: absolute;
  bottom: 0;

  div:nth-of-type(1) {
    display: flex;
    align-items: center;

    p {
      font-size: 24px;
      font-weight: 800;
      width: 80%;
    }
  }

  div:nth-of-type(2) {
    background: var(--green-lighter600);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    display: flex;
    justify-content: space-evenly;
    height: 50px;
    width: 130px;
    -webkit-box-shadow: 1px 10px 14px 0px rgba(0,0,0,0.29);
    -moz-box-shadow: 1px 10px 14px 0px rgba(0,0,0,0.29);
    box-shadow: 1px 10px 14px 0px rgba(0,0,0,0.29);

    img {
      height: 40px;
      width: 40px;
    }
  }

  .money {
    color: var(--green-lighter);
    font-size: 1.1rem;
    font-weight: 800;
  }

  svg {
    color: var(--alert);
  }

  @media (max-width: 768px) {
    div:nth-of-type(1) {
      p {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 470px) {
    div:nth-of-type(2) {
      margin-left: auto;
    }
  }
`;
