import socketIO from "socket.io-client";
import { chatUrl } from "./chat";

const server = chatUrl;

export const socket = socketIO(server);

export function enterRoom(room: any) {
  socket.emit("enterRoom", room);
}

export function leaveRoom(room: any) {
  socket.emit("leave-room", room);
}

export function addMessage(message: any) {
  socket.emit("add-message", message);
}

export function createChatRoom(room: any) {
  socket.emit("room", room);
}
