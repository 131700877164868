import styled from "styled-components";

interface IOpen {
  isOpen: boolean;
}

export const Container = styled.div<IOpen>`
  /* background-color: var(--menu-background); */
  width: 30%;
  min-width: 400px;
  height: 100vh;

  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: absolute;
  right: ${(props: any) => props.isOpen ? "0" : "-100%"};
  top: 0;
  transition: all .7s ease-in-out;
  
  & > div {
    background-color: #333333;
    padding-bottom: 20px;
    min-height: fit-content;
    width: 100%;

    & > h2 {
      color: var(--white);
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 10px;
      margin-left: 20px;
      width: 90%;
    }

    & > div {
      display: flex;
      flex-direction: row;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        /* background-color: blue; */
        width: 100%;
        gap: 20px;
        padding: 30px;

        & > div {
          display: flex;
          flex-direction: column;

          & > span {
            font-size: 0.9rem;
            font-weight: 200;
            color: var(--white);
          }

          & > strong {
            font-size: 1.5rem;
            color: var(--white);
            font-weight: 500;
          }

          .green {
            color: var(--green-lighter300);
          }
        }

        & + div {
          & > button {
            background-color: var(--green-lighter300);
            height: 40px;

            & > strong {
              color: var(--black);
            }
          }
        }
      }

      & + ul {
        list-style: none;
        display: flex;
        justify-content: center;

        & > li {
          margin-right: 20px;
          position: relative;

          & > a {
            text-decoration: none;
            color: #333;
            font-size: 16px;

            & > strong {
              color: var(--white);
            }
          }

          & > div {
            position: absolute;
            bottom: 2;
            left: 0;
            height: 4px;
            width: 0;
            background-color: var(--green-lighter300);
            transition: width 0.3s ease-in-out;
          }
        }

        & > li:last-child {
          margin-right: 0;
        }

        & > li:hover {
          & > div {
            width: 100%;
          }
        }

        & > line:active {
          & > div {
            width: 100%;
          }
        }
      }

      .button {
        cursor: pointer;
      }
    }

    & + div {
      width: 100%;
      flex: 4;
      background-color: #282828;
      display: flex;
      flex-direction: column;
      /* align-items: start; */

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: yellow; */

        & > div {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 20px;
          padding: 20px;

          & > div {
            display: flex;
            flex-direction: column;
            /* justify-content: center; */
            align-items: center;
            /* background-color: red; */

            & > strong {
              font-size: 0.8rem;
              font-weight: bolder;
              white-space: nowrap;
            }
          }
        }

        & + div {
          display: flex;
          flex-direction: column;
          align-items: start;
          align-self: auto;
          padding: 40px;

          & > button {
            background-color: transparent;
  
            & > span {
              color: var(--white);
            }
          }

          & > hr {
            width: 100%;
            color: black;
            margin: 10px 0 10px 0;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .label-modal {
      font-size: 14px !important;
    }
  }

  @media (max-width: 400px) {
    right: ${(props: any) => props.isOpen ? "0" : "-120%"};
    left: ${(props: any) => props.isOpen ? "0" : ""};

    & > div > div > div {
      padding: 30px 20px;
    }

    .green {
      font-size: 1.3rem;
    }

    #dataNascimento{
      max-width: 12ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

`

export const DivRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const Card = styled.div`
  border: 1px solid gray;
  border-radius: 8px;
  background-color: var(--white);
  margin-left: 3px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px 6px;
`

export const Title = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
`

export const Select = styled.select`
  border: 1px solid gray;
  border-radius: 8px;
  padding: 4px 10px;
  width: 90%;

  &:disabled {
    font-size: 12px;
    opacity: .6;
  }
`