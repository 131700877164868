import styled from "styled-components";
import InputMask from "react-input-mask";

export const Input = styled.input`
  border: none;
  border-radius: 6px;
  width: 100%;
  padding: 6px;
  margin: 5px 0px;
  text-align: center;

  &::placeholder {
    color: var(--muted);
  }
`;

export const MaskedInput = styled(InputMask)`
  border: none;
  border-radius: 6px;
  width: 100%;
  padding: 6px;
  margin: 5px 0px;
  text-align: center;
`;
